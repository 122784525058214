import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { modules } from '../../../utils/EditorToolbar';
import { TaskStatuses } from '../../../utils/TaskStatuses';
import { TaskPriority } from '../../../utils/TaskPriority';
import { Currencies } from '../../../utils/Currencies';
import { QuotationStatuses } from '../../../utils/QuotationStatuses';
import { InvoiceStatuses } from '../../../utils/InvoiceStatuses';

const AddClientTask = (props) => {
    const projectId = props.match.params.projectId;
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [pending, setPending] = useState(false);
    const [clientUserList, setClientUserList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [allCheckbox, setCheckboxes] = useState([]);
    const [taskFile, setTaskFile] = useState([]);
    const [errorList, setError] = useState([]);
    const [clientTaskInput, setClientTask] = useState({
        project_id: '',
        assigned_by: '',
        task: '',
        description: '',
        ideal_date: new Date().toISOString().slice(0,10),
        task_status: 'not-started',
        task_priority: 'medium',
        quotation_amount: '',
        quotation_currency: '',
        quotation_status: '',
        invoice_status: ''
    });

    const setProjectData = useCallback(async (projectId) => {
        if (projectId) {
            setClientTask(
                { ...clientTaskInput,
                    project_id: parseInt(projectId),
                }
            );
        }
        setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);

    useEffect(() => {
        axios.get(`/api/all-projects`).then(res => {
            if (res.data.status === 200) {
                setProjectList(res.data.projects);
            }
        });
        axios.get(`/api/all-client-users`).then(res => {
            if (res.data.status === 200) {
                setClientUserList(res.data.users);
            }
        });
        setProjectData(projectId);
    }, [setProjectData, projectId]);

    const resetForm = () => {
        setClientTask({
            project_id: '',
            assigned_by: '',
            task: '',
            description: '',
            ideal_date: '',
            task_status: '',
            task_priority: '',
            quotation_amount: '',
            quotation_currency: '',
            quotation_status: '',
            invoice_status: ''
        });

        setTaskFile([]);
        setCheckboxes([]);
    }

    const handleInput = (e) => {
        e.persist();
        setClientTask({ ...clientTaskInput, [e.target.name]: e.target.value });
    }

    const handleDescription = (value) => {
        setClientTask({ ...clientTaskInput, description: value });
    }

    const handleFile = (e) => {
        e.persist();
        setTaskFile({ file: e.target.files[0] });
    }

    const handleCheckbox = (e) => {
        e.persist();
        setCheckboxes({ ...allCheckbox, [e.target.name]: e.target.checked });
    }

    const handleProject = (project) => {
        setClientTask({ ...clientTaskInput, project_id: project.value });
    }

    const handleClientUser = (clientUser) => {
        setClientTask({ ...clientTaskInput, assigned_by: clientUser.value });
    }

    const submitTask = (e) => {
        setPending(true);
        e.preventDefault();
        const formData = new FormData();
        formData.append('project_id', clientTaskInput.project_id);
        formData.append('assigned_by', clientTaskInput.assigned_by);
        formData.append('task', clientTaskInput.task);
        formData.append('description', clientTaskInput.description);
        formData.append('ideal_date', clientTaskInput.ideal_date);
        formData.append('file', taskFile.file);
        formData.append('task_status', clientTaskInput.task_status);
        formData.append('task_priority', clientTaskInput.task_priority);
        formData.append('status', allCheckbox.status ? '1' : '0');
        formData.append('quotation_amount', clientTaskInput.quotation_amount);
        formData.append('quotation_currency', clientTaskInput.quotation_currency);
        formData.append('quotation_status', clientTaskInput.quotation_status);
        formData.append('invoice_status', clientTaskInput.invoice_status);

        axios.get('/sanctum/csrf-cookie').then(response => {
            axios({
                method: "post",
                url: `/api/store-client-task`,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then(res => {
                if (res.data.status === 200) {
                    swal("Success", res.data.message, "success");
                    resetForm();
                    setError([]);
                    setProjectData(projectId);
                    setPending(false);
                } else if (res.data.status === 422) {
                    swal("All Fields are mandatory", "", "error");
                    setError(res.data.errors);
                    setPending(false);
                }
            });
        });
    }

    const projectOptions = projectList.map(item => {
        return ({
            value: item.id,
            label: item.name+' ('+item.client.name+')'
        });
    });

    const clientUserOptions = clientUserList.map(item => {
        return ({
            value: item.id,
            label: item.name+' ('+item.client.name+')'
        });
    });

    if (loading) {
        return <h4>Loading...</h4>
    }

    return (
        <div className='px-4'>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h4>Add Client Task
                        <Link to="/admin/view-client-tasks" className='btn btn-primary btn-sm float-end'>View Client Tasks</Link>
                    </h4>
                </div>
                <div className='card-body'>
                    <form encType='multipart/form-data' onSubmit={submitTask} id="CLIENT_TASK_FORM" className="row g-3">
                        <div className="col-md-3">
                            <label htmlFor="project_id" className="form-label">Select Project</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                value={projectOptions.filter(function(option) {
                                    return option.value === clientTaskInput.project_id;
                                })}
                                isSearchable
                                onChange={handleProject}
                                name="project_id"
                                options={projectOptions}
                            />
                            <small className='text-danger'>{errorList.project_id}</small>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="assigned_by" className="form-label">Select Client User</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                value={clientUserOptions.filter(function(option) {
                                    return option.value === clientTaskInput.assigned_by;
                                })}
                                isSearchable
                                onChange={handleClientUser}
                                name="assigned_by"
                                options={clientUserOptions}
                            />
                            <small className='text-danger'>{errorList.assigned_by}</small>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="task" className="form-label">Task</label>
                            <input type="text" name="task" onChange={handleInput} value={clientTaskInput.task} className='form-control' id='task' />
                            <small className='text-danger'>{errorList.task}</small>
                        </div>
                        <div className="col-12">
                            <label htmlFor="description" className="form-label">Description</label>
                            <ReactQuill
                                theme="snow"
                                value={clientTaskInput.description}
                                onChange={handleDescription}
                                modules={modules}
                            />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="ideal_date" className="form-label">Ideal Date</label>
                            <input type="date" name="ideal_date" onChange={handleInput} value={clientTaskInput.ideal_date} className='form-control' id='ideal_date' />
                            <small className='text-danger'>{errorList.ideal_date}</small>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="task_status" className="form-label">Task Status</label>
                            <select name='task_status' onChange={handleInput} value={clientTaskInput.task_status} className='form-select' id='task_status'>
                                <option value="">Select Task Status</option>
                                {TaskStatuses.map((taskStatus, idx) => <option key={idx} value={taskStatus.value}>{taskStatus.label}</option>)}
                            </select>
                            <small className='text-danger'>{errorList.task_status}</small>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="task_priority" className="form-label">Task Priority</label>
                            <select name='task_priority' onChange={handleInput} value={clientTaskInput.task_priority} className='form-select' id='task_priority'>
                                <option value="">Select Task Priority</option>
                                {TaskPriority.map((taskPriority, idx) => <option key={idx} value={taskPriority.value}>{taskPriority.label}</option>)}
                            </select>
                            <small className='text-danger'>{errorList.task_priority}</small>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="file" className="form-label">Upload File</label>
                            <input type='file' name='file' onChange={handleFile} className='form-control' id='file' />
                            <small className='text-danger'>{errorList.file}</small>
                        </div>
                        <div className="col-12">
                            <div className="form-check">
                                <input type="checkbox" name='status' onChange={handleCheckbox} defaultChecked={allCheckbox.status === 1 ? true : false} className="form-check-input" id="status" />
                                <label htmlFor="status" className="form-check-label">Status (checked=Hidden)</label>
                            </div>
                        </div>
                        <h4 className="card-title mt-5">Quotation</h4>
                        <div className="col-md-3">
                            <label htmlFor="quotation_amount" className="form-label">Quotation Amount</label>
                            <input type="number" name="quotation_amount" onChange={handleInput} value={clientTaskInput.quotation_amount} className='form-control' id='quotation_amount' step="0.1" />
                            <small className='text-danger'>{errorList.quotation_amount}</small>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="quotation_currency" className="form-label">Quotation Currency</label>
                            <select name='quotation_currency' onChange={handleInput} value={clientTaskInput.quotation_currency} className='form-select' id='quotation_currency'>
                                <option value="">Select Currency</option>
                                {Currencies.map((currency, idx) => <option key={idx} value={currency.value}>{currency.label}</option>)}
                            </select>
                            <small className='text-danger'>{errorList.quotation_currency}</small>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="quotation_status" className="form-label">Quotation Status</label>
                            <select name='quotation_status' onChange={handleInput} value={clientTaskInput.quotation_status} className='form-select' id='quotation_status'>
                                <option value="">Select Status</option>
                                {QuotationStatuses.map((quotationStatus, idx) => <option key={idx} value={quotationStatus.value}>{quotationStatus.label}</option>)}
                            </select>
                            <small className='text-danger'>{errorList.quotation_status}</small>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="invoice_status" className="form-label">Invoice Status</label>
                            <select name='invoice_status' onChange={handleInput} value={clientTaskInput.invoice_status} className='form-select' id='invoice_status'>
                                <option value="">Select Status</option>
                                {InvoiceStatuses.map((invoiceStatus, idx) => <option key={idx} value={invoiceStatus.value}>{invoiceStatus.label}</option>)}
                            </select>
                            <small className='text-danger'>{errorList.invoice_status}</small>
                        </div>
                        <div className="col-12">
                            <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-primary px-4 mt-2 float-end'>
                                <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AddClientTask;