import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import ShowMoreText from "react-show-more-text";
import DataTable from 'react-data-table-component';
import { Chrono } from 'react-chrono';

import { TaskStatuses } from '../../../utils/TaskStatuses';
import { TaskPriority } from '../../../utils/TaskPriority';
import MinutesToTime from '../../../utils/MinutesToTime';

const tasksColumns = [
    {
        name: 'Task',
        selector: row => row.task,
        sortable: true,
    },
    {
        name: 'Assignee',
        selector: row => row.assignee,
        sortable: true,
    },
    {
        name: 'Assigned By',
        selector: row => row.assigned_by,
        sortable: true,
    },
    {
        name: 'Due Date',
        selector: row => row.due_date,
        sortable: true,
    },
    {
        name: 'Estimate',
        selector: row => row.estimate,
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
    },
    {
        name: 'Priority',
        selector: row => row.priority,
        sortable: true,
    },
    {
        name: 'Actions',
        selector: row => row.actions,
    },
];

let tasksData = [];
let timeLineData = [];

const ViewProject = (props) => {
    const projectId = props.match.params.id;
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [project, setProject] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [picture, setPicture] = useState([]);
    const [totalEstimate, setTotalEstimate] = useState(0);
    const [totalSpent, setTotalSpent] = useState(0);
    const [totalNonBillable, setTotalNonBillable] = useState(0);

    if (!localStorage.getItem('auth_token')) {
        history.push('/login');
        swal('Unauthorized', 'Authorization Required', 'error');
    } else if (!props.allowedRoles.includes(localStorage.getItem('auth_role'))) {
        history.push('/403');
    }

    const setFormData = useCallback(async () => {
        timeLineData = [];
        tasksData = [];
        axios.get(`/api/view-project/${projectId}`).then(res => {
            if (res.data.status === 200) {
                setProject(res.data.project_data.project);
                setTasks(res.data.project_data.tasks);
                setPicture({ logo: res.data.project_data.project.logo });
                setTotalEstimate(res.data.project_data.total_estimate);
                setTotalSpent(res.data.project_data.total_spent);
                setTotalNonBillable(res.data.project_data.total_non_billable);
            } else if (res.data.status === 404) {
                swal("Error", res.data.message, "error");
                history.push('/admin/view-projects');
            }
            setLoading(false);
        });
    }, [history, projectId])

    useEffect(() => {
        setFormData();
    }, [setFormData]);

    if (loading) {
        return <h4>Loading Project Details...</h4>
    } else {
        if (tasks.length > 0) {
            timeLineData = tasks.filter(res => res.task_status === 'completed').map(item => {
                return ({
                    title: item.completed_date,
                    cardTitle: item.task,
                    url: `/admin/view-task/${item.id}`,
                    cardSubtitle: item.assignee && item.assignee.name,
                    cardDetailedText: <>
                        <span>Assigned By <b>{item.assigned_by ? item.assigned_by.name : 'Self Assigned'}</b></span><br/>
                        <span>{TaskPriority.map((taskPriority, idx) => item.task_priority === taskPriority.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskPriority.value}`}>{taskPriority.label}</span> : '')} | Due on <b>{item.due_date}</b></span><br/>
                        <span>Estimated <b>{MinutesToTime(item.estimate_time)}</b> | Spent <b>{MinutesToTime(item.total_spent_time)}</b></span><br/>
                        <span>
                            {parseInt(item.total_spent_time) > parseInt(item.estimate_time) ? <span className='badge rounded-pill bg-danger'>MORE Time Spent</span> : <span className='badge rounded-pill bg-success'>LESS Time Spent</span>}&nbsp;
                            {new Date(item.completed_date) <= new Date(item.due_date) ? <span className='badge rounded-pill bg-success'>COMPLETED ON TIME</span> : <span className='badge rounded-pill bg-danger'>DELAYED</span>}
                        </span>
                    </>,
                });
            });

            tasksData = tasks.filter(res => res.task_status !== 'completed').map(item => {
                return ({
                    task :  <ShowMoreText
                                lines={1}
                                more="Show more"
                                less="Show less"
                                className="content-css"
                                anchorClass="show-more-less-clickable"
                                expanded={false}
                                width={200}
                                truncatedEndingComponent={"... "}
                            >{item.task}
                            </ShowMoreText>,
                    assignee :  item.assignee && item.assignee.name,
                    assigned_by :   item.assigned_by ? item.assigned_by.name : 'Self Assigned',
                    due_date :  item.due_date,
                    estimate :  MinutesToTime(item.estimate_time),
                    status :    TaskStatuses.map((taskStatus, idx) => item.task_status === taskStatus.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskStatus.value}`}>{taskStatus.label}</span> : ''),
                    priority :  TaskPriority.map((taskPriority, idx) => item.task_priority === taskPriority.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskPriority.value}`}>{taskPriority.label}</span> : ''),
                    actions :   localStorage.getItem('auth_role') === 'admin' &&
                                <div className="d-flex">
                                    <Link to={`/admin/view-task/${item.id}`} className="btn btn-outline-secondary btn-sm"><i className="fa fa-eye"></i></Link>
                                    <Link to={`/admin/edit-task/${item.id}`} className="btn btn-outline-success btn-sm ms-1"><i className="fa fa-pencil"></i></Link>
                                </div>,
                });
            });
        }
    }

    return (
        <>
            <section className="py-5 container">
                <div className="row py-lg-5 text-center">
                    <div className="col-lg-6 col-md-8 mx-auto">
                        <h2 className="fw-light">{picture.logo && <img src={`${process.env.REACT_APP_BACKEND_URL}/${picture.logo}`} className="logo" alt={project.id} />} {project.name}</h2>
                        <h5 className="fw-light">{project.client.logo && <img src={`${process.env.REACT_APP_BACKEND_URL}/${project.client.logo}`} className="logo" alt={project.client.id} />} {project.client.name}</h5>
                        <p className="lead text-muted">{project.description}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ul className='list-group list-group-flush text-start'>
                            {project.production_url && <li className="list-group-item">Production URL :<a href={project.production_url} target="_blank" rel="noopener noreferrer" className="btn btn-link">{project.production_url}</a></li>}
                            {project.staging_url && <li className="list-group-item">Staging URL :<a href={project.staging_url} target="_blank" rel="noopener noreferrer" className="btn btn-link">{project.staging_url}</a></li>}
                            {project.spec_url && <li className="list-group-item">Spec :<a href={project.spec_url} target="_blank" rel="noopener noreferrer" className="btn btn-link">{project.spec_url}</a></li>}
                            {project.design_url && <li className="list-group-item">Design :<a href={project.design_url} target="_blank" rel="noopener noreferrer" className="btn btn-link">{project.design_url}</a></li>}
                        </ul>
                    </div>
                </div>
                <div className="row pt-5 text-center">
                    <div className="col-md-4">
                        <div className="card text-bg-dark mb-3">
                            <div className="card-header">Total Estimated (HH:MM)</div>
                            <div className="card-body">
                                <h5 className="card-title">{MinutesToTime(totalEstimate)}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card text-bg-dark mb-3">
                            <div className="card-header">Total Spent (HH:MM)</div>
                            <div className="card-body">
                                <h5 className="card-title">{MinutesToTime(totalSpent)}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card text-bg-dark mb-3">
                            <div className="card-header">Total Billable (HH:MM)</div>
                            <div className="card-body">
                                <h5 className="card-title">{MinutesToTime(totalSpent - totalNonBillable)}</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-12">
                        <div className='card'>
                            <div className='card-header'>
                                <h4>Pending Tasks
                                    <div className="d-flex float-end">
                                        <Link to={`/admin/add-task/${project.id}`} className='btn btn-primary btn-sm float-end'>Add Task</Link>
                                    </div>
                                </h4>
                            </div>
                            <div className='card-body'>
                                <DataTable
                                    columns={tasksColumns}
                                    data={tasksData}
                                    pagination
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-12 project-timeline">
                        <Chrono
                            items={timeLineData}
                            mode="VERTICAL_ALTERNATING"
                            twoColumns
                        />
                    </div>
                </div>
            </section>
        </>
    );
}

export default ViewProject;