import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import ShowMoreText from "react-show-more-text";
import DataTable from 'react-data-table-component';
import parse from 'html-react-parser';

import { TaskStatuses } from '../../../utils/TaskStatuses';
import { TaskPriority } from '../../../utils/TaskPriority';
import { Currencies } from '../../../utils/Currencies';
import { QuotationStatuses } from '../../../utils/QuotationStatuses';
import { InvoiceStatuses } from '../../../utils/InvoiceStatuses';
import MinutesToTime from '../../../utils/MinutesToTime';

const taskColumns = [
    {
        name: 'Task',
        selector: row => row.task,
        sortable: true,
    },
    {
        name: 'Assignee',
        selector: row => row.assignee,
        sortable: true,
    },
    {
        name: 'Assigned By',
        selector: row => row.assigned_by,
        sortable: true,
    },
    {
        name: 'Due Date',
        selector: row => row.due_date,
        sortable: true,
    },
    {
        name: 'Completed Date',
        selector: row => row.completed_date,
        sortable: true,
    },
    {
        name: 'Task Status',
        selector: row => row.task_status,
        sortable: true,
    },
    {
        name: 'Task Priority',
        selector: row => row.task_priority,
        sortable: true,
    },
    {
        name: 'Actions',
        selector: row => row.actions,
    }
];

let taskData = [];

const ViewClientTask = (props) => {
    const clientTaskId = props.match.params.id;
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [taskList, setTaskList] = useState([]);
    const [clientTaskInput, setClientTask] = useState({
        project_id: '',
        assigned_by_user: '',
        task: '',
        description: '',
        comments: '',
        ideal_date: '',
        task_status: '',
        task_priority: '',
        file: '',
        time_spent: '',
        completed_date: '',
        quotation_amount: '',
        quotation_currency: '',
        quotation_status: '',
        invoice_status: ''
    });

    if (!localStorage.getItem('auth_token')) {
        history.push('/login');
        swal('Unauthorized', 'Authorization Required', 'error');
    } else if (!props.allowedRoles.includes(localStorage.getItem('auth_role'))) {
        history.push('/403');
    }

    const setFormData = useCallback(async () => {
        taskData = [];
        axios.get(`/api/edit-client-task/${clientTaskId}`).then(res => {
            if (res.data.status === 200) {
                setClientTask(res.data.clientTask);
                setTaskList(res.data.tasks);
            } else if (res.data.status === 404) {
                swal("Error", res.data.message, "error");
                history.push('/admin/view-client-tasks');
            }
            setLoading(false);
        });
    }, [history, clientTaskId]);

    const deleteTask = (e, id) => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Record",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(`/api/delete-task/${id}`).then(res => {
                    if (res.data.status === 200) {
                        swal(res.data.message, {
                            icon: "success",
                        });
                        setFormData();
                    } else if (res.data.status === 404) {
                        swal("Error", res.data.message, "error");
                    } else if (res.data.status === 405) {
                        swal("Error", res.data.message, "error");
                    }
                });
            }
        });
    }

    useEffect(() => {
        setFormData();
    }, [setFormData]);

    if (loading) {
        return <h4>Loading View Client Task...</h4>
    } else {
        if (taskList.length > 0) {
            taskData = taskList.map(item => {
                return ({
                    task :  <ShowMoreText
                                lines={1}
                                more="Show more"
                                less="Show less"
                                className="content-css"
                                anchorClass="show-more-less-clickable"
                                expanded={false}
                                width={200}
                                truncatedEndingComponent={"... "}
                            >{item.task}
                            </ShowMoreText>,
                    assignee :   item.assignee && item.assignee.name,
                    assigned_by :   item.assigned_by ? item.assigned_by.name : 'Self Assigned',
                    due_date :  item.due_date,
                    completed_date  :   item.completed_date,
                    task_status :    TaskStatuses.map((taskStatus, idx) => item.task_status === taskStatus.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskStatus.value}`}>{taskStatus.label}</span> : ''),
                    task_priority :    TaskPriority.map((taskPriority, idx) => item.task_priority === taskPriority.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskPriority.value}`}>{taskPriority.label}</span> : ''),
                    actions :   <div className="d-flex">
                        <Link to={`/admin/view-task/${item.id}`} className="btn btn-outline-secondary btn-sm"><i className="fa fa-eye"></i></Link>
                        <button type='button' onClick={(e) => deleteTask(e, item.id)} className='btn btn-outline-danger btn-sm ms-1'><i className="fa fa-trash"></i></button>
                    </div>,
                });
            });
        }
    }
    
    return (
        <div className='px-4'>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h4>View Client Task
                        <Link to="/admin/view-client-tasks" className='btn btn-primary btn-sm float-end'>BACK</Link>
                    </h4>
                </div>
                <div className='card-body'>
                    <form encType='multipart/form-data' id="TASK_FORM" className="row g-3">
                        <div className="col-md-4">
                            <label htmlFor="client_id" className="form-label">Client</label>
                            <input type="text" name="client_id" value={clientTaskInput.assigned_by_user && clientTaskInput.assigned_by_user.client && clientTaskInput.assigned_by_user.client.name} className='form-control' id="client_id" disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="project_id" className="form-label">Project</label>
                            <input type="text" name="project_id" value={clientTaskInput.project && clientTaskInput.project.name} className='form-control' id="project_id" disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="assigned_by" className="form-label">Assigned By</label>
                            <input type="text" name="assigned_by" value={clientTaskInput.assigned_by_user && clientTaskInput.assigned_by_user.name} className='form-control' id="assigned_by" disabled />
                        </div>
                        <div className="col-12">
                            <label htmlFor="task" className="form-label">Task</label>
                            <input type="text" name="task" value={clientTaskInput.task} className='form-control' id="task" disabled />
                        </div>
                        <div className="col-12">
                            <label htmlFor="description" className="form-label">Description</label>
                            <div className='form-control html-desc'>{clientTaskInput.description && parse(clientTaskInput.description)}</div>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="ideal_date" className="form-label">Ideal Date</label>
                            <input type="date" name="ideal_date" value={clientTaskInput.ideal_date} className='form-control' id='ideal_date' disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="completed_date" className="form-label">Completed Date</label>
                            <input type="date" name="completed_date" value={clientTaskInput.completed_date} className='form-control' id='completed_date' disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="time_spent" className="form-label">Time Spent (HH:MM)</label>
                            <input type="text" name="time_spent" value={MinutesToTime(clientTaskInput.time_spent)} className='form-control' id='time_spent' disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="task_status" className="form-label">Task Status</label>
                            <select name='task_status' value={clientTaskInput.task_status} className='form-select' id='task_status' disabled>
                                <option value="">Select Task Status</option>
                                {TaskStatuses.map((taskStatus, idx) => <option key={idx} value={taskStatus.value}>{taskStatus.label}</option>)}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="task_priority" className="form-label">Task Priority</label>
                            <select name='task_priority' value={clientTaskInput.task_priority} className='form-select' id='task_priority' disabled>
                                <option value="">Select Task Priority</option>
                                {TaskPriority.map((taskPriority, idx) => <option key={idx} value={taskPriority.value}>{taskPriority.label}</option>)}
                            </select>
                        </div>
                        {clientTaskInput.file &&
                            <div className="col-md-4">
                                <label htmlFor="file" className="form-label">File</label>
                                <div><a href={`${process.env.REACT_APP_BACKEND_URL}/${clientTaskInput.file}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-dark mt-2">View File <i className="fa fa-external-link" aria-hidden="true"></i></a></div>
                            </div>
                        }
                        <div className="col-12">
                            <label htmlFor="comments" className="form-label">Comments</label>
                            <div className='form-control html-desc'>{clientTaskInput.comments && parse(clientTaskInput.comments)}</div>
                        </div>
                        <h4 className="card-title mt-5">Quotation</h4>
                        <div className="col-md-3">
                            <label htmlFor="quotation_amount" className="form-label">Quotation Amount</label>
                            <input type="number" name="quotation_amount" value={clientTaskInput.quotation_amount} className='form-control' id='quotation_amount' step="0.1" disabled />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="quotation_currency" className="form-label">Quotation Currency</label>
                            <select name='quotation_currency' value={clientTaskInput.quotation_currency} className='form-select' id='quotation_currency' disabled>
                                <option value="">Select Currency</option>
                                {Currencies.map((currency, idx) => <option key={idx} value={currency.value}>{currency.label}</option>)}
                            </select>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="quotation_status" className="form-label">Quotation Status</label>
                            <select name='quotation_status' value={clientTaskInput.quotation_status} className='form-select' id='quotation_status' disabled>
                                <option value="">Select Status</option>
                                {QuotationStatuses.map((quotationStatus, idx) => <option key={idx} value={quotationStatus.value}>{quotationStatus.label}</option>)}
                            </select>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="invoice_status" className="form-label">Invoice Status</label>
                            <select name='invoice_status' value={clientTaskInput.invoice_status} className='form-select' id='invoice_status' disabled>
                                <option value="">Select Status</option>
                                {InvoiceStatuses.map((invoiceStatus, idx) => <option key={idx} value={invoiceStatus.value}>{invoiceStatus.label}</option>)}
                            </select>
                        </div>
                    </form>
                </div>
            </div>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h5>Developer Task List
                        <Link to={`/admin/add-task-by-client-task/${clientTaskId}`} className="btn btn-primary btn-sm float-end">Add Task</Link>
                    </h5>
                </div>
                <div className='card-body'>
                    <DataTable
                        columns={taskColumns}
                        data={taskData}
                        pagination
                    />
                </div>
            </div>
        </div>
    );
}

export default ViewClientTask;