import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { modules } from '../../../utils/EditorToolbar';
import { TaskStatuses } from '../../../utils/TaskStatuses';
import { TaskPriority } from '../../../utils/TaskPriority';
import { TaskTypes } from '../../../utils/TaskTypes';

const AddTaskByClientTask = (props) => {
    const clientTaskId = props.match.params.clientTaskId;
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [pending, setPending] = useState(false);
    const [assigneeList, setAssigneeList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [clientTaskList, setClientTaskList] = useState([]);
    const [allCheckbox, setCheckboxes] = useState([]);
    const [picture, setPicture] = useState([]);
    const [errorList, setError] = useState([]);
    const [estimate, setEstimate] = useState({
        estimate_hours: 0,
        estimate_minutes: 0,
    });
    const [taskInput, setTask] = useState({
        client_task_id: '',
        project_id: '',
        assignee_id: '',
        task: '',
        description: '',
        due_date: new Date().toISOString().slice(0,10),
        task_status: 'not-started',
        task_priority: 'medium',
        task_type: '',
    });

    const setClientTaskData = useCallback(async (clientTaskId) => {
        if (clientTaskId) {
            axios.get(`/api/all-client-tasks`).then(res => {
                if (res.data.status === 200) {
                    let clientTask = res.data.clientTasks.find(res => res.id === parseInt(clientTaskId));
                    setTask(
                        { ...taskInput,
                            client_task_id: parseInt(clientTaskId),
                            project_id: clientTask.project_id
                        }
                    );
                }
            });
        }
        setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);

    useEffect(() => {
        axios.get(`/api/all-client-tasks`).then(res => {
            if (res.data.status === 200) {
                setClientTaskList(res.data.clientTasks);
            }
        });
        axios.get(`/api/all-projects`).then(res => {
            if (res.data.status === 200) {
                setProjectList(res.data.projects);
            }
        });
        axios.get(`/api/all-employees`).then(res => {
            if (res.data.status === 200) {
                setAssigneeList(res.data.users);
            }
        });
        setClientTaskData(clientTaskId);
    }, [setClientTaskData, clientTaskId]);

    const resetForm = () => {
        setTask({
            client_task_id: '',
            project_id: '',
            assignee_id: '',
            task: '',
            description: '',
            due_date: '',
            task_status: '',
            task_priority: '',
            task_type: '',
        });

        setPicture([]);
        setCheckboxes([]);
    }

    const handleInput = (e) => {
        e.persist();
        setTask({ ...taskInput, [e.target.name]: e.target.value });
    }

    const handleEstimate = (e) => {
        e.persist();
        setEstimate({ ...estimate, [e.target.name]: e.target.value });
    }

    const handleDescription = (value) => {
        setTask({ ...taskInput, description: value });
    }

    const handleImage = (e) => {
        e.persist();
        setPicture({ image: e.target.files[0] });
    }

    const handleCheckbox = (e) => {
        e.persist();
        setCheckboxes({ ...allCheckbox, [e.target.name]: e.target.checked });
    }

    const submitTask = (e) => {
        setPending(true);
        e.preventDefault();
        
        let hours = estimate.estimate_hours ? parseInt(estimate.estimate_hours) : 0;
        let minutes = estimate.estimate_minutes ? parseInt(estimate.estimate_minutes) : 0;
        let estimateTime = hours*60 + minutes;

        const formData = new FormData();
        formData.append('client_task_id', taskInput.client_task_id);
        formData.append('project_id', taskInput.project_id);
        formData.append('assignee_id', taskInput.assignee_id);
        formData.append('task', taskInput.task);
        formData.append('description', taskInput.description);
        formData.append('due_date', taskInput.due_date);
        formData.append('estimate_time', estimateTime);
        formData.append('image', picture.image);
        formData.append('task_status', taskInput.task_status);
        formData.append('task_priority', taskInput.task_priority);
        formData.append('task_type', taskInput.task_type);
        formData.append('status', allCheckbox.status ? '1' : '0');

        axios.get('/sanctum/csrf-cookie').then(response => {
            axios({
                method: "post",
                url: `/api/store-task`,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then(res => {
                if (res.data.status === 200) {
                    swal("Success", res.data.message, "success");
                    resetForm();
                    setError([]);
                    setClientTaskData(clientTaskId);
                    history.push('/admin/view-client-tasks');
                    setPending(false);
                } else if (res.data.status === 422) {
                    swal("All Fields are mandatory", "", "error");
                    setError(res.data.errors);
                    setPending(false);
                }
            });
        });
    }

    if (loading) {
        return <h4>Loading...</h4>
    }

    return (
        <div className='px-4'>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h4>Add Tasks
                        <Link to="/admin/view-tasks" className='btn btn-primary btn-sm float-end'>View Tasks</Link>
                    </h4>
                </div>
                <div className='card-body'>
                    <form encType='multipart/form-data' onSubmit={submitTask} id="TASK_FORM" className="row g-3">
                        <div className="col-md-4">
                            <label htmlFor="project_id" className="form-label">Project</label>
                            <select name='project_id' onChange={handleInput} value={taskInput.project_id} className='form-select' id='project_id' disabled>
                                <option value="">Select Project</option>
                                {
                                    projectList.map(item => {
                                        return (
                                            <option value={item.id} key={item.id}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                            <small className='text-danger'>{errorList.project_id}</small>
                        </div>
                        <div className="col-md-8">
                            <label htmlFor="client_task_id" className="form-label">Client Task</label>
                            <select name='client_task_id' onChange={handleInput} value={taskInput.client_task_id} className='form-select' id='client_task_id' disabled>
                                <option value="">Select Client Task</option>
                                {
                                    clientTaskList.map(item => {
                                        return (
                                            <option value={item.id} key={item.id}>{item.assigned_by_user.client.name + ' - ' + item.task + ' ( Ideal on '+item.ideal_date+')'}</option>
                                        )
                                    })
                                }
                            </select>
                            <small className='text-danger'>{errorList.client_task_id}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="assignee_id" className="form-label">Select Assignee</label>
                            <select name='assignee_id' onChange={handleInput} value={taskInput.assignee_id} className='form-select' id='assignee_id'>
                                <option value="">Select Assignee</option>
                                {
                                    assigneeList.map(item => {
                                        return (
                                            <option value={item.id} key={item.id}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                            <small className='text-danger'>{errorList.assignee_id}</small>
                        </div>
                        <div className="col-md-8">
                            <label htmlFor="task" className="form-label">Task</label>
                            <input type="text" name="task" onChange={handleInput} value={taskInput.task} className='form-control' id='task' />
                            <small className='text-danger'>{errorList.task}</small>
                        </div>
                        <div className="col-12">
                            <label htmlFor="description" className="form-label">Description</label>
                            <ReactQuill
                                theme="snow"
                                value={taskInput.description}
                                onChange={handleDescription}
                                modules={modules}
                            />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="task_type" className="form-label">Task Type</label>
                            <select name='task_type' onChange={handleInput} value={taskInput.task_type} className='form-select' id='task_type'>
                                <option value="">Select Task Type</option>
                                {TaskTypes.map((taskType, idx) => <option key={idx} value={taskType.value}>{taskType.label}</option>)}
                            </select>
                            <small className='text-danger'>{errorList.task_type}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="task_status" className="form-label">Task Status</label>
                            <select name='task_status' onChange={handleInput} value={taskInput.task_status} className='form-select' id='task_status'>
                                <option value="">Select Task Status</option>
                                {TaskStatuses.map((taskStatus, idx) => <option key={idx} value={taskStatus.value}>{taskStatus.label}</option>)}
                            </select>
                            <small className='text-danger'>{errorList.task_status}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="task_priority" className="form-label">Task Priority</label>
                            <select name='task_priority' onChange={handleInput} value={taskInput.task_priority} className='form-select' id='task_priority'>
                                <option value="">Select Task Priority</option>
                                {TaskPriority.map((taskPriority, idx) => <option key={idx} value={taskPriority.value}>{taskPriority.label}</option>)}
                            </select>
                            <small className='text-danger'>{errorList.task_priority}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="due_date" className="form-label">Due Date</label>
                            <input type="date" name="due_date" onChange={handleInput} value={taskInput.due_date} className='form-control' id='due_date' />
                            <small className='text-danger'>{errorList.due_date}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="estimate_time" className="form-label">Estimate Time (HH:MM)</label>
                            <div className="input-group">
                                <input type="number" name="estimate_hours" onChange={handleEstimate} value={estimate.estimate_hours} min="0" max="999" step="1" id='estimate_time_hours' className="form-control text-end" placeholder="Hours" aria-label=""/>
                                <span className="input-group-text">:</span>
                                <input type="number" name="estimate_minutes" onChange={handleEstimate} value={estimate.estimate_minutes} min="0" max="59" step="1" id='estimate_time_minutes' className="form-control" placeholder="Minutes" aria-label=""/>
                            </div>
                            <small className='text-danger'>{errorList.estimate_time}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="image" className="form-label">Upload File</label>
                            <input type='file' name='image' onChange={handleImage} className='form-control' id='image' />
                            <small className='text-danger'>{errorList.image}</small>
                        </div>
                        <div className="col-12">
                            <div className="form-check">
                                <input type="checkbox" name='status' onChange={handleCheckbox} defaultChecked={allCheckbox.status === 1 ? true : false} className="form-check-input" id="status" />
                                <label htmlFor="status" className="form-check-label">Status (checked=Hidden)</label>
                            </div>
                        </div>
                        <div className="col-12">
                            <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-primary px-4 mt-2 float-end'>
                                <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AddTaskByClientTask;