import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

const EditClient = (props) => {
    const clientId = props.match.params.id;
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [pending, setPending] = useState(false);
    const [clientInput, setClient] = useState({
        slug: '',
        name: '',
        description: '',
        url: '',
    });
    const [allCheckbox, setCheckboxes] = useState([]);
    const [picture, setPicture] = useState([]);
    const [errorList, setError] = useState([]);

    const setFormData = useCallback(async () => {
        axios.get(`/api/edit-client/${clientId}`).then(res => {
            if (res.data.status === 200) {
                setClient(res.data.client);
                setPicture({ logo: res.data.client.logo });
                setCheckboxes(res.data.client);
            } else if (res.data.status === 404) {
                swal("Error", res.data.message, "error");
                history.push('/admin/view-clients');
            }
            setLoading(false);
        });
    }, [history, clientId])

    useEffect(() => {
        setFormData();
    }, [setFormData]);
    
    const handleInput = (e) => {
        e.persist();
        setClient({ ...clientInput, [e.target.name]: e.target.value });
    }

    const handleLogo = (e) => {
        e.persist();
        setPicture({ logo: e.target.files[0] });
    }

    const handleCheckbox = (e) => {
        e.persist();
        setCheckboxes({ ...allCheckbox, [e.target.name]: e.target.checked });
    }

    const updateClient = (e) => {
        e.preventDefault();
        setPending(true);

        axios.get('/sanctum/csrf-cookie').then(response => {
            const formData = new FormData();
            formData.append('logo', picture.logo);
            formData.append('slug', clientInput.slug);
            formData.append('name', clientInput.name);
            formData.append('description', clientInput.description);
            formData.append('url', clientInput.url);
            formData.append('status', allCheckbox.status ? '1' : '0');
            formData.append('_method', 'PUT');
            
            axios.post(`/api/update-client/${clientId}`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
                if (res.data.status === 200) {
                    swal("Success", res.data.message, "success");
                    setError([]);
                    setFormData();
                    setPending(false);
                } else if (res.data.status === 422) {
                    swal("All fields are mandatory", "", "error");
                    setError(res.data.errors);
                    setPending(false);
                } else if (res.data.status === 404) {
                    swal("Error", res.data.message, "error");
                    history.push('/admin/view-clients');
                    setPending(false);
                }
            });
        });
    }

    if (loading) {
        return <h4>Loading Edit Client...</h4>
    }

    return (
        <div className='px-4'>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h4>Edit Client
                        <Link to="/admin/view-clients" className='btn btn-primary btn-sm float-end'>BACK</Link>
                    </h4>
                </div>
                <div className='card-body'>
                    <form encType='multipart/form-data' onSubmit={updateClient} id="CLIENT_FORM" className="row g-3">
                        <div className="col-md-4">
                            <label htmlFor="slug" className="form-label">Slug</label>
                            <input type="text" name="slug" onChange={handleInput} value={clientInput.slug} className='form-control' id='slug' />
                            <small className='text-danger'>{errorList.slug}</small>
                        </div>
                        <div className="col-md-8">
                            <label htmlFor="name" className="form-label">Name</label>
                            <input type="text" name="name" onChange={handleInput} value={clientInput.name} className='form-control' id='name' />
                            <small className='text-danger'>{errorList.name}</small>
                        </div>
                        <div className="col-12">
                            <label htmlFor="description" className="form-label">Description</label>
                            <textarea name="description" onChange={handleInput} value={clientInput.description} className='form-control' id='description'></textarea>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="url" className="form-label">URL</label>
                            <input type="text" name="url" onChange={handleInput} value={clientInput.url} className='form-control' id='url' />
                            <small className='text-danger'>{errorList.url}</small>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="logo" className="form-label">Logo</label>
                            <input type='file' name='logo' onChange={handleLogo} className='form-control' id='logo' />
                            <small className='text-danger'>{errorList.logo}</small>
                            {clientInput.logo && <div><img src={`${process.env.REACT_APP_BACKEND_URL}/${clientInput.logo}`} className='logo mt-2' alt={clientInput.id} /></div>}
                        </div>
                        <div className="col-12">
                            <div className="form-check">
                                <input type="checkbox" name='status' onChange={handleCheckbox} defaultChecked={allCheckbox.status === 1 ? true : false} className="form-check-input" id="status" />
                                <label htmlFor="status" className="form-check-label">Status (checked=Hidden)</label>
                            </div>
                        </div>
                        <div className="col-12">
                            <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-primary px-4 float-end'>
                              <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                              Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditClient;