import React, { useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert';

const ResetPassword = (props) => {
  const token = props.match.params.token;
  const email = props.match.params.email;

    const [pending, setPending] = useState(false);
    const [resetPassword, setResetPassword] = useState({
        password: '',
        password_confirmation: '',
        error_list: []
    });

    const handleInput = (e) => {
        e.persist();
        setResetPassword({...resetPassword, [e.target.name]: e.target.value});
    }

    const resetPasswordSubmit = (e) => {
        e.preventDefault();
        setPending(true);
        const data = {
          token,
          email,
          password: resetPassword.password,
          password_confirmation: resetPassword.password_confirmation,

        }
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post('/api/reset-password', data).then(res => {
                if (res.data.status === 200) {
                    setResetPassword({
                      password: '',
                      password_confirmation: '',
                      error_list: []
                    });
                    swal("Success", res.data.message, "success");
                    setPending(false);
                } else if (res.data.status === 401) {
                  swal("Error", res.data.message, "error");
                  setPending(false);
                } else {
                  setResetPassword({ ...resetPassword, error_list: res.data.validation_errors });
                  setPending(false);
                }
            });
        });
    }

    return (
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-xl-9 mx-auto">
              <div className="card flex-row my-5 border-0 shadow rounded-3 overflow-hidden">
                <div className="card-img-left d-none d-md-flex">
                </div>
                <div className="card-body p-4 p-sm-5">
                  <h5 className="card-title text-center mb-5 fw-light fs-5">Register</h5>
                  <form  onSubmit={resetPasswordSubmit}>

                    <div className="form-floating mb-3">
                      {/* <input id="floatingInputUsername" placeholder="Full Name" type='text' name='name' onChange={handleInput} value={resetPassword.name} className='form-control' required autofocus/> */}
                      <input  id="floatingInputUsername" placeholder="Password" type='password' name='password' onChange={handleInput} value={resetPassword.password} className='form-control' />
                      <label htmlFor="floatingInputUsername">Password</label>
                      <label>{resetPassword.error_list.password}</label>
                    </div>

                    <div className="form-floating mb-3">
                      {/* <input id="floatingInputEmail" placeholder="name@example.com" type='email' name='email' onChange={handleInput} value={resetPassword.email} className='form-control' /> */}
                      <input id="floatingInputEmail" placeholder="Retype Password" type='password' name='password_confirmation' onChange={handleInput} value={resetPassword.password_confirmation} className='form-control' />
                      <label htmlFor="floatingInputEmail">Confirm Password</label>
                      <label>{resetPassword.error_list.password_confirmation}</label>
                    </div>

                    <div className="d-grid mb-2">
                      <button disabled = {pending ? 'disabled' : ''} className="btn btn-lg btn-primary btn-login fw-bold text-uppercase" type="submit">
                        <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
}

export default ResetPassword;