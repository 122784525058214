import Home from '../components/frontend/Home';
import About from '../components/frontend/About';
import Contact from '../components/frontend/Contact';
import Profile from '../components/frontend/Profile';
import Page403 from '../components/errors/Page403';
import Page404 from '../components/errors/Page404';
import Login from '../components/frontend/auth/Login';
import Register from '../components/frontend/auth/Register';
import ForgotPassword from '../components/frontend/auth/ForgotPassword';
import ResetPassword from '../components/frontend/auth/ResetPassword';
import ChangePassword from '../components/frontend/auth/ChangePassword';
import ViewClients from '../components/frontend/clients/ViewClients';
import ViewClient from '../components/frontend/clients/ViewClient';
import ViewProjects from '../components/frontend/projects/ViewProjects';
import ViewProject from '../components/frontend/projects/ViewProject';
import AddTask from '../components/frontend/tasks/AddTask';
import EditTask from '../components/frontend/tasks/EditTask';
import ViewTask from '../components/frontend/tasks/ViewTask';
import ViewTasks from '../components/frontend/tasks/ViewTasks';
import AddTimeLog from '../components/frontend/time-logs/AddTimeLog';
import ViewTimeLogs from '../components/frontend/time-logs/ViewTimeLogs';
import ViewWeeklyTimeLogs from '../components/frontend/time-logs/ViewWeeklyTimeLogs';
import AddLeave from '../components/frontend/leaves/AddLeave';
import ViewLeaves from '../components/frontend/leaves/ViewLeaves';
import ViewLeave from '../components/frontend/leaves/ViewLeave';
import Page401 from '../components/errors/Page401';
import MyProjects from '../components/frontend/projects/MyProjects';
import ProjectTasks from '../components/frontend/projects/ProjectTasks';
import ViewProjectTask from '../components/frontend/projects/ViewProjectTask';
import EditProjectTask from '../components/frontend/projects/EditProjectTask';

const PublicRouteList = [
    { path: '/', exact: true, name: 'Home', component: Home, allowedRoles: ['admin', 'user', 'client'] },
    { path: '/about', exact: true, name: 'About', component: About, allowedRoles: ['admin', 'user', 'client'] },
    { path: '/contact', exact: true, name: 'Contact', component: Contact, allowedRoles: ['admin', 'user', 'client'] },
    { path: '/profile', exact: true, name: 'Profile', component: Profile, allowedRoles: ['admin', 'user', 'client'] },
    { path: '/clients', exact: true, name: 'ViewClients', component: ViewClients, allowedRoles: ['admin', 'user'] },
    { path: '/client/:slug', exact: true, name: 'ViewClient', component: ViewClient, allowedRoles: ['admin', 'user'] },
    { path: '/projects', exact: true, name: 'ViewProjects', component: ViewProjects, allowedRoles: ['admin', 'user', 'client'] },
    { path: '/my-projects', exact: true, name: 'MyProjects', component: MyProjects, allowedRoles: ['client'] },
    { path: '/my-tasks/:slug', exact: true, name: 'ProjectTasks', component: ProjectTasks, allowedRoles: ['client'] },
    { path: '/view-project-task/:id', exact: true, name: 'ViewProjectTask', component: ViewProjectTask, allowedRoles: ['client'] },
    { path: '/edit-project-task/:id', exact: true, name: 'EditProjectTask', component: EditProjectTask, allowedRoles: ['client'] },
    { path: '/client/project/:slug', exact: true, name: 'ViewProject', component: ViewProject, allowedRoles: ['admin', 'user'] },
    { path: '/add-task/:projectId?', exact: true, name: 'AddTask', component: AddTask, allowedRoles: ['admin', 'user'] },
    { path: '/edit-task/:id', exact: true, name: 'EditTask', component: EditTask, allowedRoles: ['admin', 'user'] },
    { path: '/view-task/:id', exact: true, name: 'ViewTask', component: ViewTask, allowedRoles: ['admin', 'user'] },
    { path: '/tasks', exact: true, name: 'ViewTasks', component: ViewTasks, allowedRoles: ['admin', 'user'] },
    { path: '/add-time-log/:taskId?', exact: true, name: 'AddTimeLog', component: AddTimeLog, allowedRoles: ['admin', 'user'] },
    { path: '/time-logs', exact: true, name: 'ViewTimeLogs', component: ViewTimeLogs, allowedRoles: ['admin', 'user'] },
    { path: '/weekly-timelogs', exact: true, name: 'ViewWeeklyTimeLogs', component: ViewWeeklyTimeLogs, allowedRoles: ['admin', 'user'] },
    { path: '/add-leave', exact: true, name: 'AddLeave', component: AddLeave, allowedRoles: ['admin', 'user'] },
    { path: '/leaves', exact: true, name: 'ViewLeaves', component: ViewLeaves , allowedRoles: ['admin', 'user']},
    { path: '/view-leave/:id', exact: true, name: 'ViewLeave', component: ViewLeave, allowedRoles: ['admin', 'user'] },
    { path: '/change-password', exact: true, name: 'ChangePassword', component: ChangePassword, allowedRoles: ['admin', 'user', 'client'] },
    { path: '/401', exact: true, name: 'Page401', component: Page401, allowedRoles: ['admin', 'user', 'client'] },
    { path: '/403', exact: true, name: 'Page403', component: Page403, allowedRoles: ['admin', 'user', 'client'] },
    { path: '/404', exact: true, name: 'Page404', Page404: Page404, allowedRoles: ['admin', 'user', 'client'] },
    { path: '/login', exact: true, name: 'Login', component: Login, allowedRoles: ['admin', 'user', 'client'] },
    { path: '/register', exact: true, name: 'Register', component: Register, allowedRoles: ['admin', 'user'] },
    { path: '/forgot-password', exact: true, name: 'ForgotPassword', component: ForgotPassword, allowedRoles: ['admin', 'user', 'client'] },
    { path: '/reset-password/:token/:email', exact: true, name: 'ResetPassword', component: ResetPassword, allowedRoles: ['admin', 'user', 'client'] },
];

export default PublicRouteList;