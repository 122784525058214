import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {

    const removeBodyClass = () => {
      document.body.classList.remove('sb-sidenav-toggled');
    }


    return (
        <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
            <div className="sb-sidenav-menu">
                <div className="nav">
                    <Link className="nav-link" to="/admin/dashboard">
                        <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                        Dashboard
                    </Link>

                    {
                      localStorage.getItem('auth_designation') !== 'qa-intern' &&
                      <Link onClick={removeBodyClass} className="nav-link" to="/admin/view-users">
                        <div className="sb-nav-link-icon"><i className="fas fa-users"></i></div>
                        Users
                    </Link>
                    }

                    <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#collapseClient" aria-expanded="false" aria-controls="collapseClient">
                        <div className="sb-nav-link-icon"><i className="fas fa-briefcase"></i></div>
                        Clients
                        <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                    </Link>
                    <div className="collapse" id="collapseClient" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                        <nav className="sb-sidenav-menu-nested nav">
                            <Link onClick={removeBodyClass} className="nav-link" to="/admin/add-client">Add Client</Link>
                            <Link onClick={removeBodyClass} className="nav-link" to="/admin/view-clients">All Clients</Link>
                        </nav>
                    </div>

                    <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#collapseProject" aria-expanded="false" aria-controls="collapseProject">
                        <div className="sb-nav-link-icon"><i className="fas fa-tv"></i></div>
                        Projects
                        <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                    </Link>
                    <div className="collapse" id="collapseProject" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                        <nav className="sb-sidenav-menu-nested nav">
                            <Link onClick={removeBodyClass} className="nav-link" to="/admin/add-project">Add Project</Link>
                            <Link onClick={removeBodyClass} className="nav-link" to="/admin/view-projects">All Projects</Link>
                        </nav>
                    </div>

                    <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#collapseTask" aria-expanded="false" aria-controls="collapseTask">
                        <div className="sb-nav-link-icon"><i className="fas fa-list-check"></i></div>
                        Tasks
                        <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                    </Link>
                    <div className="collapse" id="collapseTask" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                        <nav className="sb-sidenav-menu-nested nav">
                            <Link onClick={removeBodyClass} className="nav-link" to="/admin/add-task">Add Task</Link>
                            <Link onClick={removeBodyClass} className="nav-link" to="/admin/view-tasks">All Tasks</Link>
                        </nav>
                    </div>

                    <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#collapseClientTask" aria-expanded="false" aria-controls="collapseClientTask">
                        <div className="sb-nav-link-icon"><i className="fas fa-list-alt"></i></div>
                        Client Tasks
                        <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                    </Link>
                    <div className="collapse" id="collapseClientTask" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                        <nav className="sb-sidenav-menu-nested nav">
                            <Link onClick={removeBodyClass} className="nav-link" to="/admin/add-client-task">Add Client Task</Link>
                            <Link onClick={removeBodyClass} className="nav-link" to="/admin/view-client-tasks">All Client Tasks</Link>
                        </nav>
                    </div>

                    {
                      localStorage.getItem('auth_designation') !== 'qa-intern' &&
                      <Link onClick={removeBodyClass} className="nav-link" to="/admin/view-leaves">
                        <div className="sb-nav-link-icon"><i className="fas fa-gavel"></i></div>
                        Leaves
                      </Link>
                    }

                    {
                      localStorage.getItem('auth_designation') !== 'qa-intern' &&
                      <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#collapseReport" aria-expanded="false" aria-controls="collapseReport">
                        <div className="sb-nav-link-icon"><i className="fas fa-pie-chart"></i></div>
                        Reports
                        <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                    </Link>
                    }

                    {
                      localStorage.getItem('auth_designation') !== 'qa-intern' &&
                      <div className="collapse" id="collapseReport" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                        <nav className="sb-sidenav-menu-nested nav">
                            <Link onClick={removeBodyClass} className="nav-link" to="/admin/resource-allocation-report">Resource Allocation</Link>
                            <Link onClick={removeBodyClass} className="nav-link" to="/admin/project-time-report">Project Time Report</Link>
                            <Link onClick={removeBodyClass} className="nav-link" to="/admin/weekly-timelog-report">Weekly Time Log Report</Link>
                        </nav>
                      </div>
                    }
                </div>
            </div>
            <div className="sb-sidenav-footer">
                <div className="small">Logged in as:</div>
                {localStorage.getItem('auth_name')}
            </div>
        </nav>
    );
}

export default Sidebar;