import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

const ForgotPassword = () => {
    const [pending, setPending] = useState(false);
    const [forgotPasswrd, setForgotPassord] = useState({
        email: '',
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setForgotPassord({...forgotPasswrd, [e.target.name]: e.target.value});
    }

    const forgotPasswordSubmit = (e) => {
        e.preventDefault();
        setPending(true);
        const data = {
          email: forgotPasswrd.email,
        }

        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post('/api/forgot-password', data).then(res => {
                if (res.data.status === 200) {
                  setForgotPassord({
                    email: '',
                    error_list: [],
                  });
                  swal("Success", res.data.message, "success");
                  setPending(false);
                } else if (res.data.status === 402) {
                  swal("Warning", res.data.message, "warning");
                  setPending(false);
                } else if (res.data.status === 401) {
                  swal("Error", res.data.message, "error");
                  setPending(false);
                } else {
                  setForgotPassord({ ...forgotPasswrd, error_list: res.data.validation_errors });
                  setPending(false);
                }
            });
        });
    }

    return (
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-xl-9 mx-auto">
              <div className="card flex-row my-5 border-0 shadow rounded-3 overflow-hidden">
                <div className="card-img-left d-none d-md-flex">
                </div>
                <div className="card-body p-4 p-sm-5">
                  <h5 className="card-title text-center mb-5 fw-light fs-5">Forgot Password</h5>
                  <form onSubmit={forgotPasswordSubmit}>
                    <div className="form-floating mb-3">
                      <input type='email' id="floatingInputEmail" placeholder="name@example.com" name='email' onChange={handleInput} value={forgotPasswrd.email} className='form-control' />
                      <label htmlFor="floatingInputEmail">Email address</label>
                      <label>{forgotPasswrd.error_list.email}</label>
                    </div>
                    <hr/>
                    <div className="d-grid mb-2">
                      <button disabled = {pending ? 'disabled' : ''} className="btn btn-lg btn-primary btn-login fw-bold text-uppercase" type="submit">
                        <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                        Get Password Reset Link
                      </button>
                    </div>
                    <Link className="d-block text-center mt-2 small" to="/login">Have an Account? Log In</Link>
                    <Link className="d-block text-center mt-2 small" to="/register">Don't Have an Account? Register</Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
}

export default ForgotPassword;