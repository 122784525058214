import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import ShowMoreText from "react-show-more-text";
import DataTable from 'react-data-table-component';
import parse from 'html-react-parser';

import { TaskStatuses } from '../../../utils/TaskStatuses';
import { TaskPriority } from '../../../utils/TaskPriority';
import { TaskTypes } from '../../../utils/TaskTypes';
import MinutesToTime from '../../../utils/MinutesToTime';

const timeLogColumns = [
    {
        name: 'Date',
        selector: row => row.date,
        sortable: true,
    },
    {
        name: 'Spent',
        selector: row => row.spent,
        sortable: true,
    },
    {
        name: 'Billable',
        selector: row => row.billable,
        sortable: true,
    },
    {
        name: 'Note',
        selector: row => row.note,
    }
];

let timeLogData = [];

const ViewTask = (props) => {
    const taskId = props.match.params.id;
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [projectList, setProjectList] = useState([]);
    const [timeLogList, setTimeLogList] = useState([]);
    const [errorList, setError] = useState([]);
    const [pending, setPending] = useState(false);
    const [estimate, setEstimate] = useState({
        estimate_hours: 0,
        estimate_minutes: 0,
    });
    const [taskInput, setTask] = useState({
        project_id: '',
        task: '',
        description: '',
        due_date: '',
        estimate_time: '',
        task_status: '',
        task_priority: '',
        image: '',
        total_spent_time: '',
        total_non_billable_time: '',
        completed_date: '',
        qa_assignee: '',
        qa_comments: '',
        assigned_by: '',
        locked: ''
    });

    if (!localStorage.getItem('auth_token')) {
        history.push('/login');
        swal('Unauthorized', 'Authorization Required', 'error');
    } else if (!props.allowedRoles.includes(localStorage.getItem('auth_role'))) {
        history.push('/403');
    }

    const setFormData = useCallback(async () => {
        timeLogData = [];
        axios.get(`/api/edit-task-data/${taskId}`).then(res => {
            if (res.data.status === 200) {
                setTask(res.data.task);
                setTimeLogList(res.data.timeLogs);
                let estimateTime = res.data.task.estimate_time;
                let estimateObject = {
                    estimate_hours : Math.floor(estimateTime/60),
                    estimate_minutes : estimateTime - (Math.floor(estimateTime/60))*60
                }
                setEstimate(estimateObject);
            } else if (res.data.status === 404) {
                swal("Error", res.data.message, "error");
                history.push('/tasks');
            }
            setLoading(false);
        });
    }, [history, taskId]);

    useEffect(() => {
        axios.get(`/api/get-projects`).then(res => {
            if (res.data.status === 200) {
                setProjectList(res.data.projects);
            }
        });
        setFormData();
    }, [setFormData]);

    const handleEstimate = (e) => {
        e.persist();
        setEstimate({ ...estimate, [e.target.name]: e.target.value });
    }

    const updateTaskEstimate = (e) => {
        e.preventDefault();
        setPending(true);

        axios.get('/sanctum/csrf-cookie').then(response => {
            let hours = estimate.estimate_hours ? parseInt(estimate.estimate_hours) : 0;
            let minutes = estimate.estimate_minutes ? parseInt(estimate.estimate_minutes) : 0;
            let estimateTime = hours*60 + minutes;

            const formData = new FormData();
            formData.append('estimate_time', estimateTime);
            formData.append('_method', 'PUT');

            axios.post(`/api/update-task-estimate/${taskId}`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
                if (res.data.status === 200) {
                    swal("Success", res.data.message, "success");
                    setError([]);
                    setFormData();
                    history.push('/tasks');
                    setPending(false);
                } else if (res.data.status === 422) {
                    swal("All fields are mandatory", "", "error");
                    setError(res.data.errors);
                    setPending(false);
                } else if (res.data.status === 404) {
                    swal("Error", res.data.message, "error");
                    history.push('/tasks');
                    setPending(false);
                }
            });
        });
    }

    if (loading) {
        return <h4>Loading View Task...</h4>
    } else {
        if (timeLogList.length > 0) {
            timeLogData = timeLogList.map(item => {
                return ({
                    date :  item.date,
                    spent : MinutesToTime(item.spent_time),
                    billable : MinutesToTime(item.spent_time - item.non_billable_time),
                    note :  <ShowMoreText
                                lines={1}
                                more="Show more"
                                less="Show less"
                                className="content-css"
                                anchorClass="show-more-less-clickable"
                                expanded={false}
                                width={500}
                                truncatedEndingComponent={"... "}
                            >{item.note}
                            </ShowMoreText>,
                });
            });
        }
    }

    return (
        <div className='container py-5'>
            <div className='card'>
                <div className='card-header'>
                    <h4>View Task
                        <Link to="/tasks" className='btn btn-primary btn-sm float-end'>BACK</Link>
                    </h4>
                </div>
                <div className='card-body'>
                    <form encType='multipart/form-data' onSubmit={updateTaskEstimate} id="TASK_FORM" className="row g-3">
                        <div className="col-md-4">
                            <label htmlFor="project_id" className="form-label">Project</label>
                            <select name='project_id' value={taskInput.project_id} className='form-select' id="project_id" disabled>
                                <option value="">Select Project</option>
                                {
                                    projectList.map(item => {
                                        return (
                                            <option value={item.id} key={item.id}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-md-8">
                            <label htmlFor="task" className="form-label">Task</label>
                            <input type="text" name="task" value={taskInput.task} className='form-control' id="task" disabled />
                        </div>
                        <div className="col-12">
                            <label htmlFor="description" className="form-label">Description</label>
                            <div className='form-control html-desc'>{taskInput.description && parse(taskInput.description)}</div>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="task_type" className="form-label">Task Type</label>
                            <select name='task_type' value={taskInput.task_type} className='form-select' id='task_type' disabled>
                                <option value="">Select Task Type</option>
                                {TaskTypes.map((taskType, idx) => <option key={idx} value={taskType.value}>{taskType.label}</option>)}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="task_status" className="form-label">Task Status</label>
                            <select name='task_status' value={taskInput.task_status} className='form-select' id='task_status' disabled>
                                <option value="">Select Task Status</option>
                                {TaskStatuses.map((taskStatus, idx) => <option key={idx} value={taskStatus.value}>{taskStatus.label}</option>)}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="task_priority" className="form-label">Task Priority</label>
                            <select name='task_priority' value={taskInput.task_priority} className='form-select' id='task_priority' disabled>
                                <option value="">Select Task Priority</option>
                                {TaskPriority.map((taskPriority, idx) => <option key={idx} value={taskPriority.value}>{taskPriority.label}</option>)}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="due_date" className="form-label">Due Date</label>
                            <input type="date" name="due_date" value={taskInput.due_date} className='form-control' id='due_date' disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="completed_date" className="form-label">Completed Date</label>
                            <input type="date" name="completed_date" value={taskInput.completed_date} className='form-control' id='completed_date' disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="assigned_by" className="form-label">Assigned By</label>
                            <input type="text" name="assigned_by" value={taskInput.assigned_by ? taskInput.assigned_by.name : 'Self Assigned'} className='form-control' id="task" disabled />
                        </div>
                        {
                            taskInput.locked === 1 ?
                            <div className="col-md-4">
                                <label htmlFor="estimate_time" className="form-label">Estimate Time (HH:MM)</label>
                                <div className="input-group">
                                    <input type="number" name="estimate_hours" onChange={handleEstimate} value={estimate.estimate_hours} min="0" max="999" step="1" id='estimate_time_hours' className="form-control text-end" placeholder="Hours" aria-label=""/>
                                    <span className="input-group-text">:</span>
                                    <input type="number" name="estimate_minutes" onChange={handleEstimate} value={estimate.estimate_minutes} min="0" max="59" step="1" id='estimate_time_minutes' className="form-control" placeholder="Minutes" aria-label=""/>
                                </div>
                                <small className='text-danger'>{errorList.estimate_time}</small>
                            </div> :
                            <div className="col-md-4">
                                <label htmlFor="estimate_time" className="form-label">Estimate Time (HH:MM)</label>
                                <input type="text" name="estimate_time" value={MinutesToTime(taskInput.estimate_time)} className='form-control' min="0" step="1" id='estimate_time' disabled />
                            </div>
                        }
                        
                        <div className="col-md-4">
                            <label htmlFor="spent_time" className="form-label">Total Spent Time (HH:MM)</label>
                            <input type="text" name="spent_time" value={MinutesToTime(taskInput.total_spent_time)} className='form-control' min="0" step="1" id='spent_time' disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="non_billable_time" className="form-label">Total Billable Time (HH:MM)</label>
                            <input type="text" name="non_billable_time" value={MinutesToTime(taskInput.total_spent_time - taskInput.total_non_billable_time)} className='form-control' min="0" step="1" id='non_billable_time' disabled />
                        </div>
                        {taskInput.image &&
                            <div className="col-md-4">
                                <label htmlFor="image" className="form-label">File</label>
                                <div><a href={`${process.env.REACT_APP_BACKEND_URL}/${taskInput.image}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-dark mt-2">View File <i className="fa fa-external-link" aria-hidden="true"></i></a></div>
                            </div>
                        }
                        {
                            taskInput.locked === 1 && 
                            <>
                                <div className="col-12">
                                    <div className="alert alert-danger" role="alert">
                                        Please Update Estimate Time Before Adding Time Logs
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-warning px-4 float-end'>
                                        <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                                        Update
                                    </button>
                                </div>
                            </>
                        }
                        <hr />
                        <div className="col-md-4">
                            <label htmlFor="qa_assignee_id" className="form-label">QA Assignee</label>
                            <input type="text" name="qa_assignee_id" value={taskInput.qa_assignee ? taskInput.qa_assignee.name : 'Not Assigned'} className='form-control' id="task" disabled />
                        </div>
                        <div className="col-12">
                            <label htmlFor="qa_comments" className="form-label">Task Comments</label>
                            <div className='form-control html-desc'>{taskInput.qa_comments && parse(taskInput.qa_comments)}</div>
                        </div>
                    </form>
                </div>
            </div>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h5>Time Logs
                        <div className="d-flex float-end">
                            { 
                                (taskInput.task_status !== 'completed' && taskInput.task_status !== 'internal-qa' && taskInput.locked !== 1) && 
                                <Link to={`/add-time-log/${taskId}`} className='btn btn-success btn-sm'>Add Time Log</Link>
                            }
                            <Link to="/time-logs" className='btn btn-primary btn-sm ms-1'>View My Time Logs</Link>
                        </div>
                    </h5>
                </div>
                <div className='card-body'>
                    <DataTable
                        columns={timeLogColumns}
                        data={timeLogData}
                        pagination
                    />
                </div>
            </div>
        </div>
    );
}

export default ViewTask;