import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import Select from 'react-select';

const EditProject = (props) => {
    const projectId = props.match.params.id;
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [pending, setPending] = useState(false);
    const [clientList, setClientList] = useState([]);
    const [allocated, setAllocated] = useState({
        allocated_hours: 0,
        allocated_minutes: 0,
    });
    const [projectInput, setProject] = useState({
        client_id: '',
        slug: '',
        name: '',
        description: '',
        staging_url: '',
        production_url: '',
        spec_url: '',
        design_url: '',
        allocated_time: '',
    });
    const [allCheckbox, setCheckboxes] = useState([]);
    const [picture, setPicture] = useState([]);
    const [errorList, setError] = useState([]);

    const setFormData = useCallback(async () => {
        axios.get(`/api/edit-project/${projectId}`).then(res => {
            if (res.data.status === 200) {
                setProject(res.data.project);
                setPicture({ logo: res.data.project.logo });
                setCheckboxes(res.data.project);
                let allocatedTime = res.data.project.allocated_hours_per_month;
                let allocatedObject = {
                    allocated_hours : Math.floor(allocatedTime/60),
                    allocated_minutes : allocatedTime - (Math.floor(allocatedTime/60))*60
                }
                setAllocated(allocatedObject);
            } else if (res.data.status === 404) {
                swal("Error", res.data.message, "error");
                history.push('/admin/view-projects');
            }
            setLoading(false);
        });
    }, [history, projectId])

    useEffect(() => {
        axios.get(`/api/all-clients`).then(res => {
            if (res.data.status === 200) {
                setClientList(res.data.clients);
            }
        });
        setFormData();
    }, [setFormData]);
    
    const handleInput = (e) => {
        e.persist();
        setProject({ ...projectInput, [e.target.name]: e.target.value });
    }

    const handleLogo = (e) => {
        e.persist();
        setPicture({ logo: e.target.files[0] });
    }

    const handleCheckbox = (e) => {
        e.persist();
        setCheckboxes({ ...allCheckbox, [e.target.name]: e.target.checked });
    }

    const handleAllocated = (e) => {
        e.persist();
        setAllocated({ ...allocated, [e.target.name]: e.target.value });
    }
    
    const handleClient = (client) => {
        setProject({ ...projectInput, client_id: client.value });
    }

    const updateProject = (e) => {
        e.preventDefault();
        setPending(true);

        axios.get('/sanctum/csrf-cookie').then(response => {
            let hoursAllocated = allocated.allocated_hours ? parseInt(allocated.allocated_hours) : 0;
            let minutesAllocated = allocated.allocated_minutes ? parseInt(allocated.allocated_minutes) : 0;
            let allocatedTime = hoursAllocated*60 + minutesAllocated;
            const formData = new FormData();
            formData.append('logo', picture.logo);
            formData.append('client_id', projectInput.client_id);
            formData.append('slug', projectInput.slug);
            formData.append('name', projectInput.name);
            formData.append('description', projectInput.description);
            formData.append('allocated_hours_per_month', allocatedTime);
            formData.append('staging_url', projectInput.staging_url);
            formData.append('production_url', projectInput.production_url);
            formData.append('spec_url', projectInput.spec_url);
            formData.append('design_url', projectInput.design_url);
            formData.append('status', allCheckbox.status ? '1' : '0');
            formData.append('support_enabled', allCheckbox.support_enabled ? '1' : '0');
            formData.append('_method', 'PUT');
            
            axios.post(`/api/update-project/${projectId}`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
                if (res.data.status === 200) {
                    swal("Success", res.data.message, "success");
                    setError([]);
                    setFormData();
                    setPending(false);
                } else if (res.data.status === 422) {
                    swal("All fields are mandatory", "", "error");
                    setError(res.data.errors);
                    setPending(false);
                } else if (res.data.status === 404) {
                    swal("Error", res.data.message, "error");
                    history.push('/admin/view-projects');
                    setPending(false);
                }
            });
        });
    }

    const clientOptions = clientList.map(item => {
        return ({
            value: item.id,
            label: item.name
        });
    });

    if (loading) {
        return <h4>Loading Edit Project...</h4>
    }

    return (
        <div className='px-4'>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h4>Edit Project
                        <Link to="/admin/view-projects" className='btn btn-primary btn-sm float-end'>BACK</Link>
                    </h4>
                </div>
                <div className='card-body'>
                    <form encType='multipart/form-data' onSubmit={updateProject} id="PROJECT_FORM" className="row g-3">
                        <div className="col-md-3">
                            <label htmlFor="client_id" className="form-label">Select Client</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                value={clientOptions.filter(function(option) {
                                    return option.value === projectInput.client_id;
                                })}
                                isSearchable
                                onChange={handleClient}
                                name="client_id"
                                options={clientOptions}
                            />
                            <small className='text-danger'>{errorList.client_id}</small>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="slug" className="form-label">Slug</label>
                            <input type="text" name="slug" onChange={handleInput} value={projectInput.slug} className='form-control' id='slug' />
                            <small className='text-danger'>{errorList.slug}</small>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="name" className="form-label">Name</label>
                            <input type="text" name="name" onChange={handleInput} value={projectInput.name} className='form-control' id='name' />
                            <small className='text-danger'>{errorList.name}</small>
                        </div>
                        <div className="col-12">
                            <label htmlFor="description" className="form-label">Description</label>
                            <textarea name="description" onChange={handleInput} value={projectInput.description} className='form-control' id='description'></textarea>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="staging_url" className="form-label">Staging URL</label>
                            <input type="text" name="staging_url" onChange={handleInput} value={projectInput.staging_url} className='form-control' id='staging_url' />
                            <small className='text-danger'>{errorList.staging_url}</small>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="production_url" className="form-label">Production URL</label>
                            <input type="text" name="production_url" onChange={handleInput} value={projectInput.production_url} className='form-control' id='production_url' />
                            <small className='text-danger'>{errorList.production_url}</small>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="spec_url" className="form-label">Spec URL</label>
                            <input type="text" name="spec_url" onChange={handleInput} value={projectInput.spec_url} className='form-control' id='spec_url' />
                            <small className='text-danger'>{errorList.spec_url}</small>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="design_url" className="form-label">Design URL</label>
                            <input type="text" name="design_url" onChange={handleInput} value={projectInput.design_url} className='form-control' id='design_url' />
                            <small className='text-danger'>{errorList.design_url}</small>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="allocated_time" className="form-label">Allocated Time per Month (HH:MM)</label>
                            <div className="input-group">
                                <input type="number" name="allocated_hours" onChange={handleAllocated} value={allocated.allocated_hours} min="0" max="9999" step="1" id='allocated_hours' className="form-control text-end" placeholder="Hours" aria-label=""/>
                                <span className="input-group-text">:</span>
                                <input type="number" name="allocated_minutes" onChange={handleAllocated} value={allocated.allocated_minutes} min="0" max="59" step="1" id='allocated_minutes' className="form-control" placeholder="Minutes" aria-label=""/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="logo" className="form-label">Logo</label>
                            <input type='file' name='logo' onChange={handleLogo} className='form-control' id='logo' />
                            <small className='text-danger'>{errorList.logo}</small>
                            {projectInput.logo && <img src={`${process.env.REACT_APP_BACKEND_URL}/${projectInput.logo}`} className='logo mt-2' alt={projectInput.id} />}
                        </div>
                        <div className="col-12">
                            <div className="form-check">
                                <input type="checkbox" name='support_enabled' onChange={handleCheckbox} defaultChecked={allCheckbox.support_enabled === 1 ? true : false} className="form-check-input" id="support_enabled" />
                                <label htmlFor="support_enabled" className="form-check-label">Disable Support (checked=Disable)</label>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-check">
                                <input type="checkbox" name='status' onChange={handleCheckbox} defaultChecked={allCheckbox.status === 1 ? true : false} className="form-check-input" id="status" />
                                <label htmlFor="status" className="form-check-label">Status (checked=Hidden)</label>
                            </div>
                        </div>
                        <div className="col-12">
                            <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-primary px-4 float-end'>
                                <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditProject;