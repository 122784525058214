import Dashboard from '../components/admin/Dashboard';
import Profile from '../components/admin/Profile';
import ViewUsers from '../components/admin/user/ViewUsers';
import EditUser from '../components/admin/user/EditUser';
import AddClient from '../components/admin/client/AddClient';
import ViewClients from '../components/admin/client/ViewClients';
import EditClient from '../components/admin/client/EditClient';
import AddProject from '../components/admin/project/AddProject';
import ViewProjects from '../components/admin/project/ViewProjects';
import EditProject from '../components/admin/project/EditProject';
import ViewProject from '../components/admin/project/ViewProject';
import AddTask from '../components/admin/task/AddTask';
import ViewTasks from '../components/admin/task/ViewTasks';
import EditTask from '../components/admin/task/EditTask';
import ViewTask from '../components/admin/task/ViewTask';
import ResourceAllocation from '../components/admin/reports/ResourceAllocation';
import ProjectTimeReport from '../components/admin/reports/ProjectTimeReport';
import WeeklyTimeLogReport from '../components/admin/reports/WeeklyTimeLogReport';
import ViewLeaves from '../components/admin/leave/ViewLeaves';
import ReviewLeave from '../components/admin/leave/ReviewLeave';
import ViewClientTasks from '../components/admin/client-task/ViewClientTasks';
import EditClientTask from '../components/admin/client-task/EditClientTask';
import ViewClientTask from '../components/admin/client-task/ViewClientTask';
import AddClientTask from '../components/admin/client-task/AddClientTask';
import AddTaskByClientTask from '../components/admin/client-task/AddTaskByClientTask';

const Routes = [
    { path: '/admin', exact: true, name: 'Admin' },
    { path: '/admin/dashboard', exact: true, name: 'Dashboard', component: Dashboard, allowedRoles: ['admin'] },
    { path: '/admin/profile', exact: true, name: 'Profile', component: Profile, allowedRoles: ['admin'] },
    { path: '/admin/view-users', exact: true, name: 'ViewUsers', component: ViewUsers, allowedRoles: ['admin'] },
    { path: '/admin/edit-user/:id', exact: true, name: 'EditUser', component: EditUser, allowedRoles: ['admin'] },
    { path: '/admin/add-client', exact: true, name: 'AddClient', component: AddClient, allowedRoles: ['admin'] },
    { path: '/admin/view-clients', exact: true, name: 'ViewClients', component: ViewClients, allowedRoles: ['admin'] },
    { path: '/admin/edit-client/:id', exact: true, name: 'EditClient', component: EditClient, allowedRoles: ['admin'] },
    { path: '/admin/add-project', exact: true, name: 'AddProject', component: AddProject, allowedRoles: ['admin'] },
    { path: '/admin/view-projects', exact: true, name: 'ViewProjects', component: ViewProjects, allowedRoles: ['admin'] },
    { path: '/admin/edit-project/:id', exact: true, name: 'EditProject', component: EditProject, allowedRoles: ['admin'] },
    { path: '/admin/view-project/:id', exact: true, name: 'ViewProject', component: ViewProject, allowedRoles: ['admin'] },
    { path: '/admin/add-task/:projectId?', exact: true, name: 'AddTask', component: AddTask, allowedRoles: ['admin'] },
    { path: '/admin/view-tasks', exact: true, name: 'ViewTasks', component: ViewTasks, allowedRoles: ['admin'] },
    { path: '/admin/edit-task/:id', exact: true, name: 'EditTask', component: EditTask, allowedRoles: ['admin'] },
    { path: '/admin/view-task/:id', exact: true, name: 'ViewTask', component: ViewTask, allowedRoles: ['admin'] },
    { path: '/admin/view-client-tasks', exact: true, name: 'ViewClientTasks', component: ViewClientTasks, allowedRoles: ['admin'] },
    { path: '/admin/edit-client-task/:id', exact: true, name: 'EditClientTask', component: EditClientTask, allowedRoles: ['admin'] },
    { path: '/admin/view-client-task/:id', exact: true, name: 'ViewClientTask', component: ViewClientTask, allowedRoles: ['admin'] },
    { path: '/admin/add-client-task/:projectId?', exact: true, name: 'AddClientTask', component: AddClientTask, allowedRoles: ['admin'] },
    { path: '/admin/add-task-by-client-task/:clientTaskId?', exact: true, name: 'AddTaskByClientTask', component: AddTaskByClientTask, allowedRoles: ['admin'] },
    { path: '/admin/resource-allocation-report', exact: true, name: 'ResourceAllocation', component: ResourceAllocation, allowedRoles: ['admin'] },
    { path: '/admin/project-time-report', exact: true, name: 'ProjectTimeReport', component: ProjectTimeReport, allowedRoles: ['admin'] },
    { path: '/admin/weekly-timelog-report', exact: true, name: 'WeeklyTimeLogReport', component: WeeklyTimeLogReport, allowedRoles: ['admin'] },
    { path: '/admin/view-leaves', exact: true, name: 'ViewLeaves', component: ViewLeaves, allowedRoles: ['admin'] },
    { path: '/admin/review-leave/:id', exact: true, name: 'ReviewLeave', component: ReviewLeave, allowedRoles: ['admin'] },
];

export default Routes;