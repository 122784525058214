import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import ShowMoreText from "react-show-more-text";
import DataTable from 'react-data-table-component';

import { TaskStatuses } from '../../../utils/TaskStatuses';
import { TaskPriority } from '../../../utils/TaskPriority';
import { Statuses } from '../../../utils/Statuses';
import MinutesToTime from '../../../utils/MinutesToTime';
import FilterComponent from '../../FilterComponent';

const clientTasksColumns = [
    {
        name: 'Client',
        selector: row => row.client,
        sortable: true,
    },
    {
        name: 'Project',
        selector: row => row.project,
        sortable: true,
    },
    {
        name: 'Task',
        selector: row => row.task,
        sortable: true,
    },
    // {
    //     name: 'Assigned By',
    //     selector: row => row.assigned_by,
    //     sortable: true,
    // },
    {
        name: 'Ideal Date',
        selector: row => row.ideal_date,
        sortable: true,
    },
    // {
    //     name: 'Completed Date',
    //     selector: row => row.completed_date,
    //     sortable: true,
    // },
    // {
    //     name: 'Time Spent',
    //     selector: row => row.time_spent,
    //     sortable: true,
    // },
    {
      name: 'Invoice',
      selector: row => row.invoice_status,
      sortable: true,
    },
    {
      name: 'Quotation',
      selector: row => row.quotation_status,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: row => row.quotation_amount,
      sortable: true,
    },
    {
        name: 'Task Status',
        selector: row => row.task_status,
        sortable: true,
    },
    {
        name: 'Task Priority',
        selector: row => row.task_priority,
        sortable: true,
    },
    {
        name: 'Actions',
        selector: row => row.actions,
    },
];

let clientTasksData = [];

const ViewClientTasks = () => {
  const [loading, setLoading] = useState(true);
  const [clientTaskList, setClientTaskList] = useState([]);
  const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    useEffect(() => {
        let isMounted = true;
        axios.get(`/api/view-client-tasks`).then(res => {
            if (isMounted) {
                if (res.data.status === 200) {
                    setClientTaskList(res.data.clientTasks);
                    console.log(res.data.clientTasks);
                    setLoading(false);
                }
            }
        });
        return () => {
            isMounted = false
        }
    }, []);

    const setTableData = useCallback(async () => {
        clientTasksData = [];
        axios.get(`/api/view-client-tasks`).then(res => {
            if (res.data.status === 200) {
                setClientTaskList(res.data.clientTasks);
                setLoading(false);
            }
        });
    }, []);

    const deleteClientTask = (e, id) => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Record",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(`/api/delete-client-task/${id}`).then(res => {
                    if (res.data.status === 200) {
                        swal(res.data.message, {
                            icon: "success",
                        });
                        setTableData();
                    } else if (res.data.status === 404) {
                        swal("Error", res.data.message, "error");
                    } else if (res.data.status === 405) {
                        swal("Error", res.data.message, "error");
                    }
                });
            }
        });
    }

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholderText="Filter By Client / Project / Task / AssignedBy" />
        );
    }, [filterText, resetPaginationToggle]);

    if (loading) {
        return <h4>Loading Tasks...</h4>
    } else {
        if (clientTaskList.length > 0) {
            clientTasksData = clientTaskList.filter(val => 
            (val.assigned_by_user && val.assigned_by_user.client && val.assigned_by_user.client.name.toLowerCase().includes(filterText.toLowerCase())) || 
            (val.project && val.project.name.toLowerCase().includes(filterText.toLowerCase())) ||
            (val.task && val.task.toLowerCase().includes(filterText.toLowerCase())) ||
            (val.assigned_by_user && val.assigned_by_user.name.toLowerCase().includes(filterText.toLowerCase()))
            ).map(item => {
                return ({
                        client :   item.assigned_by_user && item.assigned_by_user.client && item.assigned_by_user.client.name,
                        project :   item.project && item.project.name,
                        task :  <ShowMoreText
                                    lines={1}
                                    more="Show more"
                                    less="Show less"
                                    className="content-css"
                                    anchorClass="show-more-less-clickable"
                                    expanded={false}
                                    width={200}
                                    truncatedEndingComponent={"... "}
                                >{item.task}
                                </ShowMoreText>,
                        assigned_by :   item.assigned_by_user && item.assigned_by_user.name,
                        assignee :   item.assignee && item.assignee.name,
                        ideal_date :  item.ideal_date,
                        estimate :  MinutesToTime(item.estimate_time),
                        // completed_date  :   item.completed_date,
                        time_spent    :   MinutesToTime(item.time_spent),
                        invoice_status  : item.invoice_status && item.invoice_status,
                        quotation_status  : item.quotation_status && item.quotation_status,
                        quotation_amount : item.quotation_amount && `${item.quotation_currency && item.quotation_currency} ${item.quotation_amount}`,
                        task_status :    TaskStatuses.map((taskStatus, idx) => item.task_status === taskStatus.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskStatus.value}`}>{taskStatus.label}</span> : ''),
                        task_priority :    TaskPriority.map((taskPriority, idx) => item.task_priority === taskPriority.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskPriority.value}`}>{taskPriority.label}</span> : ''),
                        status :    Statuses.map((role, idx) => parseInt(item.status) === parseInt(role.value) ? <span key={idx} className={`badge rounded-pill bg-pill-${parseInt(role.value) ? 'inactive' : 'active'}`}>{role.label}</span> : ''),
                        actions :   <div className="d-flex">
                                        <Link to={`/admin/add-task-by-client-task/${item.id}`} className="btn btn-outline-primary btn-sm"><i className="fa fa-plus"></i></Link>
                                        <Link to={`/admin/view-client-task/${item.id}`} className="btn btn-outline-secondary btn-sm ms-1"><i className="fa fa-eye"></i></Link>
                                        <Link to={`/admin/edit-client-task/${item.id}`} className="btn btn-outline-success btn-sm ms-1"><i className="fa fa-pencil"></i></Link>
                                        <button type='button' onClick={(e) => deleteClientTask(e, item.id)} className='btn btn-outline-danger btn-sm ms-1'><i className="fa fa-trash"></i></button>
                                    </div>,
                });
            });
        }
    }

    return (
        <div className='px-4'>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h4>Client Task List
                        <Link to="/admin/add-client-task" className='btn btn-primary btn-sm float-end'>Add Client Task</Link>
                    </h4>
                </div>
                <div className='card-body'>
                    <DataTable
                        columns={clientTasksColumns}
                        data={clientTasksData}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle}
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                    />
                </div>
            </div>
        </div>
    );
}

export default ViewClientTasks;