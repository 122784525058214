import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

const Register = () => {
    const history = useHistory();
    const [pending, setPending] = useState(false);
    const [registerInput, setRegister] = useState({
        name: '',
        email: '',
        password: '',
        error_list: []
    });

    const handleInput = (e) => {
        e.persist();
        setRegister({...registerInput, [e.target.name]: e.target.value});
    }

    const registerSubmit = (e) => {
        e.preventDefault();
        setPending(true);

        const data = {
            name: registerInput.name,
            email: registerInput.email,
            password: registerInput.password,
        }

        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post(`/api/register`, data).then(res => {
                if (res.data.status === 200) {
                    // localStorage.setItem('auth_id', res.data.user_id);
                    // localStorage.setItem('auth_token', res.data.token);
                    // localStorage.setItem('auth_name', res.data.username);
                    // localStorage.setItem('auth_role', res.data.role);
                    // localStorage.setItem('auth_image', res.data.profile_picture ? res.data.profile_picture : '');
                    swal("Success", res.data.message, "success");
                    setPending(false);
                    history.push('/');
                } else {
                    setRegister({ ...registerInput, error_list: res.data.validation_errors});
                    setPending(false);
                }
            });
        });
    }

    return (
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-xl-9 mx-auto">
              <div className="card flex-row my-5 border-0 shadow rounded-3 overflow-hidden">
                <div className="card-img-left d-none d-md-flex">
                </div>
                <div className="card-body p-4 p-sm-5">
                  <h5 className="card-title text-center mb-5 fw-light fs-5">Register</h5>
                  <form  onSubmit={registerSubmit}>
                    <div className="form-floating mb-3">
                      <input id="floatingInputUsername" placeholder="Full Name" type='text' name='name' onChange={handleInput} value={registerInput.name} className='form-control' required autoFocus/>
                      <label htmlFor="floatingInputUsername">Full Name</label>
                      <label>{registerInput.error_list.name}</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input id="floatingInputEmail" placeholder="name@example.com" type='email' name='email' onChange={handleInput} value={registerInput.email} className='form-control' />
                      <label htmlFor="floatingInputEmail">Email address</label>
                      <label>{registerInput.error_list.email}</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input id="floatingPassword" placeholder="Password" type='password' name='password' onChange={handleInput} value={registerInput.password} className='form-control' />
                      <label htmlFor="floatingPassword">Password</label>
                      <label>{registerInput.error_list.password}</label>
                    </div>
                    <hr/>
                    <div className="d-grid mb-2">
                      <button disabled = {pending ? 'disabled' : ''} className="btn btn-lg btn-primary btn-login fw-bold text-uppercase" type="submit">
                        <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                        Register
                      </button>
                    </div>
                    <Link className="d-block text-center mt-2 small" to="/login">Already Have an Account? Log In</Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
}

export default Register;