import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import parse from 'html-react-parser';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { modules } from '../../../utils/EditorToolbar';
import { TaskStatuses } from '../../../utils/TaskStatuses';
import { TaskPriority } from '../../../utils/TaskPriority';
import MinutesToTime from '../../../utils/MinutesToTime';

const EditProjectTask = (props) => {
    const clientTaskId = props.match.params.id;
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [projectList, setProjectList] = useState([]);
    const [pending, setPending] = useState(false);
    const [clientTaskInput, setClientTask] = useState({
        project_id: '',
        assigned_by: '',
        task: '',
        description: '',
        comments: '',
        ideal_date: '',
        completed_date: '',
        time_spent: '',
        task_status: '',
        task_priority: '',
        quotation_amount: '',
        quotation_currency: '',
        quotation_status: '',
        invoice_status: ''
    });

    const setFormData = useCallback(async () => {
        axios.get(`/api/get-client-task-data/${clientTaskId}`).then(res => {
            if (res.data.status === 200) {
                setClientTask(res.data.clientTask);
            } else if (res.data.status === 404) {
                swal("Error", res.data.message, "error");
                history.push('/admin/view-tasks');
            }
            setLoading(false);
        });
    }, [history, clientTaskId])

    useEffect(() => {
        axios.get(`/api/get-projects`).then(res => {
            if (res.data.status === 200) {
                setProjectList(res.data.projects);
            }
        });
        setFormData();
    }, [setFormData]);

    const handleInput = (e) => {
        e.persist();
        setClientTask({ ...clientTaskInput, [e.target.name]: e.target.value });
    }

    const handleComments = (value) => {
        setClientTask({ ...clientTaskInput, comments: value });
    }

    const updateTask = (e) => {
        e.preventDefault();
        setPending(true);

        axios.get('/sanctum/csrf-cookie').then(response => {
            const formData = new FormData();
            formData.append('comments', clientTaskInput.comments);
            formData.append('task_status', clientTaskInput.task_status);
            formData.append('task_priority', clientTaskInput.task_priority);
            formData.append('_method', 'PUT');

            axios.post(`/api/update-client-task-data/${clientTaskId}`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
                if (res.data.status === 200) {
                    swal("Success", res.data.message, "success");
                    setFormData();
                    setPending(false);
                } else if (res.data.status === 422) {
                    swal("All fields are mandatory", "", "error");
                    setPending(false);
                } else if (res.data.status === 404) {
                    swal("Error", res.data.message, "error");
                    history.push(`/my-tasks/${clientTaskInput.project.slug}`);
                    setPending(false);
                }
            });
        });
    }

    if (loading) {
        return <h4>Loading Edit Task...</h4>
    }

    return (
        <div className='container py-5'>
            <div className='card'>
                <div className='card-header'>
                    <h4>Edit Task
                        <Link to={`/my-tasks/${clientTaskInput.project.slug}`} className='btn btn-primary btn-sm float-end'>BACK</Link>
                    </h4>
                </div>
                <div className='card-body'>
                    <form encType='multipart/form-data' onSubmit={updateTask} id="TASK_FORM" className="row g-3">
                        <div className="col-md-4">
                            <label htmlFor="project_id" className="form-label">Project</label>
                            <select name='project_id' value={clientTaskInput.project_id} className='form-select' id="project_id" disabled>
                                <option value="">Select Project</option>
                                {
                                    projectList.map(item => {
                                        return (
                                            <option value={item.id} key={item.id}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-md-8">
                            <label htmlFor="task" className="form-label">Task</label>
                            <input type="text" name="task" value={clientTaskInput.task} className='form-control' id="task" disabled />
                        </div>
                        <div className="col-12">
                            <label htmlFor="description" className="form-label">Description</label>
                            <div className='form-control html-desc'>{clientTaskInput.description && parse(clientTaskInput.description)}</div>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="ideal_date" className="form-label">Ideal Date</label>
                            <input type="date" name="ideal_date" value={clientTaskInput.ideal_date} className='form-control' id='ideal_date' disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="completed_date" className="form-label">Completed Date</label>
                            <input type="date" name="completed_date" value={clientTaskInput.completed_date} className='form-control' id='completed_date' disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="spent_time" className="form-label">Total Spent Time (HH:MM)</label>
                            <input type="text" name="spent_time" value={MinutesToTime(clientTaskInput.time_spent)} className='form-control' min="0" step="1" id='spent_time' disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="projectask_statust_id" className="form-label">Task Status</label>
                            <select name='task_status' onChange={handleInput} value={clientTaskInput.task_status} className='form-select' id='task_status'>
                                <option value="">Select Task Status</option>
                                {TaskStatuses.map((taskStatus, idx) => <option key={idx} value={taskStatus.value}>{taskStatus.label}</option>)}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="task_priority" className="form-label">Task Priority</label>
                            <select name='task_priority' onChange={handleInput} value={clientTaskInput.task_priority} className='form-select' id='task_priority'>
                                <option value="">Select Task Priority</option>
                                {TaskPriority.map((taskPriority, idx) => <option key={idx} value={taskPriority.value}>{taskPriority.label}</option>)}
                            </select>
                        </div>
                        {clientTaskInput.file &&
                            <div className="col-md-4">
                                <label htmlFor="file" className="form-label">File</label>
                                <div><a href={`${process.env.REACT_APP_BACKEND_URL}/${clientTaskInput.file}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-dark mt-2">View File <i className="fa fa-external-link" aria-hidden="true"></i></a></div>
                            </div>
                        }
                        <div className="col-12">
                            <label htmlFor="comments" className="form-label">Comments</label>
                            <ReactQuill
                                theme="snow"
                                value={clientTaskInput.comments}
                                onChange={handleComments}
                                modules={modules}
                            />
                        </div>
                        <div className="col-12">
                            <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-primary px-4 float-end'>
                                <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditProjectTask;