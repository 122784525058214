import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import Avatar from 'react-avatar';
import Modal from 'react-modal';
import Select from 'react-select';
import ShowMoreText from "react-show-more-text";
import DataTable from 'react-data-table-component';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { modules } from '../../../utils/EditorToolbar';
import { TaskPriority } from '../../../utils/TaskPriority';
import { TaskStatuses } from '../../../utils/TaskStatuses';
import MinutesToTime from '../../../utils/MinutesToTime';

const tasksColumns = [
    {
        name: 'Priority',
        selector: row => row.task_priority,
        sortable: true,
    },
    {
        name: 'Task Name',
        selector: row => row.task,
        sortable: true,
    },
    {
        name: 'Created By',
        selector: row => row.assigned_by,
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.task_status,
        sortable: true,
    },
    {
        name: 'Time Spent',
        selector: row => row.time_spent,
        sortable: true,
    },
    {
        name: 'Ideal Date',
        selector: row => row.ideal_date,
        sortable: true,
    },
    {
        name: 'Completed Date',
        selector: row => row.completed_date,
        sortable: true,
    },
    {
        name: 'Actions',
        selector: row => row.actions,
    },
];

let currentMonthTasks = [];
let previousMonthTasks = [];
let olderMonthsTasks = [];

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        margin: '0',
        transform: 'translate(-50%, -50%)',
        minWidth: '90%',
        maxWidth: '100%',
    },
};

const ProjectTasks = (props) => {
    const projectSlug = props.match.params.slug;
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState([]);
    const [thisMonthTasks, setThisMonthTasks] = useState([]);
    const [lastMonthTasks, setLastMonthTasks] = useState([]);
    const [olderTasks, setOlderTasks] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [errorList, setError] = useState([]);
    const [pending, setPending] = useState(false);
    const [clientTaskInput, setClientTask] = useState({
        project_id: '',
        task: '',
        description: '',
        ideal_date: '',
        task_priority: '',
    });
    const [taskFile, setTaskFile] = useState([]);
    const today = new Date();
    const currentMonthYear = today.getFullYear();
    const currentMonth = today.toLocaleString('default', {month: 'long'});
    today.setDate(0);
    const previousMonthYear = today.getFullYear();
    const previousMonth = today.toLocaleString('default', {month: 'long'});

    if (!localStorage.getItem('auth_token')) {
        history.push('/login');
        swal('Unauthorized', 'Authorization Required', 'error');
    } else if (!props.allowedRoles.includes(localStorage.getItem('auth_role'))) {
        history.push('/403');
    }

    useEffect(() => {
        let isMounted = true;
        axios.get(`/api/get-client-project-tasks/${projectSlug}`).then(res => {
            if (isMounted) {
                if (res.data.status === 200) {
                    setProjects(res.data.projects);
                    setProject(res.data.project_data.project);
                    setThisMonthTasks(res.data.project_data.thisMonthTasks);
                    setLastMonthTasks(res.data.project_data.lastMonthTasks);
                    setOlderTasks(res.data.project_data.olderTasks);
                    setLoading(false);
                } else if (res.data.status === 404) {
                    swal("Error", res.data.message, "error");
                    history.push('/');
                }
            }
        });
        return () => {
            isMounted = false
        }
    }, [history, projectSlug]);

    const setTableData = useCallback(async () => {
        currentMonthTasks = [];
        previousMonthTasks = [];
        olderMonthsTasks = [];
        axios.get(`/api/get-client-project-tasks/${projectSlug}`).then(res => {
            if (res.data.status === 200) {
                setProjects(res.data.projects);
                setProject(res.data.project_data.project);
                setThisMonthTasks(res.data.project_data.thisMonthTasks);
                setLastMonthTasks(res.data.project_data.lastMonthTasks);
                setOlderTasks(res.data.project_data.olderTasks);
                setLoading(false);
            } else if (res.data.status === 404) {
                swal("Error", res.data.message, "error");
                history.push('/');
            }
        });
    }, [history, projectSlug]);

    const resetForm = () => {
        setClientTask({
            project_id: '',
            task: '',
            description: '',
            ideal_date: '',
            task_priority: '',
        });

        setTaskFile([]);
    }

    function openModal(e, projectId) {
        resetForm();
        projectId && setClientTask({ ...clientTaskInput, project_id: projectId });
        setIsOpen(true);
    }

    function closeModal() {
        setError([]);
        setIsOpen(false);
    }

    const handleTaskFile = (e) => {
        e.persist();
        setTaskFile({ file: e.target.files[0] });
    }

    const handleInput = (e) => {
        e.persist();
        setClientTask({ ...clientTaskInput, [e.target.name]: e.target.value });
    }

    const handleDescription = (value) => {
        setClientTask({ ...clientTaskInput, description: value });
    }

    const handleProject = (project) => {
        setClientTask({ ...clientTaskInput, project_id: project.value });
    }

    const submitClientTask = (e) => {
        e.preventDefault();
        setPending(true);
        const formData = new FormData();
        formData.append('project_id', clientTaskInput.project_id);
        formData.append('task', clientTaskInput.task);
        formData.append('description', clientTaskInput.description);
        formData.append('ideal_date', clientTaskInput.ideal_date);
        formData.append('file', taskFile.file);
        formData.append('task_priority', clientTaskInput.task_priority);

        axios({
            method: "post",
            url: `/api/add-client-task-data`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(res => {
            if (res.data.status === 200) {
                swal("Success", res.data.message, "success");
                resetForm();
                setTableData();
                setError([]);
                closeModal();
                setPending(false);
            } else if (res.data.status === 422) {
                swal("All Fields are mandatory", "", "error");
                setError(res.data.errors);
                setPending(false);
            }
        });
    }

    const deleteTask = (e, id) => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Record",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(`/api/delete-client-task-data/${id}`).then(res => {
                    if (res.data.status === 200) {
                        swal(res.data.message, {
                            icon: "success",
                        });
                        setTableData();
                    } else if (res.data.status === 404) {
                        swal("Error", res.data.message, "error");
                    } else if (res.data.status === 405) {
                        swal("Error", res.data.message, "error");
                    }
                });
            }
        });
    }

    const projectOptions = projects.map(item => {
        return ({
            value: item.id,
            label: item.name
        })
    });

    if (loading) {
        return <h4>Loading Project Data...</h4>
    } else {
        currentMonthTasks = thisMonthTasks.map((item, idx) => {
            return ({
                task_priority : TaskPriority.map((taskPriority, idx) => item.task_priority === taskPriority.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskPriority.value}`}>{taskPriority.label}</span> : ''),
                task    :   <ShowMoreText
                                lines={1}
                                more="Show more"
                                less="Show less"
                                className="content-css"
                                anchorClass="show-more-less-clickable"
                                expanded={false}
                                width={200}
                                truncatedEndingComponent={"... "}
                            >{item.task}
                            </ShowMoreText>,
                assigned_by :   item.assigned_by_user && item.assigned_by_user.name,
                task_status :   TaskStatuses.map((taskStatus, idx) => item.task_status === taskStatus.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskStatus.value}`}>{taskStatus.label}</span> : ''),
                time_spent    :   MinutesToTime(item.time_spent),
                ideal_date    :   item.ideal_date,
                completed_date  :   item.completed_date,
                actions :   <div className="d-flex">
                                <Link to={`/view-project-task/${item.id}`} className="btn btn-outline-secondary btn-sm"><i className="fa fa-eye"></i></Link>
                                <Link to={`/edit-project-task/${item.id}`} className="btn btn-outline-success btn-sm ms-1"><i className="fa fa-pencil"></i></Link>
                                { parseInt(localStorage.getItem('auth_id')) === parseInt(item.assigned_by) &&
                                    <button type='button' onClick={(e) => deleteTask(e, item.id)} className='btn btn-outline-danger btn-sm ms-1'><i className="fa fa-trash"></i></button>
                                }
                            </div>,
            });
        });

        previousMonthTasks = lastMonthTasks.map((item, idx) => {
            return ({
                task_priority : TaskPriority.map((taskPriority, idx) => item.task_priority === taskPriority.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskPriority.value}`}>{taskPriority.label}</span> : ''),
                task    :   <ShowMoreText
                                lines={1}
                                more="Show more"
                                less="Show less"
                                className="content-css"
                                anchorClass="show-more-less-clickable"
                                expanded={false}
                                width={200}
                                truncatedEndingComponent={"... "}
                            >{item.task}
                            </ShowMoreText>,
                assigned_by :   item.assigned_by_user && item.assigned_by_user.name,
                task_status :   TaskStatuses.map((taskStatus, idx) => item.task_status === taskStatus.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskStatus.value}`}>{taskStatus.label}</span> : ''),
                time_spent    :   MinutesToTime(item.time_spent),
                ideal_date    :   item.ideal_date,
                completed_date  :   item.completed_date,
                actions :   <div className="d-flex">
                                <Link to={`/view-project-task/${item.id}`} className="btn btn-outline-secondary btn-sm"><i className="fa fa-eye"></i></Link>
                                <Link to={`/edit-project-task/${item.id}`} className="btn btn-outline-success btn-sm ms-1"><i className="fa fa-pencil"></i></Link>
                            </div>,
            });
        });

        olderMonthsTasks = olderTasks.map((item, idx) => {
            return ({
                task_priority : TaskPriority.map((taskPriority, idx) => item.task_priority === taskPriority.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskPriority.value}`}>{taskPriority.label}</span> : ''),
                task    :   <ShowMoreText
                                lines={1}
                                more="Show more"
                                less="Show less"
                                className="content-css"
                                anchorClass="show-more-less-clickable"
                                expanded={false}
                                width={200}
                                truncatedEndingComponent={"... "}
                            >{item.task}
                            </ShowMoreText>,
                assigned_by :   item.assigned_by_user && item.assigned_by_user.name,
                task_status :   TaskStatuses.map((taskStatus, idx) => item.task_status === taskStatus.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskStatus.value}`}>{taskStatus.label}</span> : ''),
                time_spent    :   MinutesToTime(item.time_spent),
                ideal_date    :   item.ideal_date,
                completed_date  :   item.completed_date,
                actions :   <div className="d-flex">
                                <Link to={`/view-project-task/${item.id}`} className="btn btn-outline-secondary btn-sm"><i className="fa fa-eye"></i></Link>
                                <Link to={`/edit-project-task/${item.id}`} className="btn btn-outline-success btn-sm ms-1"><i className="fa fa-pencil"></i></Link>
                            </div>,
            });
        });
    }

    return (
        <section className="py-5">
            <div className="container">
                <div className="row">
                    <div className="col-6 mx-auto">
                        <Avatar name={project.name} src={project.logo ? `${process.env.REACT_APP_BACKEND_URL}/${project.logo}` : ''} size="50" round={true} /><h2 className="d-inline-block align-bottom ms-2">{project.name}</h2>
                    </div>
                    <div className="col-6 mx-auto">
                        <button className="btn btn-sm btn-primary mt-2 float-end" onClick={(e) => openModal(e, project.id)}><i className="fa fa-plus" aria-hidden="true"></i> Create a Task</button>
                    </div>
                </div>
                <div className="row py-4">
                    <div className='card mt-4'>
                        <div className='card-header'>
                            <h4>{currentMonth} {currentMonthYear}</h4>
                        </div>
                        <div className='card-body'>
                            <DataTable
                                columns={tasksColumns}
                                data={currentMonthTasks}
                                pagination
                            />
                        </div>
                    </div>
                </div>
                { previousMonthTasks.length > 0 &&
                    <div className="row py-4">
                        <div className='card mt-4'>
                            <div className='card-header'>
                                <h4>{previousMonth} {previousMonthYear}</h4>
                            </div>
                            <div className='card-body'>
                                <DataTable
                                    columns={tasksColumns}
                                    data={previousMonthTasks}
                                    pagination
                                />
                            </div>
                        </div>
                    </div>
                }
                <div className="row py-4">
                    <div className='card mt-4'>
                        <div className='card-header'>
                            <h4>Older Tasks</h4>
                        </div>
                        <div className='card-body'>
                            <DataTable
                                columns={tasksColumns}
                                data={olderMonthsTasks}
                                pagination
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} ariaHideApp={false}>
                <div>
                    <form encType='multipart/form-data' onSubmit={submitClientTask} id="CLIENT_TASK_FORM" className="row g-3">
                        <div className="modal-header">
                            <h5 className="modal-title pe-4">Add a Task</h5>
                            <div className="col-md-3">
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    value={projectOptions.filter(function(option) {
                                        return option.value === clientTaskInput.project_id;
                                    })}
                                    isSearchable
                                    onChange={handleProject}
                                    name="project_id"
                                    options={projectOptions}
                                />
                                <small className='text-danger'>{errorList.project_id}</small>
                            </div>
                            <button type="button" className="btn-close" onClick={closeModal}></button>
                        </div>
                        <div className="modal-body row g-3">
                            <div className="col-12">
                                <label htmlFor="task" className="form-label">Task Title</label>
                                <input type="text" name="task" onChange={handleInput} value={clientTaskInput.task} className='form-control' id="task" />
                                <small className='text-danger'>{errorList.task}</small>
                            </div>
                            <div className="col-12">
                                <label htmlFor="description" className="form-label">Description</label>
                                <ReactQuill
                                    theme="snow"
                                    value={clientTaskInput.description}
                                    onChange={handleDescription}
                                    modules={modules}
                                />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="task_priority" className="form-label">Task Priority</label>
                                <select name='task_priority' onChange={handleInput} value={clientTaskInput.task_priority} className='form-select' id='task_priority'>
                                    <option value="">Select Task Priority</option>
                                    {TaskPriority.map((taskPriority, idx) => <option key={idx} value={taskPriority.value}>{taskPriority.label}</option>)}
                                </select>
                                <small className='text-danger'>{errorList.task_priority}</small>
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="ideal_date" className="form-label">Ideal Date ?</label>
                                <input type="date" name="ideal_date" onChange={handleInput} value={clientTaskInput.ideal_date} className='form-control' id='ideal_date' />
                                <small className='text-danger'>{errorList.ideal_date}</small>
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="taskFile" className="form-label">Upload a File</label>
                                <input type='file' name='taskFile' onChange={handleTaskFile} className='form-control' id='taskFile' />
                                <small className='text-danger'>{errorList.taskFile}</small>
                            </div>
                            <div className="col-12">
                                <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-primary px-4 mt-2 float-start'>
                                <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                                Add Task
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </section>
    );
}

export default ProjectTasks;