import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import Select from 'react-select';
import Modal from 'react-modal';
import ShowMoreText from "react-show-more-text";
import DataTable from 'react-data-table-component';
import ReactQuill from 'react-quill';

import { modules } from '../../../utils/EditorToolbar';
import { TaskStatuses } from '../../../utils/TaskStatuses';
import { TaskPriority } from '../../../utils/TaskPriority';
import MinutesToTime from '../../../utils/MinutesToTime';
import ViewWeeklyTimeLogs from './ViewWeeklyTimeLogs';

const timeLogColumns = [
    {
        name: 'Project',
        selector: row => row.project,
        sortable: true,
    },
    {
        name: 'Task',
        selector: row => row.task,
        sortable: true,
    },
    {
        name: 'Note',
        selector: row => row.note,
    },
    {
        name: 'Date',
        selector: row => row.date,
        sortable: true,
    },
    {
        name: 'Spent',
        selector: row => row.spent,
        sortable: true,
    },
    {
        name: 'Non Billable',
        selector: row => row.non_billable,
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
    },
    {
        name: 'Priority',
        selector: row => row.priority,
        sortable: true,
    },
    {
        name: 'Actions',
        selector: row => row.actions,
        width: '200px'
    },
];

let timeLogData = [];

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        margin: '0',
        transform: 'translate(-50%, -50%)',
        minWidth: '90%',
        maxWidth: '100%',
    },
};

const ViewTimeLogs = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [timeLogList, setTimeLogList] = useState([]);
    const [qaAssigneeList, setQaAssigneeList] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [errorList, setError] = useState([]);
    const [pending, setPending] = useState(false);
    const [spent, setSpent] = useState({
        spent_hours: 0,
        spent_minutes: 0,
    });
    const [nonBillable, setNonBillable] = useState({
        non_billable_hours: 0,
        non_billable_minutes: 0,
    });
    const [timeLogInput, setTimeLog] = useState({
        id: '',
        task_id: '',
        task: '',
        note: '',
        date: '',
        spent_time: '',
        non_billable_time: '',
        task_status: '',
        task_priority: '',
        qa_assignee_id: '',
        qa_comments: ''
    });

    if (!localStorage.getItem('auth_token')) {
        history.push('/login');
        swal('Unauthorized', 'Authorization Required', 'error');
    } else if (!props.allowedRoles.includes(localStorage.getItem('auth_role'))) {
        history.push('/403');
    }

    useEffect(() => {
        let isMounted = true;
        axios.get(`/api/get-time-logs`).then(res => {
            if (isMounted) {
                if (res.data.status === 200) {
                    setTimeLogList(res.data.timeLogs);
                    setLoading(false);
                }
            }
        });
        axios.get(`/api/get-qa-assignees`).then(res => {
            if (res.data.status === 200) {
                setQaAssigneeList(res.data.users);
                setLoading(false);
            }
        });
        return () => {
            isMounted = false
        }
    }, []);

    const setTableData = useCallback(async () => {
        axios.get(`/api/get-time-logs`).then(res => {
            if (res.data.status === 200) {
                setTimeLogList(res.data.timeLogs);
                setLoading(false);
            }
        });
    }, []);

    const setFormData = useCallback(async (timeLogId) => {
        setTimeLog([]);
        axios.get(`/api/edit-time-log-data/${timeLogId}`).then(res => {
            if (res.data.status === 200) {
                setTimeLog({ 
                    ...res.data.timeLog, 
                    task_status: res.data.timeLog.task.task_status, 
                    qa_assignee_id: res.data.timeLog.task.qa_assignee_id ? res.data.timeLog.task.qa_assignee_id : '',
                    qa_comments: res.data.timeLog.task.qa_comments ? res.data.timeLog.task.qa_comments : '',
                });
                let spentTime = res.data.timeLog.spent_time;
                let nonBillableTime = res.data.timeLog.non_billable_time;
                let spentObject = {
                    spent_hours : Math.floor(spentTime/60),
                    spent_minutes : spentTime - (Math.floor(spentTime/60))*60
                }
                let nonBillableObject = {
                    non_billable_hours : Math.floor(nonBillableTime/60),
                    non_billable_minutes : nonBillableTime - (Math.floor(nonBillableTime/60))*60
                }
                setSpent(spentObject);
                setNonBillable(nonBillableObject);
            } else if (res.data.status === 404) {
                swal("Error", res.data.message, "error");
                history.push('/time-logs');
            }
            setLoading(false);
        });
    }, [history]);

    function openModal(e, timeLogId) {
        setFormData(timeLogId);
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const handleQaAssignee = (assignee) => {
        setTimeLog({ ...timeLogInput, qa_assignee_id: assignee.value });
    }

    const handleInput = (e) => {
        e.persist();
        setTimeLog({ ...timeLogInput, [e.target.name]: e.target.value });
    }

    const handleQaComments = (value) => {
        setTimeLog({ ...timeLogInput, qa_comments: value });
    }

    const handleSpent = (e) => {
        e.persist();
        setSpent({ ...spent, [e.target.name]: e.target.value });
    }

    const handleNonBillable = (e) => {
        e.persist();
        setNonBillable({ ...nonBillable, [e.target.name]: e.target.value });
    }

    const deleteTimeLog = (e, id) => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Record",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(`/api/delete-time-log-data/${id}`).then(res => {
                    if (res.data.status === 200) {
                        swal(res.data.message, {
                            icon: "success",
                        });
                        setTableData();
                    } else if (res.data.status === 404) {
                        swal("Error", res.data.message, "error");
                    } else if (res.data.status === 405) {
                        swal("Error", res.data.message, "error");
                    }
                });
            }
        });
    }

    const updateTimeLog = (e) => {
        e.preventDefault();
        setPending(true);

        axios.get('/sanctum/csrf-cookie').then(response => {
            const formData = new FormData();
            let hours = spent.spent_hours ? parseInt(spent.spent_hours) : 0;
            let minutes = spent.spent_minutes ? parseInt(spent.spent_minutes) : 0;
            let spentTime = hours*60 + minutes;

            let nonBillableHours = nonBillable.non_billable_hours ? parseInt(nonBillable.non_billable_hours) : 0;
            let nonBillableMinutes = nonBillable.non_billable_minutes ? parseInt(nonBillable.non_billable_minutes) : 0;
            let nonBillableTime = nonBillableHours*60 + nonBillableMinutes;

            formData.append('task_id', timeLogInput.task_id);
            formData.append('note', timeLogInput.note);
            formData.append('date', timeLogInput.date);
            formData.append('spent_time', spentTime);
            formData.append('non_billable_time', nonBillableTime);
            formData.append('task_status', timeLogInput.task_status);
            formData.append('task_priority', timeLogInput.task_priority);
            formData.append('qa_assignee_id', timeLogInput.qa_assignee_id);
            formData.append('qa_comments', timeLogInput.qa_comments);
            formData.append('_method', 'PUT');

            let isValid = true;
            
            if (timeLogInput.task_status === 'internal-qa') {
                if (timeLogInput.qa_assignee_id === '') {
                    swal("Please Select QA Assignee", "", "error");
                    isValid = false;
                    setPending(false);
                }
            }

            if (isValid) {
                axios.post(`/api/update-time-log-data/${timeLogInput.id}`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
                    if (res.data.status === 200) {
                        setTableData();
                        swal("Success", res.data.message, "success");
                        setTimeLog([]);
                        setError([]);
                        closeModal();
                        setPending(false);
                    } else if (res.data.status === 422) {
                        swal("All fields are mandatory", "", "error");
                        setError(res.data.errors);
                        setPending(false);
                    } else if (res.data.status === 404) {
                        swal("Error", res.data.message, "error");
                        history.push('/time-logs');
                        setPending(false);
                    }
                });
            }
        });
    }

    const qaAssigneeOptions = qaAssigneeList.map(item => {
        return ({
            value: item.id,
            label: item.name
        });
    });

    if (loading) {
        return <h4>Loading Time Logs...</h4>
    } else {
        if (timeLogList.length > 0) {
            timeLogData = timeLogList.map(item => {
                return ({
                    project :   item.task && item.task.project.name,
                    task :  item.task &&
                            <ShowMoreText
                                lines={1}
                                more="Show more"
                                less="Show less"
                                className="content-css"
                                anchorClass="show-more-less-clickable"
                                expanded={false}
                                width={200}
                                truncatedEndingComponent={"... "}
                            >{item.task.task}
                            </ShowMoreText>,
                    note :  <ShowMoreText
                                lines={1}
                                more="Show more"
                                less="Show less"
                                className="content-css"
                                anchorClass="show-more-less-clickable"
                                expanded={false}
                                width={200}
                                truncatedEndingComponent={"... "}
                            >{item.note}
                            </ShowMoreText>,
                    date :  item.date,
                    spent : MinutesToTime(item.spent_time),
                    non_billable : MinutesToTime(item.non_billable_time),
                    status :    TaskStatuses.map((taskStatus, idx) => item.task.task_status === taskStatus.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskStatus.value}`}>{taskStatus.label}</span> : ''),
                    priority :    TaskPriority.map((taskPriority, idx) => item.task.task_priority === taskPriority.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskPriority.value}`}>{taskPriority.label}</span> : ''),
                    actions :   <div className="d-flex">
                                    { item.task &&
                                        <Link to={`view-task/${item.task.id}`} className="btn btn-outline-secondary btn-sm"><i className="fa fa-eye"></i> Task</Link>
                                    }
                                    { item.task.task_status !== 'completed' && item.task.task_status !== 'internal-qa' &&
                                        <>
                                            <button className="btn btn-outline-success btn-sm ms-1" onClick={(e) => openModal(e, item.id)}><i className="fa fa-pencil"></i></button>
                                            <button type='button' onClick={(e) => deleteTimeLog(e, item.id)} className='btn btn-outline-danger btn-sm ms-1'><i className="fa fa-trash"></i></button>
                                        </>
                                    }
                                </div>,
                });
            });
        }
    }

    return (
        <div className='container py-5'>
            <div className='card'>
                <div className='card-header'>
                    <h4>Time Log List
                        <Link to="/add-time-log" className='btn btn-primary btn-sm float-end'>Add Time Log</Link>
                    </h4>
                </div>
                <div className='card-body'>
                    <DataTable
                        columns={timeLogColumns}
                        data={timeLogData}
                        pagination
                    />
                </div>
            </div>
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} ariaHideApp={false}>
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title">Update Time Log</h5>
                        <button type="button" className="btn-close" onClick={closeModal}></button>
                    </div>
                    <div className="modal-body" style={{ overflow: 'auto', height: 'calc(100vh - 300px)' }}>
                        <form encType='multipart/form-data' onSubmit={updateTimeLog} id="TASK_FORM" className="row g-3">
                            <input type='hidden' name='id' value={timeLogInput.id} />
                            <input type='hidden' name='task_id' value={timeLogInput.task_id} />
                            <div className="col-md-4">
                                <label htmlFor="date" className="form-label">Date</label>
                                <input type="date" name="date" onChange={handleInput} value={timeLogInput.date} className='form-control' id='date' />
                                <small className='text-danger'>{errorList.date}</small>
                            </div>

                            <div className="col-md-4">
                                <label htmlFor="spent_hours" className="form-label">Spent Time (HH:MM)</label>
                                <div className="input-group">
                                    <input type="number" name="spent_hours" onChange={handleSpent} value={spent.spent_hours} min="0" max="999" step="1" id='spent_hours' className="form-control text-end" placeholder="Hours" aria-label=""/>
                                    <span className="input-group-text">:</span>
                                    <input type="number" name="spent_minutes" onChange={handleSpent} value={spent.spent_minutes} min="0" max="59" step="1" id='spent_minutes' className="form-control" placeholder="Minutes" aria-label=""/>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <label htmlFor="non_billable_hours" className="form-label">Non Billable Time (HH:MM)</label>
                                <div className="input-group">
                                    <input type="number" name="non_billable_hours" onChange={handleNonBillable} value={nonBillable.non_billable_hours} min="0" max="999" step="1" id='non_billable_hours' className="form-control text-end" placeholder="Hours" aria-label=""/>
                                    <span className="input-group-text">:</span>
                                    <input type="number" name="non_billable_minutes" onChange={handleNonBillable} value={nonBillable.non_billable_minutes} min="0" max="59" step="1" id='non_billable_minutes' className="form-control" placeholder="Minutes" aria-label=""/>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <label htmlFor="task_status" className="form-label">Task Status</label>
                                <select name='task_status' onChange={handleInput} value={timeLogInput.task_status} className='form-select' id='task_status'>
                                    <option value="">Select Task Status</option>
                                    {TaskStatuses.map((taskStatus, idx) => <option key={idx} value={taskStatus.value}>{taskStatus.label}</option>)}
                                </select>
                                <small className='text-danger'>{errorList.task_status}</small>
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="qa_assignee_id" className="form-label">QA Assignee</label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    value={qaAssigneeOptions.filter(function(option) {
                                        return option.value === timeLogInput.qa_assignee_id;
                                    })}
                                    isSearchable
                                    onChange={handleQaAssignee}
                                    name="qa_assignee_id"
                                    options={qaAssigneeOptions}
                                />
                                <small className='text-danger'>{errorList.qa_assignee_id}</small>
                            </div>
                            <div className="col-12">
                                <label htmlFor="qa_comments" className="form-label">Task Comments</label>
                                <ReactQuill
                                    theme="snow"
                                    value={timeLogInput.qa_comments}
                                    onChange={handleQaComments}
                                    modules={modules}
                                />
                            </div>
                            <div className="col-12">
                                <label htmlFor="note" className="form-label">Time Log Note</label>
                                <textarea name="note" onChange={handleInput} value={timeLogInput.note} className='form-control' id="note"></textarea>
                            </div>
                            <div className="col-12">
                                <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-primary px-4 float-end'>
                                    <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                                    Update
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>

            <ViewWeeklyTimeLogs/>
        </div>
    );
}

export default ViewTimeLogs;