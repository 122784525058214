import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

const ViewProjects = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [project, setProject] = useState([]);

    if (!localStorage.getItem('auth_token')) {
        history.push('/login');
        swal('Unauthorized', 'Authorization Required', 'error');
    } else if (!props.allowedRoles.includes(localStorage.getItem('auth_role'))) {
        history.push('/403');
    }

    useEffect(() => {
        let isMounted = true;
        axios.get(`/api/get-projects`).then(res => {
            if (isMounted) {
                if (res.data.status === 200) {
                    setProject(res.data.projects);
                    setLoading(false);
                }
            }
        });
        return () => {
            isMounted = false
        }
    }, []);    

    let showProjectList = '';
    if (loading) {
        return <h4>Loading Projects...</h4>
    } else {
        showProjectList = project.map((item, idx) => {
            return (
                <div className="col-md-6 col-xl-4" key={idx}>
                    <div className="card shadow-sm h-100">
                        {item.logo && <img src={`${process.env.REACT_APP_BACKEND_URL}/${item.logo}`} className="bd-placeholder-img card-img-top box-image" alt={item.id} />}
                        <div className="card-body">
                            <h5 className="card-title">{item.name}</h5>
                            <p className="card-text">{item.description}</p>
                            <div className="d-flex justify-content-between">
                                <Link to={`client/project/${item.slug}`} className="btn btn-sm btn-outline-primary">View Details</Link>
                                <Link to={`/add-task/${item.id}`} className="btn btn-sm btn-outline-success">Add Task</Link>
                                {item.production_url && <a href={item.production_url} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-dark">Production <i className="fa fa-external-link" aria-hidden="true"></i></a>}
                                {item.staging_url && <a href={item.staging_url} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-secondary">Staging <i className="fa fa-external-link" aria-hidden="true"></i></a>}
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    return (
        <>
            <section className="py-5 text-center container">
                <div className="row py-lg-5">
                    <div className="col-lg-6 col-md-8 mx-auto">
                        <h2 className="fw-light">Projects</h2>
                        <p className="lead text-muted">A project is defined as an effort to create or modify a specific product or service. Projects are temporary work efforts with a clear beginning and end. Projects can be completely contained within a specific unit or department, or include other organizations and vendors.</p>
                    </div>
                </div>
            </section>
            <div className="pb-5 bg-light">
                <div className="container">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                        {showProjectList}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ViewProjects;