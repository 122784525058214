import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import Select from 'react-select';

import { Designations } from '../../../utils/Designations';
import { Roles } from '../../../utils/Roles';

const EditUser = (props) => {
    const userId = props.match.params.id;
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [pending, setPending] = useState(false);
    const [clientList, setClientList] = useState([]);
    const [userInput, setUser] = useState({
        name: '',
        designation: '',
        phone: '',
        address: '',
        birth_date: '',
        role_as: '',
        client_id: ''
    });
    const [allCheckbox, setCheckboxes] = useState([]);
    const [picture, setPicture] = useState([]);
    const [errorList, setError] = useState([]);

    const setFormData = useCallback(async () => {
        axios.get(`/api/edit-user/${userId}`).then(res => {
            if (res.data.status === 200) {
                setUser(res.data.user);
                setPicture({ profile_picture: res.data.user.profile_picture });
                setCheckboxes(res.data.user);
            } else if (res.data.status === 404) {
                swal("Error", res.data.message, "error");
                history.push('/admin/view-users');
            }
            setLoading(false);
        });
    }, [history, userId])

    useEffect(() => {
        axios.get(`/api/all-clients`).then(res => {
            if (res.data.status === 200) {
                setClientList(res.data.clients);
            }
        });
        setFormData();
    }, [setFormData]);
    
    const handleInput = (e) => {
        e.persist();
        setUser({ ...userInput, [e.target.name]: e.target.value });
    }

    const handleImage = (e) => {
        e.persist();
        setPicture({ profile_picture: e.target.files[0] });
    }

    const handleCheckbox = (e) => {
        e.persist();
        setCheckboxes({ ...allCheckbox, [e.target.name]: e.target.checked });
    }

    const handleClient = (client) => {
        setUser({ ...userInput, client_id: client.value });
    }

    const updateUser = (e) => {
        e.preventDefault();
        setPending(true);

        axios.get('/sanctum/csrf-cookie').then(response => {
            const formData = new FormData();
            formData.append('name', userInput.name);
            formData.append('designation', userInput.designation);
            formData.append('phone', userInput.phone);
            formData.append('address', userInput.address);
            formData.append('birth_date', userInput.birth_date);
            formData.append('role_as', userInput.role_as);
            formData.append('profile_picture', picture.profile_picture);
            formData.append('client_id', userInput.client_id);
            formData.append('status', allCheckbox.status ? '1' : '0');
            formData.append('_method', 'PUT');
            axios.post(`/api/update-user/${userId}`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
                if (res.data.status === 200) {
                    swal("Success", res.data.message, "success");
                    setError([]);
                    setFormData();
                    setPending(false);
                } else if (res.data.status === 422) {
                    swal("All fields are mandatory", "", "error");
                    setError(res.data.errors);
                    setPending(false);
                } else if (res.data.status === 404) {
                    swal("Error", res.data.message, "error");
                    history.push('/admin/view-users');
                    setPending(false);
                }
            });
        });
    }

    const clientOptions = clientList.map(item => {
        return ({
            value: item.id,
            label: item.name
        });
    });

    if (loading) {
        return <h4>Loading Edit User...</h4>
    }

    return (
        <div className='px-4'>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h4>Edit User
                        <Link to="/admin/view-users" className='btn btn-primary btn-sm float-end'>BACK</Link>
                    </h4>
                </div>
                <div className='card-body'>
                    <form encType='multipart/form-data' onSubmit={updateUser} id="USER_FORM" className="row g-3">
                        <div className="col-md-6">
                            <label htmlFor="name" className="form-label">Full Name</label>
                            <input type="text" name="name" onChange={handleInput} value={userInput.name} className='form-control' id='name' />
                            <small className='text-danger'>{errorList.name}</small>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input type="text" name="email" onChange={handleInput} value={userInput.email} className='form-control' id='email' disabled />
                            <small className='text-danger'>{errorList.email}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="designation" className="form-label">Designation</label>
                            <select name='designation' onChange={handleInput} value={userInput.designation} className='form-select' id='designation'>
                                <option value="">Select Designation</option>
                                {Designations.map((designation, idx) => <option key={idx} value={designation.value}>{designation.label}</option>)}
                            </select>
                            <small className='text-danger'>{errorList.designation}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="phone" className="form-label">Mobile Number</label>
                            <input type="text" name="phone" onChange={handleInput} value={userInput.phone} className='form-control' id='phone' />
                            <small className='text-danger'>{errorList.phone}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="birth_date" className="form-label">Birth Date</label>
                            <input type="date" name="birth_date" onChange={handleInput} value={userInput.birth_date} className='form-control' id='birth_date' />
                            <small className='text-danger'>{errorList.birth_date}</small>
                        </div>
                        <div className="col-12">
                            <label htmlFor="address" className="form-label">Address</label>
                            <textarea name="address" onChange={handleInput} value={userInput.address} className='form-control' id='address'></textarea>
                            <small className='text-danger'>{errorList.address}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="profile_picture" className="form-label">Profile Picture</label>
                            <input type='file' name='profile_picture' onChange={handleImage} className='form-control' id='profile_picture' />
                            {userInput.profile_picture && <img src={`${process.env.REACT_APP_BACKEND_URL}/${userInput.profile_picture}`} className='avatar-lg mt-4' alt={userInput.id} />}
                            <small className='text-danger'>{errorList.profile_picture}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="role_as" className="form-label">Role</label>
                            <select name='role_as' onChange={handleInput} value={userInput.role_as} className='form-select' id='role_as'>
                                <option value="">Select Role</option>
                                {Roles.map((role, idx) => <option key={idx} value={role.value}>{role.label}</option>)}
                            </select>
                            <small className='text-danger'>{errorList.role_as}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="client_id" className="form-label">Select Client</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                value={clientOptions.filter(function(option) {
                                    return option.value === userInput.client_id;
                                })}
                                isSearchable
                                onChange={handleClient}
                                name="client_id"
                                options={clientOptions}
                            />
                            <small className='text-danger'>{errorList.client_id}</small>
                        </div>
                        <div className="col-12">
                            <div className="form-check">
                                <input type="checkbox" name='status' onChange={handleCheckbox} defaultChecked={allCheckbox.status === 1 ? true : false} className="form-check-input" id="status" />
                                <label htmlFor="status" className="form-check-label">Status (checked=Hidden)</label>
                            </div>
                        </div>
                        <div className="col-12">
                            <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-primary px-4 float-end'>
                                <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditUser;