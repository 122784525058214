import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import ShowMoreText from "react-show-more-text";
import DataTable from 'react-data-table-component';
import Avatar from 'react-avatar';

import { Statuses } from '../../../utils/Statuses';
import FilterComponent from '../../FilterComponent';

const clientsColumns = [
    {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
    },
    {
        name: 'Slug',
        selector: row => row.slug,
        sortable: true,
    },
    {
        name: 'Description',
        selector: row => row.description,
    },
    {
        name: 'Logo',
        selector: row => row.logo,
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
    },
    {
        name: 'Actions',
        selector: row => row.actions,
    },
];

let clientsData = [];

const ViewClients = () => {
    const [loading, setLoading] = useState(true);
    const [clientList, setClientList] = useState([]);
    const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    useEffect(() => {
        let isMounted = true;
        axios.get(`/api/view-clients`).then(res => {
            if (isMounted) {
                if (res.data.status === 200) {
                    setClientList(res.data.clients);
                    setLoading(false);
                }
            }
        });
        return () => {
            isMounted = false
        }
    }, []);

    const setTableData = useCallback(async () => {
        clientsData = []
        axios.get(`/api/view-clients`).then(res => {
            if (res.data.status === 200) {
                setClientList(res.data.clients);
                setLoading(false);
            }
        });
    }, []);

    const deleteClient = (e, id) => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Record",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(`/api/delete-client/${id}`).then(res => {
                    if (res.data.status === 200) {
                        swal(res.data.message, {
                            icon: "success",
                        });
                        setTableData();
                    } else if (res.data.status === 404) {
                        swal("Error", res.data.message, "error");
                    } else if (res.data.status === 405) {
                        swal("Error", res.data.message, "error");
                    }
                });
            }
        });
    }

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholderText="Filter By Name / Slug" />
        );
    }, [filterText, resetPaginationToggle]);

    if (loading) {
        return <h4>Loading Clients...</h4>
    } else {
        if (clientList.length > 0) {
            clientsData = clientList.filter(val => 
            (val.name && val.name.toLowerCase().includes(filterText.toLowerCase())) || 
            (val.slug && val.slug.toLowerCase().includes(filterText.toLowerCase()))
            ).map(item => {
                return ({
                    name :  item.name,
                    slug :  item.slug,
                    description :   <ShowMoreText
                                        lines={1}
                                        more="Show more"
                                        less="Show less"
                                        className="content-css"
                                        anchorClass="show-more-less-clickable"
                                        expanded={false}
                                        width={250}
                                        truncatedEndingComponent={"... "}
                                    >{item.description}
                                    </ShowMoreText>,
                    logo :  <Avatar name={item.name} src={item.logo !== '' ? `${process.env.REACT_APP_BACKEND_URL}/${item.logo}` : ''} size="40" round={true} />,
                    status :    Statuses.map((role, idx) => parseInt(item.status) === parseInt(role.value) ? <span key={idx} className={`badge rounded-pill bg-pill-${parseInt(role.value) ? 'inactive' : 'active'}`}>{role.label}</span> : ''),
                    actions :   <div className="d-flex">
                                    <Link to={`/admin/edit-client/${item.id}`} className="btn btn-outline-success btn-sm"><i className="fa fa-pencil"></i></Link>
                                    <button type='button' onClick={(e) => deleteClient(e, item.id)} className='btn btn-outline-danger btn-sm ms-1'><i className="fa fa-trash"></i></button>
                                </div>,
                });
            });
        }
    }

    return (
        <div className='px-4'>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h4>Client List
                        <Link to="/admin/add-client" className='btn btn-primary btn-sm float-end'>Add Client</Link>
                    </h4>
                </div>
                <div className='card-body'>
                    <DataTable
                        columns={clientsColumns}
                        data={clientsData}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle}
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                    />
                </div>
            </div>
        </div>
    );
}

export default ViewClients;