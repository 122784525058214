import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import DataTable from 'react-data-table-component';

import { LeaveStatuses } from '../../../utils/LeaveStatuses';
import { LeaveTypes } from '../../../utils/LeaveTypes';
import FilterComponent from '../../FilterComponent';

const leaveColumns = [
    {
        name: 'Requested By',
        selector: row => row.requested_by,
        sortable: true,
    },
    {
        name: 'Type',
        selector: row => row.type,
        sortable: true,
    },
    {
        name: 'Start Date',
        selector: row => row.start_date,
        sortable: true,
    },
    {
        name: 'End Date',
        selector: row => row.end_date,
        sortable: true,
    },
    {
        name: 'Reviewer',
        selector: row => row.reviewer,
        sortable: true,
    },
    {
        name: 'Review Date',
        selector: row => row.review_date,
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
    },
    {
        name: 'Actions',
        selector: row => row.actions,
        width: '200px'
    },
];

let leaveData = [];

const ViewLeaves = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [leaveList, setLeaveList] = useState([]);
    const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    if (!localStorage.getItem('auth_token')) {
        history.push('/login');
        swal('Unauthorized', 'Authorization Required', 'error');
    } else if (!props.allowedRoles.includes(localStorage.getItem('auth_role'))) {
        history.push('/403');
    }

    useEffect(() => {
        let isMounted = true;
        axios.get(`/api/all-leaves`).then(res => {
            if (isMounted) {
                if (res.data.status === 200) {
                    setLeaveList(res.data.leaves);
                    setLoading(false);
                }
            }
        });
        return () => {
            isMounted = false
        }
    }, []);

    const setTableData = useCallback(async () => {
        leaveData = [];
        axios.get(`/api/all-leaves`).then(res => {
            if (res.data.status === 200) {
                setLeaveList(res.data.leaves);
                setLoading(false);
            }
        });
    }, []);

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholderText="Filter By Requester / Reviewer" />
        );
    }, [filterText, resetPaginationToggle]);

    const deleteLeave = (e, id) => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Record",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(`/api/delete-leave/${id}`).then(res => {
                    if (res.data.status === 200) {
                        swal(res.data.message, {
                            icon: "success",
                        });
                        setTableData();
                    } else if (res.data.status === 404) {
                        swal("Error", res.data.message, "error");
                    } else if (res.data.status === 405) {
                        swal("Error", res.data.message, "error");
                    }
                });
            }
        });
    }

    if (loading) {
        return <h4>Loading Leaves...</h4>
    } else {
        if (leaveList.length > 0) {
            leaveData = leaveList.filter(val => 
            (val.user && val.user.name.toLowerCase().includes(filterText.toLowerCase())) || 
            (val.to_user && val.to_user.name.toLowerCase().includes(filterText.toLowerCase()))
            ).map(item => {
                return ({
                    requested_by    :   item.user && item.user.name,
                    type    :   LeaveTypes.map((leaveType, idx) => item.type === leaveType.value ? <span key={idx} className={`badge rounded-pill bg-pill-${leaveType.value}`}>{leaveType.label}</span> : ''),
                    start_date  :   item.start_date,
                    end_date    :   item.end_date,
                    reviewer    :   item.to_user && item.to_user.name,
                    review_date :   item.review_date,
                    status  :   LeaveStatuses.map((leaveStatus, idx) => item.status === leaveStatus.value ? <span key={idx} className={`badge rounded-pill bg-pill-${leaveStatus.value}`}>{leaveStatus.label}</span> : ''),
                    actions :  <div className="d-flex">
                        <Link to={`/admin/review-leave/${item.id}`} className="btn btn-outline-dark btn-sm"><i className="fa fa-eye"></i></Link>
                        {item.to_user.id.toString() === localStorage.getItem('auth_id') && <button type='button' onClick={(e) => deleteLeave(e, item.id)} className='btn btn-outline-danger btn-sm ms-1'><i className="fa fa-trash"></i></button>}
                    </div>,
                });
            });
        }
    }

    return (
        <div className='px-4'>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h4>Leave List</h4>
                </div>
                <div className='card-body'>
                    <DataTable
                        columns={leaveColumns}
                        data={leaveData}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle}
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                    />
                </div>
            </div>
        </div>
    );
}

export default ViewLeaves;