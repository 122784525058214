import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import ShowMoreText from "react-show-more-text";
import DataTable from 'react-data-table-component';
import parse from 'html-react-parser';

import { TaskStatuses } from '../../../utils/TaskStatuses';
import { TaskPriority } from '../../../utils/TaskPriority';
import { TaskTypes } from '../../../utils/TaskTypes';
import MinutesToTime from '../../../utils/MinutesToTime';

const timeLogColumns = [
    {
        name: 'Date',
        selector: row => row.date,
        sortable: true,
    },
    {
        name: 'Spent',
        selector: row => row.spent,
        sortable: true,
    },
    {
        name: 'Billable',
        selector: row => row.billable,
        sortable: true,
    },
    {
        name: 'Note',
        selector: row => row.note,
    },
    {
        name: 'Actions',
        selector: row => row.actions,
    }
];

let timeLogData = [];

const ViewTask = (props) => {
    const taskId = props.match.params.id;
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [projectList, setProjectList] = useState([]);
    const [assigneeList, setAssigneeList] = useState([]);
    const [qaAssigneeList, setQaAssigneeList] = useState([]);
    const [timeLogList, setTimeLogList] = useState([]);
    const [taskInput, setTask] = useState({
        project_id: '',
        task: '',
        description: '',
        due_date: '',
        estimate_time: '',
        task_status: '',
        task_priority: '',
        image: '',
        total_spent_time: '',
        total_non_billable_time: '',
        completed_date: '',
        qa_assignee_id: '',
        qa_comments: '',
    });

    if (!localStorage.getItem('auth_token')) {
        history.push('/login');
        swal('Unauthorized', 'Authorization Required', 'error');
    } else if (!props.allowedRoles.includes(localStorage.getItem('auth_role'))) {
        history.push('/403');
    }

    const setFormData = useCallback(async () => {
        timeLogData = [];
        axios.get(`/api/edit-task/${taskId}`).then(res => {
            if (res.data.status === 200) {
                setTask(res.data.task);
                setTimeLogList(res.data.timeLogs);
            } else if (res.data.status === 404) {
                swal("Error", res.data.message, "error");
                history.push('/admin/view-tasks');
            }
            setLoading(false);
        });
    }, [history, taskId])

    const setTableData = useCallback(async () => {
        timeLogData = [];
        axios.get(`/api/get-time-logs`).then(res => {
            if (res.data.status === 200) {
                setTimeLogList(res.data.timeLogs);
                setLoading(false);
            }
        });
    }, []);

    const deleteTimeLogs = (e, id) => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Record",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(`/api/delete-timelog/${id}`).then(res => {
                    if (res.data.status === 200) {
                        swal(res.data.message, {
                            icon: "success",
                        });
                        setTableData();
                    } else if (res.data.status === 404) {
                        swal("Error", res.data.message, "error");
                    } else if (res.data.status === 405) {
                        swal("Error", res.data.message, "error");
                    }
                });
            }
        });
    }

    useEffect(() => {
        axios.get(`/api/all-projects`).then(res => {
            if (res.data.status === 200) {
                setProjectList(res.data.projects);
            }
        });
        axios.get(`/api/all-employees`).then(res => {
            if (res.data.status === 200) {
                setAssigneeList(res.data.users);
            }
        });
        axios.get(`/api/get-qa-assignees`).then(res => {
            if (res.data.status === 200) {
                setQaAssigneeList(res.data.users);
            }
        });
        setFormData();
    }, [setFormData]);

    if (loading) {
        return <h4>Loading View Task...</h4>
    } else {
        if (timeLogList.length > 0) {
            timeLogData = timeLogList.map(item => {
                return ({
                    date :  item.date,
                    spent : MinutesToTime(item.spent_time),
                    billable : MinutesToTime(item.spent_time - item.non_billable_time),
                    note :  <ShowMoreText
                                lines={1}
                                more="Show more"
                                less="Show less"
                                className="content-css"
                                anchorClass="show-more-less-clickable"
                                expanded={false}
                                width={500}
                                truncatedEndingComponent={"... "}
                            >{item.note}
                            </ShowMoreText>,
                    actions :   <div className="d-flex">
                                    <button type='button' onClick={(e) => deleteTimeLogs(e, item.id)} className='btn btn-outline-danger btn-sm ms-1'><i className="fa fa-trash"></i></button>
                                </div>,
                });
            });
        }
    }

    return (
        <div className='px-4'>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h4>View Task
                        <Link to="/admin/view-tasks" className='btn btn-primary btn-sm float-end'>BACK</Link>
                    </h4>
                </div>
                <div className='card-body'>
                    <form encType='multipart/form-data' id="TASK_FORM" className="row g-3">
                        <div className="col-md-4">
                            <label htmlFor="project_id" className="form-label">Project</label>
                            <select name='project_id' value={taskInput.project_id} className='form-select' id="project_id" disabled>
                                <option value="">Select Project</option>
                                {
                                    projectList.map(item => {
                                        return (
                                            <option value={item.id} key={item.id}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="assignee_id" className="form-label">Assignee</label>
                            <select name='assignee_id' value={taskInput.assignee_id} className='form-select' id='assignee_id' disabled>
                                <option value="">Select Assignee</option>
                                {
                                    assigneeList.map(item => {
                                        return (
                                            <option value={item.id} key={item.id}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="assigned_by" className="form-label">Assigned By</label>
                            <input type="text" name="assigned_by" value={taskInput.assigned_by ? taskInput.assigned_by.name : 'Self Assigned'} className='form-control' id="task" disabled />
                        </div>
                        <div className="col-12">
                            <label htmlFor="task" className="form-label">Task</label>
                            <input type="text" name="task" value={taskInput.task} className='form-control' id="task" disabled />
                        </div>
                        <div className="col-12">
                            <label htmlFor="description" className="form-label">Description</label>
                            <div className='form-control html-desc'>{taskInput.description && parse(taskInput.description)}</div>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="task_type" className="form-label">Task Type</label>
                            <select name='task_type' value={taskInput.task_type} className='form-select' id='task_type' disabled>
                                <option value="">Select Task Type</option>
                                {TaskTypes.map((taskType, idx) => <option key={idx} value={taskType.value}>{taskType.label}</option>)}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="task_status" className="form-label">Task Status</label>
                            <select name='task_status' value={taskInput.task_status} className='form-select' id='task_status' disabled>
                                <option value="">Select Task Status</option>
                                {TaskStatuses.map((taskStatus, idx) => <option key={idx} value={taskStatus.value}>{taskStatus.label}</option>)}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="task_priority" className="form-label">Task Priority</label>
                            <select name='task_priority' value={taskInput.task_priority} className='form-select' id='task_priority' disabled>
                                <option value="">Select Task Priority</option>
                                {TaskPriority.map((taskPriority, idx) => <option key={idx} value={taskPriority.value}>{taskPriority.label}</option>)}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="due_date" className="form-label">Due Date</label>
                            <input type="date" name="due_date" value={taskInput.due_date} className='form-control' id='due_date' disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="completed_date" className="form-label">Completed Date</label>
                            <input type="date" name="completed_date" value={taskInput.completed_date} className='form-control' id='completed_date' disabled />
                        </div>
                        <div className="col-md-4">
                        {
                            taskInput.image &&
                            <>
                                <label htmlFor="image" className="form-label">File</label>
                                <div><a href={`${process.env.REACT_APP_BACKEND_URL}/${taskInput.image}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-dark mt-2">View File <i className="fa fa-external-link" aria-hidden="true"></i></a></div>
                            </>
                        }
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="estimate_time" className="form-label">Estimate Time (HH:MM)</label>
                            <input type="text" name="estimate_time" value={MinutesToTime(taskInput.estimate_time)} className='form-control' min="0" step="1" id='estimate_time' disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="spent_time" className="form-label">Total Spent Time (HH:MM)</label>
                            <input type="text" name="spent_time" value={MinutesToTime(taskInput.total_spent_time)} className='form-control' min="0" step="1" id='spent_time' disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="non_billable_time" className="form-label">Total Billable Time (HH:MM)</label>
                            <input type="text" name="non_billable_time" value={MinutesToTime(taskInput.total_spent_time - taskInput.total_non_billable_time)} className='form-control' min="0" step="1" id='non_billable_time' disabled />
                        </div>
                        <hr />
                        <div className="col-md-4">
                            <label htmlFor="qa_assignee_id" className="form-label">QA Assignee</label>
                            <select name='qa_assignee_id' value={taskInput.qa_assignee_id} className='form-select' id="qa_assignee_id" disabled>
                                <option value="">Select QA Assignee</option>
                                {
                                    qaAssigneeList.map(item => {
                                        return (
                                            <option value={item.id} key={item.id}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-12">
                            <label htmlFor="qa_comments" className="form-label">Task Comments</label>
                            <div className='form-control html-desc'>{taskInput.qa_comments && parse(taskInput.qa_comments)}</div>
                        </div>
                    </form>
                </div>
            </div>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h5>Time Log List</h5>
                </div>
                <div className='card-body'>
                    <DataTable
                        columns={timeLogColumns}
                        data={timeLogData}
                        pagination
                    />
                </div>
            </div>
        </div>
    );
}

export default ViewTask;