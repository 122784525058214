import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import parse from 'html-react-parser';
import Modal from 'react-modal';

import { LeaveStatuses } from '../../../utils/LeaveStatuses';
import { LeaveTypes } from '../../../utils/LeaveTypes';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        margin: '0',
        transform: 'translate(-50%, -50%)',
        minWidth: '350px',
    },
};

const ReviewLeave = (props) => {
    const leaveId = props.match.params.id;
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [pending, setPending] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalStatus, setModalStatus] = useState('');
    const [leaveInput, setLeave] = useState({
        user_id: '',
        type: '',
        details: '',
        start_date: '',
        end_date: '',
        to_user_id: '',
        status: '',
        review_date: '',
    });
    const [ccUserNames, setCcUserNames] = useState([]);

    if (!localStorage.getItem('auth_token')) {
        history.push('/login');
        swal('Unauthorized', 'Authorization Required', 'error');
    } else if (!props.allowedRoles.includes(localStorage.getItem('auth_role'))) {
        history.push('/403');
    }

    const setFormData = useCallback(async () => {
        axios.get(`/api/review-leave/${leaveId}`).then(res => {
            if (res.data.status === 200) {
                setLeave(res.data.leave);
                setCcUserNames(res.data.cc_users);
            } else if (res.data.status === 404) {
                swal("Error", res.data.message, "error");
                history.push('/admin/view-leaves');
            }
            setLoading(false);
        });
    }, [history, leaveId]);

    useEffect(() => {
        setFormData();
    }, [setFormData]);

    function openReviewModal(e, status) {
        e.preventDefault();
        setModalStatus(status);
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const handleInput = (e) => {
        e.persist();
        setLeave({ ...leaveInput, [e.target.name]: e.target.value });
    }

    const submitReview = (e) => {
        e.preventDefault();
        setPending(true);

        axios.get('/sanctum/csrf-cookie').then(response => {
            const formData = new FormData();
            formData.append('status', modalStatus);
            formData.append('reviewer_comment', leaveInput.reviewer_comment);
            formData.append('_method', 'PUT');

            axios.post(`/api/submit-review/${leaveId}`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
                if (res.data.status === 200) {
                    swal("Success", res.data.message, "success");
                    setFormData();
                    closeModal();
                    setPending(false);
                } else if (res.data.status === 422) {
                    swal("All fields are mandatory", "", "error");
                    setPending(false);
                } else if (res.data.status === 404) {
                    swal("Error", res.data.message, "error");
                    history.push('/admin/view-leaves');
                    setPending(false);
                }
            });
        });
    }

    if (loading) {
        return <h4>Loading Review Leave...</h4>
    }

    return (
        <div className='px-4'>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h4>Review Leave
                        <Link to="/admin/view-leaves" className='btn btn-primary btn-sm float-end'>BACK</Link>
                    </h4>
                </div>
                <div className='card-body'>
                    <form encType='multipart/form-data' id="LEAVE_FORM" className="row g-3">
                        <div className="col-md-3">
                            <label htmlFor="user_id" className="form-label">Employee Name</label>
                            <input type="text" name="user_id" value={leaveInput.user && leaveInput.user.name} className='form-control' id='user_id' disabled />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="type" className="form-label">Leave Type</label>
                            <select name='type' value={leaveInput.type} className='form-select' id='type' disabled>
                                <option value="">Select Leave Type</option>
                                {LeaveTypes.map((leaveType, idx) => <option key={idx} value={leaveType.value}>{leaveType.label}</option>)}
                            </select>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="start_date" className="form-label">Start Date</label>
                            <input type="date" name="start_date" value={leaveInput.start_date} className='form-control' id='start_date' disabled />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="end_date" className="form-label">End Date</label>
                            <input type="date" name="end_date" value={leaveInput.end_date} className='form-control' id='end_date' disabled />
                        </div>
                        <div className="col-12">
                            <label htmlFor="details" className="form-label">Message from Employee</label>
                            <div className='form-control html-desc'>{leaveInput.details && parse(leaveInput.details)}</div>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="to_user_id" className="form-label">Reviewer</label>
                            <input type="text" name="to_user_id" value={leaveInput.to_user && leaveInput.to_user.name} className='form-control' id='to_user_id' disabled />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="cc_user_ids" className="form-label">CC Reviewers</label>
                            <input type="text" name="cc_user_ids" value={ccUserNames} className='form-control' id='cc_user_ids' disabled />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="status" className="form-label">Leave Status</label>
                            <select name='status' value={leaveInput.status} className='form-select' id='status' disabled>
                                <option value="">Select Leave Status</option>
                                {LeaveStatuses.map((leaveStatus, idx) => <option key={idx} value={leaveStatus.value}>{leaveStatus.label}</option>)}
                            </select>
                        </div>
                        { 
                            leaveInput.status !== 'requested' && 
                            <>
                                <div className="col-md-3">
                                    <label htmlFor="review_date" className="form-label">Review Date</label>
                                    <input type="text" name="review_date" value={leaveInput.review_date} className='form-control' id='review_date' disabled />
                                </div>
                                <div className="col-9">
                                    <label htmlFor="reviewer_comment" className="form-label">Message to Employee</label>
                                    <textarea name="reviewer_comment" value={leaveInput.reviewer_comment} className='form-control' id="reviewer_comment" disabled rows={1}></textarea>
                                </div>
                            </>
                        }
                        { 
                            leaveInput.status === 'requested' && parseInt(leaveInput.to_user_id) === parseInt(localStorage.getItem('auth_id')) &&
                            <div className="col-12 mt-4">
                                <button className="btn btn-success px-4" onClick={(e) => openReviewModal(e, 'approved')}>Approve Request</button>
                                <button className="btn btn-danger px-4 ms-2 float-end" onClick={(e) => openReviewModal(e, 'declined')}>Decline Request</button>
                            </div>
                        }
                    </form>
                </div>
            </div>
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} ariaHideApp={false}>
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title">{modalStatus === 'approved' ? 'Approve Leave Request' : modalStatus === 'declined' ? 'Decline Leave Request' : ''}</h5>
                        <button type="button" className="btn-close" onClick={closeModal}></button>
                    </div>
                    <div className="modal-body">
                        <form encType='multipart/form-data' onSubmit={submitReview} id="LEAVE_MODAL_FORM" className="row g-3">
                            <div className="col-12">
                                <label htmlFor="reviewer_comment" className="form-label">Message to Employee</label>
                                <textarea name="reviewer_comment" onChange={handleInput} value={leaveInput.reviewer_comment} className='form-control' id="reviewer_comment" rows={5}></textarea>
                            </div>
                            <div className="col-12">
                                <>
                                    { modalStatus === 'approved' ? 
                                        <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-success px-4 float-end'>
                                            <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                                            Approve
                                        </button> 
                                    : modalStatus === 'declined' ? 
                                        <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-danger px-4 float-end'>
                                            <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                                            Decline
                                        </button> 
                                    : <></> }
                                </>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default ReviewLeave;