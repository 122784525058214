import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import ShowMoreText from "react-show-more-text";
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import swal from 'sweetalert';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import { TaskStatuses } from '../../../utils/TaskStatuses';
import { TaskPriority } from '../../../utils/TaskPriority';
import { TaskTypes } from '../../../utils/TaskTypes';
import MinutesToTime from '../../../utils/MinutesToTime';

const tasksColumns = [
  {
    name: 'Project',
    selector: row => row.project,
    sortable: true,
  },
  {
    name: 'Task',
    selector: row => row.task,
    sortable: true,
  },
  {
    name: 'Assigned To',
    selector: row => row.assignee,
    sortable: true,
  },
  {
    name: 'Assigned By',
    selector: row => row.assigned_by,
    sortable: true,
  },
  {
    name: 'Due Date',
    selector: row => row.due_date,
    sortable: true,
  },
  {
    name: 'Estimate',
    selector: row => row.estimate,
    sortable: true,
  },
  {
    name: 'Completed Date',
    selector: row => row.completed_date,
    sortable: true,
  },
  {
    name: 'Total Spent',
    selector: row => row.total_spent_time,
    sortable: true,
  },
  {
    name: 'Total Billable',
    selector: row => row.total_billable_time,
    sortable: true,
  },
  {
    name: 'Type',
    selector: row => row.task_type,
    sortable: true,
  },
  {
    name: 'Status',
    selector: row => row.task_status,
    sortable: true,
  },
  {
    name: 'Priority',
    selector: row => row.task_priority,
    sortable: true,
  },
  {
    name: 'Actions',
    selector: row => row.actions,
  },
];

const ProjectTimeReport = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [taskList, setTaskList] = useState([]);
  const [totalEstimateTime, setTotalEstimateTime] = useState(0);
  const [totalSpentTime, setTotalSpentTime] = useState(0);
  const [totalBillableTime, setTotalBillableTime] = useState(0);
  const [projectList, setProjectList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [errorList, setError] = useState([]);
  const [pending, setPending] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [projectInput, setProjectInput] = useState({
    project_id: '',
    user_id: 'all',
    task_type: 'all'
  });

  useEffect(() => {
    if (localStorage.getItem('auth_designation') !== 'qa-intern') {
      axios.get(`/api/project-time-report`).then(res => {
        if (res.data.status === 200) {
          setTaskList([]);
          setError([]);
          setProjectList(res.data.projects);
          setUserList(res.data.users);
        }
          setLoading(false);
        });
    } else {
      history.push('/admin/dashboard');
    }
// eslint-disable-next-line
  }, []);

  const handleProject = (value) => {
    setProjectInput({ ...projectInput, project_id: value });
  }

  const handleTaskType = (value) => {
    setProjectInput({ ...projectInput, task_type: value });
  }

  const handleUser = (value) => {
    setProjectInput({ ...projectInput, user_id: value });
  }

  const generateReport = (e) => {
    e.preventDefault();
    setPending(true);
    setTaskList([]);
    setError([]);
    let startingDate = 'start';
    let endingDate = 'end';
    if (dateRange && dateRange.length > 0) {
      let newStartDate = dateRange[0];
      let newEndDate = dateRange[1];
      startingDate = `${newStartDate.getFullYear()}-${newStartDate.getMonth()+1}-${newStartDate.getDate()}`;
      endingDate = `${newEndDate.getFullYear()}-${newEndDate.getMonth()+1}-${newEndDate.getDate()}`;
    }
    axios.get('/sanctum/csrf-cookie').then(response => {
      axios.get(`/api/generate-project-time-report/${projectInput.project_id}/${projectInput.user_id}/${projectInput.task_type}/${startingDate}/${endingDate}`).then(res => {
        if (res.data.status === 200) {
          // setTimeLogList(res.data.time);
          let tasksData = [];
          let newTotalEstimateTime = 0;
          let newTotalSpentTime = 0;
          let newTotalBillableTime = 0;
          if (res.data.tasks.length > 0) {
            tasksData = res.data.tasks.map(item => {
              if (item.estimate_time === null) {
                item.estimate_time = 0;
              }
              newTotalEstimateTime += parseInt(item.estimate_time);

              if (item.total_spent_time === null) {
                item.total_spent_time = 0;
              }
              newTotalSpentTime += (item.total_spent_time ? parseInt(item.total_spent_time) : 0);

              if (item.total_non_billable_time === null) {
                item.total_non_billable_time = 0;
              }
              newTotalBillableTime += parseInt(item.total_spent_time - item.total_non_billable_time);

              return ({
                  project : item.project && item.project.name,
                  task  :  <ShowMoreText
                              lines={1}
                              more="Show more"
                              less="Show less"
                              className="content-css"
                              anchorClass="show-more-less-clickable"
                              expanded={false}
                              width={200}
                              truncatedEndingComponent={"... "}
                          >
                            {item.task}
                          </ShowMoreText>,
                  assignee :  item.assignee ? item.assignee.name : 'Self Assigned',
                  assigned_by : item.assigned_by ? item.assigned_by.name : 'Self Assigned',
                  due_date :  item.due_date,
                  estimate :  MinutesToTime(item.estimate_time),
                  completed_date  : item.completed_date,
                  total_spent_time  : MinutesToTime(item.total_spent_time),
                  total_billable_time : MinutesToTime(item.total_spent_time - item.total_non_billable_time),
                  task_type : TaskTypes.map((taskType, idx) => item.task_type === taskType.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskType.value}`}>{taskType.label}</span> : ''),
                  task_status : TaskStatuses.map((taskStatus, idx) => item.task_status === taskStatus.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskStatus.value}`}>{taskStatus.label}</span> : ''),
                  task_priority : TaskPriority.map((taskPriority, idx) => item.task_priority === taskPriority.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskPriority.value}`}>{taskPriority.label}</span> : ''),
                  actions : <div className="d-flex">
                              <Link to={`/admin/view-task/${item.id}`} className="btn btn-outline-secondary btn-sm"><i className="fa fa-eye"></i></Link>
                            </div>,
              });
            });
          }
          setTotalEstimateTime(newTotalEstimateTime);
          setTotalSpentTime(newTotalSpentTime);
          setTotalBillableTime(newTotalBillableTime);
          setTaskList(tasksData);
          setPending(false);
        } else if (res.data.status === 404) {
          swal("Error", res.data.message, "error");
          history.push('/admin/project-time-report');
          setPending(false);
        }  else if (res.data.status === 405) {
          swal("Error", res.data.message, "error");
          setPending(false);
        }
      });
    });
  }

  if (loading) {
    return <h4>Loading Resource Allocation Report...</h4>
  }

  return (
    <div className='px-4'>
      <div className='card mt-4'>
        <div className='card-header'>
          <h4>Project Time Report</h4>
        </div>
        <div className='card-body'>
          <form encType='multipart/form-data' onSubmit={generateReport} id="REPORT_FORM" className="row gx-3 gy-2 align-items-center">
            <div className='row pt-3'>
              <div className="col-12 col-md-6 col-lg-3">
                <DateRangePicker 
                  onChange={setDateRange} 
                  value={dateRange} 
                  format='y-MM-dd'
                  yearPlaceholder='yyyy'
                  monthPlaceholder='mm'
                  dayPlaceholder='dd'
                  className='my-calender pt-1'
                />
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <label htmlFor="project_id" className="visually-hidden">Select Project</label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  required
                  defaultValue={projectInput.project_id}
                  isSearchable
                  isClearable
                  onChange={(options) =>
                    !options ? handleProject("all") : handleProject(options.value)
                  }
                  name="project_id"
                  id="project_id"
                  placeholder="Select Project..."
                  options={
                    projectList.map(item => {
                      return ({
                        value: item.id,
                        label: item.name
                      })
                    })
                  }
                />
                <small className='text-danger'>{errorList.project_id}</small>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <label htmlFor="user_id" className="visually-hidden">Select User</label>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={projectInput.user_id}
                    isSearchable
                    isClearable
                    onChange={(options) =>
                      !options ? handleUser("all") : handleUser(options.value)
                    } 
                    name="user_id"
                    id="user_id"
                    placeholder="Select User..."
                    options={
                        userList.map(item => {
                            return (
                                {
                                    value: item.id,
                                    label: item.name
                                }
                            )
                        })
                    }
                />
                <small className='text-danger'>{errorList.user_id}</small>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <label htmlFor="task_type" className="visually-hidden">Select Task Type</label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue={projectInput.task_type}
                  isSearchable
                  isClearable
                  onChange={(options) =>
                    !options ? handleTaskType("all") : handleTaskType(options.value)
                  }
                  name="task_type"
                  id="task_type"
                  placeholder="Select Task Type..."
                  options={TaskTypes}
                />
                <small className='text-danger'>{errorList.task_type}</small>
              </div>
            </div>
            <div className='row pt-3'>
              <div className="d-flex justify-content-end">
                <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-dark'>
                  <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                  Generate Report
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="row pt-5">
          <div className="col-md-3">
            <div className="card text-bg-dark mb-3">
              <div className="card-header"><h5>Total Estimated (HH:MM)</h5></div>
              <div className="card-body">
                <h5 className="card-title">{MinutesToTime(totalEstimateTime)}</h5>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card text-bg-dark mb-3">
              <div className="card-header"><h5>Total Spent (HH:MM)</h5></div>
              <div className="card-body">
                <h5 className="card-title">{MinutesToTime(totalSpentTime)}</h5>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card text-bg-dark mb-3">
              <div className="card-header"><h5>Total Billable (HH:MM)</h5></div>
              <div className="card-body">
                <h5 className="card-title">{MinutesToTime(totalBillableTime)}</h5>
              </div>
            </div>
          </div>
      </div>
      <div className='card mt-4'>
        <div className='card-header'>
          <h5>Task Data</h5>
        </div>
        <div className='card-body'>
          <DataTable
            columns={tasksColumns}
            data={taskList}
            pagination
          />
        </div>
      </div>
    </div>
  );
}

export default ProjectTimeReport;