import React from 'react';

const Footer = () => {
    return (
        <footer className="py-4 bg-light mt-auto">
            <div className="container-fluid px-4">
                <div className="d-flex align-items-center justify-content-between small">
                    <div className="text-muted">Copyright &copy; <a href="https://smashy.design/" target="_blank" rel="noopener noreferrer">Smashy Design</a> {new Date().getFullYear()}</div>
                    <div>
                        <a href="https://smashy.design/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                        &middot;
                        <a href="https://smashy.design/" target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;