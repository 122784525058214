import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import swal from 'sweetalert';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { addDays, differenceInDays, isWeekend, format  } from 'date-fns';

import { modules } from '../../../utils/EditorToolbar';
import { LeaveTypes } from '../../../utils/LeaveTypes';

const mercantileHolidays = [
  "2024-01-15",
  "2024-01-25",
  "2024-02-23",
  "2024-03-24",
  "2024-04-12",
  "2024-04-15",
  "2024-04-23",
  "2024-05-01",
  "2024-05-23",
  "2024-05-24",
  "2024-06-21",
  "2024-08-19",
  "2024-09-16",
  "2024-09-17",
  "2024-10-17",
  "2024-11-15",
  "2024-12-25",
]

const AddLeave = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [toUserList, setToUserList] = useState([]);
    const [ccUsersList, setCcUsersList] = useState([]);
    const [errorList, setError] = useState([]);
    const [pending, setPending] = useState(false);
    const [leaveInput, setLeave] = useState({
        type: '',
        details: '',
        start_date: '',
        end_date: 'null',
        to_user_id: '',
        cc_user_ids: '',
    });
    // const [leaveList, setLeaveList] = useState([]);
    const [leaveCount, setLeaveCount] = useState([]);

    if (!localStorage.getItem('auth_token')) {
        history.push('/login');
        swal('Unauthorized', 'Authorization Required', 'error');
    } else if (!props.allowedRoles.includes(localStorage.getItem('auth_role'))) {
        history.push('/403');
    }

    const holidayCount = (startDate, EndDate) => {
      let loopDate = new Date(startDate);
      let removeDaysCount = 0;
      while (loopDate <= new Date(EndDate)) {
        if (isWeekend(loopDate)) {
          removeDaysCount++;
        }

        if (mercantileHolidays.includes(format(loopDate, 'yyyy-MM-dd'))) {
          removeDaysCount++;
        }

        loopDate = addDays(loopDate,1);
      }

      return removeDaysCount;
    }

    const showLabel = (leaveType) => {
      if (leaveType.value === 'casual') {
        return `${leaveType.label} - ${leaveCount[0]} / 7`;
      } else if (leaveType.value === 'sick') {
        return `${leaveType.label} - ${leaveCount[1]} / 7`;
      } else if (leaveType.value === 'annual') {
        return `${leaveType.label} - ${leaveCount[2]} / 14`;
      } else if (leaveType.value === 'short') {
        return `${leaveType.label} - ${leaveCount[3]} / 2`;
      } else if (leaveType.value === 'half-day') {
        return `${leaveType.label} - ${leaveCount[0]} / 7`;
      } else if (leaveType.value === 'half-day-medical') {
        return `${leaveType.label} - ${leaveCount[1]} / 7`;
      } else {
        return `${leaveType.label}`;
      }
    }

    const isOptionDisabled = (leaveType) => {
      if (leaveType.value === 'casual') {
        if (leaveCount[0] >= 6.5) {
          return true;
        } else {
          return false;
        }
      } else if (leaveType.value === 'sick') {
        if (leaveCount[1] >= 6.5) {
          return true;
        } else {
          return false;
        }
      } else if (leaveType.value === 'annual') {
        if (leaveCount[2] >= 14) {
          return true;
        } else {
          return false;
        }
      } else if (leaveType.value === 'short') {
        if (leaveCount[3] >= 2) {
          return true;
        } else {
          return false;
        }
      } else if (leaveType.value === 'half-day') {
        if (leaveCount[0] >= 7) {
          return true;
        } else {
          return false;
        }
      } else if (leaveType.value === 'half-day-medical') {
        if (leaveCount[1] >= 7) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    const hanndleSubmitValidation = (leaveType) => {
      if (leaveType.type === 'casual' && leaveType.start_date) {
        if (!leaveType.end_date || leaveType.end_date === 'null') {
          if (leaveCount[0] < 6.5 || holidayCount(leaveType.start_date,leaveType.start_date)===0) {
            return true;
          } else {
            return false;
          }
        } else {
          const holidays = holidayCount(leaveType.start_date, leaveType.end_date);
          const days = differenceInDays(new Date(leaveType.end_date), new Date(leaveType.start_date)) +1 - holidays;
          if (leaveCount[0]+days > 7) {
            return false;
          } else {
            return true;
          }
        }
      } else if (leaveType.type === 'sick' && leaveType.start_date) {
        if (!leaveType.end_date || leaveType.end_date === 'null') {
          if (leaveCount[1] < 7 || holidayCount(leaveType.start_date,leaveType.start_date)===0) {
            return true;
          } else {
            return false;
          }
        } else {
          const holidays = holidayCount(leaveType.start_date, leaveType.end_date);
          const days = differenceInDays(new Date(leaveType.end_date), new Date(leaveType.start_date)) +1 - holidays;
          if (leaveCount[1]+days > 7) {
            return false;
          } else {
            return true;
          }
        }
      } else if (leaveType.type === 'annual' && leaveType.start_date) {
        if (!leaveType.end_date || leaveType.end_date === 'null') {
          if (leaveCount[2] < 14 || holidayCount(leaveType.start_date,leaveType.start_date)===0) {
            return true;
          } else {
            return false;
          }
        } else {
          const holidays = holidayCount(leaveType.start_date, leaveType.end_date);
          const days = differenceInDays(new Date(leaveType.end_date), new Date(leaveType.start_date)) +1 - holidays;
          if (leaveCount[2]+days > 14) {
            return false;
          } else {
            return true;
          }
        }
      } else if (leaveType.type === 'short' && leaveType.start_date) {
        if (leaveCount[3] >= 2) {
          return false;
        } else {
          return true;
        }
      } else if (leaveType.type === 'half-day' && leaveType.start_date) {
        if (leaveCount[0] < 7 || holidayCount(leaveType.start_date,leaveType.start_date)===0) {
          return true;
        } else {
          return false;
        }
      } else if (leaveType.type === 'half-day-medical' && leaveType.start_date) {
        if (leaveCount[1] < 7 || holidayCount(leaveType.start_date,leaveType.start_date)===0) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    }

    useEffect(() => {
        axios.get(`/api/get-users`).then(res => {
            if (res.data.status === 200) {
                setCcUsersList(res.data.users);
                setLoading(false);
            }
        });
        axios.get(`/api/get-admins`).then(res => {
            if (res.data.status === 200) {
                setToUserList(res.data.users);
                setLoading(false);
            }
        });
        axios.get(`/api/get-leaves`).then(res => {
          if (res.data.status === 200) {

            let thisYearLeaves = [];

            // Get Aproved Leaves for this year
            res.data.leaves.forEach(leave => {
              if ((new Date(leave.start_date)).getFullYear() === new Date().getFullYear() && leave.status === 'approved') {
                thisYearLeaves.push(leave)
              }
            });

            let leavesCount = [0,0,0,0]
            thisYearLeaves.forEach(leave => {
              if (leave.type === 'casual') {
                const removeDaysCount = holidayCount(leave.start_date,leave.end_date);
                let days = differenceInDays(new Date(leave.end_date), new Date(leave.start_date));
                leavesCount[0] = leavesCount[0]+days+1-removeDaysCount;
              }
              if (leave.type === 'half-day') {
                const removeDaysCount = holidayCount(leave.start_date,leave.end_date);
                if (removeDaysCount === 0) {
                  leavesCount[0] = leavesCount[0]+0.5;
                }
              }
              if (leave.type === 'half-day-medical') {
                const removeDaysCount = holidayCount(leave.start_date,leave.end_date);
                if (removeDaysCount === 0) {
                  leavesCount[1] = leavesCount[1]+0.5;
                }
              }
              if (leave.type === 'sick') {
                const removeDaysCount = holidayCount(leave.start_date,leave.end_date);
                let days = differenceInDays(new Date(leave.end_date), new Date(leave.start_date));
                leavesCount[1] = leavesCount[1]+days+1-removeDaysCount;
              }
              if (leave.type === 'annual') {
                const removeDaysCount = holidayCount(leave.start_date,leave.end_date);
                let days = differenceInDays(new Date(leave.end_date), new Date(leave.start_date));
                leavesCount[2] = leavesCount[2]+days+1-removeDaysCount;
              }
              if (leave.type === 'short') {
                if ((new Date(leave.start_date)).getMonth() === new Date().getMonth()) {
                  const removeDaysCount = holidayCount(leave.start_date,leave.end_date);
                  if (removeDaysCount === 0) {
                    leavesCount[3] = leavesCount[3]+1;
                  }
                }
              }
            });

            setLeaveCount(leavesCount);
            setLoading(false);
          }
        });
    }, []);

    const resetForm = () => {
        setLeave({
            type: '',
            details: '',
            start_date: '',
            end_date: '',
            to_user_id: '',
            cc_user_ids: '',
        });
    }

    const handleInput = (e) => {
        e.persist();
        setLeave({ ...leaveInput, [e.target.name]: e.target.value });
    }

    const handleToUser = (user) => {
        let selectedUser = toUserList.find(item => item.id === user.value);
        if (selectedUser) {
            setLeave(
                { ...leaveInput,
                    to_user_id: selectedUser.id,
                }
            )
        }
    }

    const handleCcUsers = (user) => {
        const selectedUsersArr = user ? user.map(item => item.value) : [];
        setLeave(
            { ...leaveInput,
                cc_user_ids: selectedUsersArr,
            }
        )
    }

    const handleDetails = (value) => {
        setLeave({ ...leaveInput, details: value });
    }

    const submitLeave = (e) => {
        e.preventDefault();
        setPending(true);
        const formData = new FormData();
        formData.append('type', leaveInput.type);
        formData.append('details', leaveInput.details);
        formData.append('start_date', leaveInput.start_date);
        formData.append('end_date', leaveInput.end_date);
        formData.append('to_user_id', leaveInput.to_user_id);
        formData.append('cc_user_ids', leaveInput.cc_user_ids);
        if (hanndleSubmitValidation(leaveInput)) {
          axios({
            method: "post",
            url: `/api/add-leave-data`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          }).then(res => {
              if (res.data.status === 200) {
                  swal("Success", res.data.message, "success");
                  resetForm();
                  setError([]);
                  setPending(false);
              } else if (res.data.status === 422) {
                  swal("All Fields are mandatory", "", "error");
                  setError(res.data.errors);
                  setPending(false);
              }
          });
          // setError([]);
          // setPending(false);
          // resetForm();
        } else {
          setError(
            { ...errorList,
              type: "Exceeded Leaves Limit",
            }
          )
          setPending(false);
        }
    }

    const toUserOptions = toUserList.map(user => {
        return ({
            value: user.id,
            label: user.name+' ('+user.email+')'
        })
    });

    const ccUserOptions = ccUsersList.map(user => {
        return ({
            value: user.id,
            label: user.name+' ('+user.email+')'
        })
    });

    if (loading) {
        return <h4>Loading...</h4>
    }

    return (
        <div className='container py-5'>
            <div className='card'>
                <div className='card-header'>
                    <h4>Request a Leave
                        <Link to="/leaves" className='btn btn-primary btn-sm float-end'>View My Leaves</Link>
                    </h4>
                </div>
                <div className='card-body'>
                    <form encType='multipart/form-data' onSubmit={submitLeave} id="LEAVE_FORM" className="row g-3">
                        <div className="col-md-4">
                            <label htmlFor="type" className="form-label">Leave Type</label>
                            <select name='type' onChange={handleInput} value={leaveInput.type} className='form-select' id='type'>
                                <option value="">Select Leave Type</option>
                                {/* {LeaveTypes.map((leaveType, idx) => <option key={idx} value={leaveType.value}>{`${leaveType.label}`}</option>)} */}
                                {LeaveTypes.map((leaveType, idx) => <option key={idx} value={leaveType.value} disabled={isOptionDisabled(leaveType)} >{showLabel(leaveType)}</option>)}
                            </select>
                            <small className='text-danger'>{errorList.type}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="start_date" className="form-label">Start Date</label>
                            <input type="date" name="start_date" onChange={handleInput} value={leaveInput.start_date} className='form-control' id='start_date' />
                            <small className='text-danger'>{errorList.start_date}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="end_date" className="form-label">End Date</label>
                            <input type="date" name="end_date" onChange={handleInput} value={leaveInput.end_date} className='form-control' id='end_date' />
                            <small className='text-danger'>{errorList.end_date}</small>
                        </div>
                        <div className="col-12">
                            <label htmlFor="details" className="form-label">Details</label>
                            <ReactQuill
                                theme="snow"
                                value={leaveInput.details}
                                onChange={handleDetails}
                                modules={modules}
                            />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="to_user_id" className="form-label">Reviewer</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                value={toUserOptions.filter(function(option) {
                                    return option.value === leaveInput.to_user_id;
                                })}
                                isSearchable
                                onChange={handleToUser}
                                name="to_user_id"
                                options={toUserOptions}
                            />
                            <small className='text-danger'>{errorList.to_user_id}</small>
                        </div>
                        <div className="col-md-8">
                            <label htmlFor="cc_user_ids" className="form-label">CC Users</label>
                            <Select
                                className="basic-multi-select"
                                classNamePrefix="select"
                                isSearchable
                                isMulti
                                onChange={handleCcUsers}
                                name="cc_user_ids"
                                options={ccUserOptions}
                            />
                            <small className='text-danger'>{errorList.cc_user_ids}</small>
                        </div>
                        <div className="col-12">
                            <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-primary px-4 mt-2 float-end'>
                                <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AddLeave;