const MinutesToTime = (minutes) => {
  let timeInMinutes = minutes;
  let hrs = Math.floor(timeInMinutes/60) ;
  let mins = timeInMinutes - hrs*60;
  if (hrs < 10) {
    hrs = `0${hrs}`;
  }
  if (mins < 10) {
    mins = `0${mins}`;
  }
  let returnTime = `${hrs} : ${mins}`;
  return returnTime;
}

export default MinutesToTime;