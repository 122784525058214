import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

import { Designations } from '../../utils/Designations';

const Profile = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [pending, setPending] = useState(false);
    const [userInput, setUser] = useState({
        name: '',
        designation: '',
        phone: '',
        address: '',
        birth_date: '',
    });
    const [picture, setPicture] = useState([]);
    const [errorList, setError] = useState([]);

    if (!localStorage.getItem('auth_token')) {
        history.push('/login');
        swal('Unauthorized', 'Authorization Required', 'error');
    } else if (!props.allowedRoles.includes(localStorage.getItem('auth_role'))) {
        history.push('/403');
    }

    const setFormData = useCallback(async () => {
        axios.get(`/api/edit-profile`).then(res => {
            if (res.data.status === 200) {
                setUser(res.data.user);
                setPicture({ profile_picture: res.data.user.profile_picture });
            } else if (res.data.status === 404) {
                swal("Error", res.data.message, "error");
                history.push('/');
            }
            setLoading(false);
        });
    }, [history])

    useEffect(() => {
        setFormData();
    }, [setFormData]);
    
    const handleInput = (e) => {
        e.persist();
        setUser({ ...userInput, [e.target.name]: e.target.value });
    }

    const handleImage = (e) => {
        e.persist();
        setPicture({ profile_picture: e.target.files[0] });
    }

    const updateUser = (e) => {
        e.preventDefault();
        setPending(true);

        axios.get('/sanctum/csrf-cookie').then(response => {
            const formData = new FormData();
            formData.append('name', userInput.name);
            formData.append('designation', userInput.designation);
            formData.append('phone', userInput.phone);
            formData.append('address', userInput.address);
            formData.append('birth_date', userInput.birth_date);
            formData.append('profile_picture', picture.profile_picture);
            formData.append('_method', 'PUT');
            
            axios.post(`/api/update-profile`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
                if (res.data.status === 200) {
                    swal("Success", res.data.message, "success");
                    localStorage.setItem('auth_name', res.data.user.name);
                    localStorage.setItem('auth_image', res.data.user.profile_picture ? res.data.user.profile_picture : '');
                    setError([]);
                    setFormData();
                    setPending(false);
                } else if (res.data.status === 422) {
                    swal("All fields are mandatory", "", "error");
                    setError(res.data.errors);
                    setPending(false);
                } else if (res.data.status === 404) {
                    swal("Error", res.data.message, "error");
                    history.push('/');
                    setPending(false);
                }
            });
        });
    }

    if (loading) {
        return <h4>Loading Edit User Profile...</h4>
    }

    return (
        <div className='container py-5'>
            <div className='card'>
                <div className='card-header'>
                    <h4>Edit User Profile</h4>
                </div>
                <div className='card-body'>
                    <form encType='multipart/form-data' onSubmit={updateUser} id="USER_FORM" className="row g-3">
                        <div className="col-md-6">
                            <label htmlFor="name" className="form-label">Full Name</label>
                            <input type="text" name="name" onChange={handleInput} value={userInput.name} className='form-control' id='name' />
                            <small className='text-danger'>{errorList.name}</small>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="phone" className="form-label">Mobile Number</label>
                            <input type="text" name="phone" onChange={handleInput} value={userInput.phone} className='form-control' id='phone' />
                            <small className='text-danger'>{errorList.phone}</small>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="birth_date" className="form-label">Birth Date</label>
                            <input type="date" name="birth_date" onChange={handleInput} value={userInput.birth_date} className='form-control' id='birth_date' />
                            <small className='text-danger'>{errorList.birth_date}</small>
                        </div>
                        {
                            localStorage.getItem('auth_role') !== 'client' &&
                            <div className="col-md-6">
                                <label htmlFor="designation" className="form-label">Designation</label>
                                <select name='designation' onChange={handleInput} value={userInput.designation} className='form-select' id='designation'>
                                    <option value="">Select Designation</option>
                                    {Designations.map((designation, idx) => <option key={idx} value={designation.value}>{designation.label}</option>)}
                                </select>
                                <small className='text-danger'>{errorList.designation}</small>
                            </div>
                        }
                        <div className="col-12">
                            <label htmlFor="address" className="form-label">Address</label>
                            <textarea name="address" onChange={handleInput} value={userInput.address} className='form-control' id='address'></textarea>
                            <small className='text-danger'>{errorList.address}</small>
                        </div>
                        <div className="col-12">
                            <label htmlFor="profile_picture" className="form-label">Profile Picture</label>
                            <input type='file' name='profile_picture' onChange={handleImage} className='form-control' id='profile_picture' />
                            {userInput.profile_picture && <img src={`${process.env.REACT_APP_BACKEND_URL}/${userInput.profile_picture}`} className='avatar-lg mt-4' alt={userInput.id} />}
                            <small className='text-danger'>{errorList.profile_picture}</small>
                        </div>
                        <div className="col-12">
                            <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-primary px-4 float-end'>
                              <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                              Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Profile;