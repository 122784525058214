import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import Avatar from 'react-avatar';
import Modal from 'react-modal';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { modules } from '../../../utils/EditorToolbar';
import { TaskPriority } from '../../../utils/TaskPriority';
import MinutesToTime from '../../../utils/MinutesToTime';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        margin: '0',
        transform: 'translate(-50%, -50%)',
        minWidth: '90%',
        maxWidth: '100%',
        height: 'calc(70vh - 10%)',
        marginTop: '20px'
    },
};

const MyProjects = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [projects, setProjects] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [errorList, setError] = useState([]);
    const [pending, setPending] = useState(false);
    const [clientTaskInput, setClientTask] = useState({
        project_id: '',
        task: '',
        description: '',
        ideal_date: '',
        task_priority: '',
    });
    const [taskFile, setTaskFile] = useState([]);

    if (!localStorage.getItem('auth_token')) {
        history.push('/login');
        swal('Unauthorized', 'Authorization Required', 'error');
    } else if (!props.allowedRoles.includes(localStorage.getItem('auth_role'))) {
        history.push('/403');
    }

    useEffect(() => {
        let isMounted = true;
        axios.get(`/api/get-client-projects`).then(res => {
            if (isMounted) {
                if (res.data.status === 200) {
                    setProjects(res.data.projects);
                    setTasks(res.data.tasks);
                    setLoading(false);
                } else if (res.data.status === 404) {
                    swal("Error", res.data.message, "error");
                    history.push('/');
                }
            }
        });
        return () => {
            isMounted = false
        }
    }, [history]);

    const resetForm = () => {
        setClientTask({
            project_id: '',
            task: '',
            description: '',
            ideal_date: '',
            task_priority: '',
        });

        setTaskFile([]);
    }

    function openModal(e, projectId) {
        resetForm();
        projectId && setClientTask({ ...clientTaskInput, project_id: projectId });
        setIsOpen(true);
    }

    function closeModal() {
        setError([]);
        setIsOpen(false);
    }

    const handleTaskFile = (e) => {
        e.persist();
        setTaskFile({ file: e.target.files[0] });
    }

    const handleInput = (e) => {
        e.persist();
        setClientTask({ ...clientTaskInput, [e.target.name]: e.target.value });
    }

    const handleDescription = (value) => {
        setClientTask({ ...clientTaskInput, description: value });
    }

    const handleProject = (project) => {
        setClientTask({ ...clientTaskInput, project_id: project.value });
    }

    const submitClientTask = (e) => {
        e.preventDefault();
        setPending(true);
        const formData = new FormData();
        formData.append('project_id', clientTaskInput.project_id);
        formData.append('task', clientTaskInput.task);
        formData.append('description', clientTaskInput.description);
        formData.append('ideal_date', clientTaskInput.ideal_date);
        formData.append('file', taskFile.file);
        formData.append('task_priority', clientTaskInput.task_priority);

        axios({
            method: "post",
            url: `/api/add-client-task-data`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(res => {
            if (res.data.status === 200) {
                swal("Success", res.data.message, "success");
                resetForm();
                setError([]);
                closeModal();
                setPending(false);
            } else if (res.data.status === 422) {
                swal("All Fields are mandatory", "", "error");
                setError(res.data.errors);
                setPending(false);
            }
        });
    }

    const projectOptions = projects.map(item => {
        return ({
            value: item.id,
            label: item.name
        })
    });

    let showProjectList = [];
    let showDisabledProjectList = [];
    let completedTasks = [];
    let inProgressTasks = [];
    if (loading) {
        return <h4>Loading Projects...</h4>
    } else {
        showProjectList = projects.filter(res => res.support_enabled === 0).map((item, idx) => {
            return (
                <div className="col-md-6" key={idx}>
                    <div className="card shadow-sm h-100">
                        <div className="card-body d-flex flex-column">
                            <div className="row pb-3">
                                <div className="col-10 mx-auto">
                                    <a href={`my-tasks/${item.slug}`}><Avatar name={item.name} src={item.logo ? `${process.env.REACT_APP_BACKEND_URL}/${item.logo}` : ''} size="50" round={true} /><h5 className="d-inline-block ms-2">{item.name}</h5></a>
                                </div>
                                <div className="col-2 mx-auto">
                                    <button className="btn btn-sm btn-warning mt-2 float-end" onClick={(e) => openModal(e, item.id)}><i className="fa fa-plus" aria-hidden="true"></i></button>
                                </div>
                            </div>
                            <div className="row pb-3">
                                <div className="card-text">{item.description}</div>
                            </div>
                            <div className="row pt-3 mt-auto">
                                <div className="d-flex justify-content-between">
                                    {item.production_url && <a href={item.production_url} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-dark">Production <i className="fa fa-external-link" aria-hidden="true"></i></a>}
                                    {item.staging_url && <a href={item.staging_url} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-dark">Staging <i className="fa fa-external-link" aria-hidden="true"></i></a>}
                                    {item.spec_url && <a href={item.spec_url} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-dark">Spec <i className="fa fa-external-link" aria-hidden="true"></i></a>}
                                    {item.design_url && <a href={item.design_url} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-dark">Design <i className="fa fa-external-link" aria-hidden="true"></i></a>}
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="row">
                                <div className="d-flex justify-content-between">
                                    <div><span className="badge rounded-pill bg-success">&#x2713;</span> Support Enabled</div>
                                    {(item.allocated_hours_per_month !== null && item.allocated_hours_per_month !== 0) && <div>Rem: {MinutesToTime(item.allocated_hours_per_month)} Hours</div> }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        showDisabledProjectList = projects.filter(res => res.support_enabled === 1).map((item, idx) => {
            return (
                <div className="col-md-6" key={idx}>
                    <div className="card shadow-sm h-100">
                        <div className="card-body d-flex flex-column">
                            <div className="row pb-3">
                                <div className="col-10 mx-auto">
                                    <a href={`my-tasks/${item.slug}`}><Avatar name={item.name} src={item.logo ? `${process.env.REACT_APP_BACKEND_URL}/${item.logo}` : ''} size="50" round={true} /><h5 className="d-inline-block ms-2">{item.name}</h5></a>
                                </div>
                                <div className="col-2 mx-auto">
                                    <button className="btn btn-sm btn-warning mt-2 float-end" onClick={(e) => openModal(e, item.id)}><i className="fa fa-plus" aria-hidden="true"></i></button>
                                </div>
                            </div>
                            <div className="row pb-3">
                                <div className="card-text">{item.description}</div>
                            </div>
                            <div className="row pt-3 mt-auto">
                                <div className="d-flex justify-content-between">
                                    {item.production_url && <a href={item.production_url} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-dark">Production <i className="fa fa-external-link" aria-hidden="true"></i></a>}
                                    {item.staging_url && <a href={item.staging_url} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-dark">Staging <i className="fa fa-external-link" aria-hidden="true"></i></a>}
                                    {item.spec_url && <a href={item.spec_url} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-dark">Spec <i className="fa fa-external-link" aria-hidden="true"></i></a>}
                                    {item.design_url && <a href={item.design_url} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-dark">Design <i className="fa fa-external-link" aria-hidden="true"></i></a>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        completedTasks = tasks.filter(res => res.task_status === 'completed').map((item, idx) => {
            return (
                <li key={idx} className="list-group-item">
                    <div className='row'>
                        <div className='col-1'><span className={`badge rounded-pill bg-pill-${item.task_priority}`}>&#8226;</span></div>
                        <div className='col-2'>{item.project && item.project.slug.substring(0, 2).toUpperCase()}</div>
                        <div className='col-auto'><a href={`view-project-task/${item.id}`} className="link-dark">{item.task}</a></div>
                    </div>
                </li>
            );
        });

        inProgressTasks = tasks.filter(res => res.task_status === 'in-progress').map((item, idx) => {
            return (
                <li key={idx} className="list-group-item">
                    <div className='row'>
                        <div className='col-1'><span className={`badge rounded-pill bg-pill-${item.task_priority}`}>&#8226;</span></div>
                        <div className='col-2'>{item.project && item.project.slug.substring(0, 2).toUpperCase()}</div>
                        <div className='col-auto'><a href={`view-project-task/${item.id}`} className="link-dark">{item.task}</a></div>
                    </div>
                </li>
            );
        });
    }

    return (
        <section className="py-5">
            <div className="container">
                <div className="row">
                    <div className="col-xl-8">
                        <div className="row">
                            <div className="col-md-6 mx-auto">
                                <h4 className="fw-light">Projects</h4>
                            </div>
                            <div className="col-md-6 mx-auto">
                                <button className="btn btn-sm btn-primary float-end" onClick={(e) => openModal(e)}><i className="fa fa-plus" aria-hidden="true"></i> Create a Task</button>
                            </div>
                        </div>
                        <div className="row g-3 py-4">
                            {showProjectList}
                        </div>
                        <div className="row g-3 py-4">
                            {showDisabledProjectList}
                        </div>
                    </div>
                    <div className="col-xl-4 mx-auto">
                        <div className="row">
                            <h4 className="fw-light">Recently Completed</h4>
                        </div>
                        <div className="row py-4">
                            { completedTasks.length > 0 ?
                                <div className="card">
                                        <ul className="list-group list-group-flush">
                                            {completedTasks}
                                        </ul>
                                </div>:
                                <div>No Tasks Available</div> 
                            }
                        </div>
                        <div className="row pt-4">
                            <h4 className="fw-light">In Progress</h4>
                        </div>
                        <div className="row py-4">
                            { inProgressTasks.length > 0 ?
                                <div className="card">
                                        <ul className="list-group list-group-flush">
                                            {inProgressTasks}
                                        </ul>
                                </div>:
                                <div>No Tasks Available</div> 
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} ariaHideApp={false}>
                <div>
                    <form encType='multipart/form-data' onSubmit={submitClientTask} id="CLIENT_TASK_FORM" className="row g-3">
                        <div className="modal-header">
                            <h5 className="modal-title pe-4">Add a Task</h5>
                            <div className="col-md-3">
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    value={projectOptions.filter(function(option) {
                                        return option.value === clientTaskInput.project_id;
                                    })}
                                    isSearchable
                                    onChange={handleProject}
                                    name="project_id"
                                    options={projectOptions}
                                />
                                <small className='text-danger'>{errorList.project_id}</small>
                            </div>
                            <button type="button" className="btn-close" onClick={closeModal}></button>
                        </div>
                        <div className="modal-body row g-3">
                            <div className="col-12">
                                <label htmlFor="task" className="form-label">Task Title</label>
                                <input type="text" name="task" onChange={handleInput} value={clientTaskInput.task} className='form-control' id="task" />
                                <small className='text-danger'>{errorList.task}</small>
                            </div>
                            <div className="col-12">
                                <label htmlFor="description" className="form-label">Description</label>
                                <ReactQuill
                                    theme="snow"
                                    value={clientTaskInput.description}
                                    onChange={handleDescription}
                                    modules={modules}
                                />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="task_priority" className="form-label">Task Priority</label>
                                <select name='task_priority' onChange={handleInput} value={clientTaskInput.task_priority} className='form-select' id='task_priority'>
                                    <option value="">Select Task Priority</option>
                                    {TaskPriority.map((taskPriority, idx) => <option key={idx} value={taskPriority.value}>{taskPriority.label}</option>)}
                                </select>
                                <small className='text-danger'>{errorList.task_priority}</small>
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="ideal_date" className="form-label">Ideal Date ?</label>
                                <input type="date" name="ideal_date" onChange={handleInput} value={clientTaskInput.ideal_date} className='form-control' id='ideal_date' />
                                <small className='text-danger'>{errorList.ideal_date}</small>
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="taskFile" className="form-label">Upload a File</label>
                                <input type='file' name='taskFile' onChange={handleTaskFile} className='form-control' id='taskFile' />
                                <small className='text-danger'>{errorList.taskFile}</small>
                            </div>
                            <div className="col-12">
                                <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-primary px-4 mt-2 float-start'>
                                <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                                Add Task
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </section>
    );
}

export default MyProjects;