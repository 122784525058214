export const LeaveTypes = [
    { value: 'casual', label: 'Casual Leave' },
    { value: 'sick', label: 'Sick Leave' },
    { value: 'half-day', label: 'Half-Day Casual Leave' },
    { value: 'half-day-medical', label: 'Half-Day Sick Leave' },
    { value: 'short', label: 'Short Leave' },
    { value: 'annual', label: 'Annual Leave' },
    { value: 'maternity', label: 'Maternity Leave' },
    { value: 'paternity', label: 'Paternity Leave' },
    { value: 'nopay', label: 'No Pay Leave' },
    // { value: 'study', label: 'Study Leave' },
];