import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

const ViewClient = (props) => {
    const clientSlug = props.match.params.slug;
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [client, setClient] = useState([]);
    const [projects, setProjects] = useState([]);
    const [picture, setPicture] = useState([]);

    if (!localStorage.getItem('auth_token')) {
        history.push('/login');
        swal('Unauthorized', 'Authorization Required', 'error');
    } else if (!props.allowedRoles.includes(localStorage.getItem('auth_role'))) {
        history.push('/403');
    }

    const setFormData = useCallback(async () => {
        axios.get(`/api/view-client/${clientSlug}`).then(res => {
            if (res.data.status === 200) {
                setClient(res.data.client_data.client);
                setProjects(res.data.client_data.projects);
                setPicture({ logo: res.data.client_data.client.logo });
            } else if (res.data.status === 404) {
                swal("Error", res.data.message, "error");
                history.push('/clients');
            }
            setLoading(false);
        });
    }, [history, clientSlug])

    useEffect(() => {
        setFormData();
    }, [setFormData]);
    
    let viewProjectList = "";
    if (loading) {
        return <h4>Loading Project Details...</h4>
    } else {
        if (projects.length > 0) {
            viewProjectList = projects.map((item, idx) => {
                return (
                    <div className="col-md-6 col-xl-4" key={idx}>
                        <div className="card shadow-sm h-100">
                            {item.logo && <img src={`${process.env.REACT_APP_BACKEND_URL}/${item.logo}`} className="bd-placeholder-img card-img-top box-image" alt={item.id} />}
                            <div className="card-body">
                                <h5 className="card-title">{item.name}</h5>
                                <p className="card-text">{item.description}</p>
                                <div className="d-flex justify-content-between">
                                    <Link to={`project/${item.slug}`} className="btn btn-sm btn-outline-primary">View Details</Link>
                                    <Link to={`/add-task/${item.id}`} className="btn btn-sm btn-outline-success">Add Task</Link>
                                    {item.production_url && <a href={item.production_url} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-dark">Production <i className="fa fa-external-link" aria-hidden="true"></i></a>}
                                    {item.staging_url && <a href={item.staging_url} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-secondary">Staging <i className="fa fa-external-link" aria-hidden="true"></i></a>}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        }
    }

    return (
        <>
            <section className="py-5 text-center container">
                <div className="row py-lg-5">
                    <div className="col-lg-6 col-md-8 mx-auto">
                        <h2 className="fw-light">{picture.logo && <img src={`${process.env.REACT_APP_BACKEND_URL}/${picture.logo}`} className="logo" alt={client.id} />} {client.name}</h2>
                        <p className="lead text-muted">{client.description}</p>
                    </div>
                </div>
            </section>
            <div className="pb-5 bg-light">
                <div className="container">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                        {viewProjectList}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ViewClient;