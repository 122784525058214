import React, {useEffect, useState} from 'react';
import { startOfWeek, format } from 'date-fns'
import axios from 'axios';
import DataTable from 'react-data-table-component';
import MinutesToTime from '../../../utils/MinutesToTime';
import ShowMoreText from "react-show-more-text";
import Select from 'react-select';
import { useHistory } from 'react-router-dom';

const timeLogColumns = [
  {
      name: 'Task Name',
      selector: row => row.task_name,
      width: "250px",
  },
  {
    name: 'Project',
    selector: row => row.project,
    width: "150px",
  },
  {
      name: 'Monday',
      selector: row => row.monday,
  },
  {
      name: 'Tuesday',
      selector: row => row.tuesday,
  },
  {
      name: 'Wednesday',
      selector: row => row.wednesday,
  },
  {
      name: 'Thursday',
      selector: row => row.thursday,
  },
  {
      name: 'Friday',
      selector: row => row.friday,
  },
  {
      name: 'Saturday',
      selector: row => row.saturday,
  },
  {
      name: 'Sunday',
      selector: row => row.sunday,
  },
  {
    name: 'Total',
    selector: row => row.total,
},
];

const WeeklyTimeLogReports = (props) => {
  var date = new Date();
  const startDate = format(startOfWeek(date, {weekStartsOn: 1}), 'yyyy-MM-dd' ) ;

  const [weekStartDate, setWeekStartDate] = useState(startDate);
  const [currentWeekStartDate, setCurrentWeekStartDate] = useState('');
  const [weeklyTimeLogs, setWeeklyTimeLogs] = useState([]);
  const [uniqueTasks, setUniqueTasks] = useState([]);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [previousButtonDisable, setPreviousButtonDisable] = useState(true);
  const [showWeek, setShowWeek] = useState('');
  // const [loading, setLoading] = useState();
  const [projectInput, setProjectInput] = useState({
    project_id: 'all',
    user_id: ''
  });
  const [projectList, setProjectList] = useState([]);
  const [userList, setUserList] = useState([]);
  const history = useHistory();

  useEffect(() => {
    // setShowWeek('No Data to Display');
    // setLoading(true);
    setCurrentWeekStartDate(weekStartDate);
    if (localStorage.getItem('auth_designation') !== 'qa-intern') {
      axios.get(`/api/project-time-report`).then(res => {
        if (res.data.status === 200) {
          setProjectList(res.data.projects);
          setUserList(res.data.users);
        }
          // setLoading(false);
        });
    } else {
      history.push('/admin/dashboard');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(projectInput);


  const generateReport = (e) => {
    e.preventDefault();
    setShowWeek('Loading...');
    setButtonDisable(true);
    setPreviousButtonDisable(false);

    axios.get('/sanctum/csrf-cookie').then(response => {
      axios.get(`/api/weekly-time-log-repot/${currentWeekStartDate}/${projectInput.project_id}/${projectInput.user_id}`).then(res => {
        if (res.data.status === 200) {
          let tempTasksArray = [];
          res.data.weeklyTimeLogs.forEach(timelog => {
            tempTasksArray.push(timelog.task);
          });
          setWeeklyTimeLogs(res.data.weeklyTimeLogs);
          console.log(res.data.weeklyTimeLogs);

          let uniqueTasksObject = [];
          let tempArray = [];
          tempTasksArray.forEach(element => {
            const val = element.id;
            if (!tempArray.includes(val)) {
              tempArray.push(val);
              uniqueTasksObject.push(element);
            }
          });
          setUniqueTasks(uniqueTasksObject);
          setWeekStartDate(currentWeekStartDate);
          setShowWeek(`From ${currentWeekStartDate} To ${weeklyDates(currentWeekStartDate,6)}`);
        }
      });
    });

  }

  const handleProject = (value) => {
    setButtonDisable(true);
    setPreviousButtonDisable(true);
    setProjectInput({ ...projectInput, project_id: value });
  }

  const handleUser = (value) => {
    setButtonDisable(true);
    setPreviousButtonDisable(true);
    setProjectInput({ ...projectInput, user_id: value });
  }

  const countTaskTime = (date, taskId) => {
    let count = 0;
    let workTime;
    weeklyTimeLogs.forEach(element => {
      if (element.date === date && element.task_id === taskId) {
        count += parseInt(element.spent_time);
      }
    });

    if (count === 0) {
      workTime = "-";
    } else {
      workTime = MinutesToTime(count);
    }

    return workTime;
  }

  const countDailyTaskTime = (date) => {
    let count = 0;
    let workTime;
    weeklyTimeLogs.forEach(element => {
      if (element.date === date) {
        count += parseInt(element.spent_time);
      }
    });

    if (count === 0) {
      workTime = "-";
    } else {
      workTime = MinutesToTime(count);
    }

    return workTime;
  }

  const countSingleTaskWeeklyTotalTime = (taskId) => {
    let count = 0;
    let workTime;
    weeklyTimeLogs.forEach(element => {
      if (element.task_id === taskId) {
        count += parseInt(element.spent_time);
      }
    });

    if (count === 0) {
      workTime = "-";
    } else {
      workTime = MinutesToTime(count);
    }

    return workTime;
  }

  const countWeeklyTotalTime = () => {
    let count = 0;
    let workTime;
    weeklyTimeLogs.forEach(element => {
      count += parseInt(element.spent_time);
    });

    if (count === 0) {
      workTime = "-";
    } else {
      workTime = MinutesToTime(count);
    }

    return workTime;
  }

  const weeklyDates = (startDate, increment) => {
    let tempDate = new Date(startDate);
    const day = 60 * 60 * 24 * 1000;
    const endDate = new Date(tempDate.getTime() + day*increment);
    return format(endDate, 'yyyy-MM-dd' )
  }

  const previousWeek = () => {
    setShowWeek('Loading...');
    const newDate = weeklyDates(weekStartDate, -7);
    console.log(newDate);
    axios.get(`/api/weekly-time-log-repot/${newDate}/${projectInput.project_id}/${projectInput.user_id}`).then(res => {
      if (res.data.status === 200) {
        let tempTasksArray = [];
        res.data.weeklyTimeLogs.forEach(timelog => {
          tempTasksArray.push(timelog.task);
        });
        setWeeklyTimeLogs(res.data.weeklyTimeLogs)

        let uniqueTasksObject = [];
        let tempArray = [];
        tempTasksArray.forEach(element => {
          const val = element.id;
          if (!tempArray.includes(val)) {
            tempArray.push(val);
            uniqueTasksObject.push(element);
          }
        });

        setUniqueTasks(uniqueTasksObject);
        setWeekStartDate(newDate);
        setButtonDisable(false);
        setShowWeek(`From ${newDate} To ${weeklyDates(newDate,6)}`);
      }
    });
  }

  const currentWeek = () => {
    setShowWeek('Loading...');
    const newDate = currentWeekStartDate;
    axios.get(`/api/weekly-time-log-repot/${newDate}/${projectInput.project_id}/${projectInput.user_id}`).then(res => {
      if (res.data.status === 200) {
        let tempTasksArray = [];
        res.data.weeklyTimeLogs.forEach(timelog => {
          tempTasksArray.push(timelog.task);
        });
        setWeeklyTimeLogs(res.data.weeklyTimeLogs)

        let uniqueTasksObject = [];
        let tempArray = [];
        tempTasksArray.forEach(element => {
          const val = element.id;
          if (!tempArray.includes(val)) {
            tempArray.push(val);
            uniqueTasksObject.push(element);
          }
        });

        setUniqueTasks(uniqueTasksObject);
        setWeekStartDate(newDate);
        setButtonDisable(true);
        setShowWeek(`From ${newDate} To ${weeklyDates(newDate,6)}`);
      }
    });
  }

  const nextWeek = () => {
    setShowWeek('Loading...');
    const newDate = weeklyDates(weekStartDate, 7);
    axios.get(`/api/weekly-time-log-repot/${newDate}/${projectInput.project_id}/${projectInput.user_id}`).then(res => {
      if (res.data.status === 200) {
        let tempTasksArray = [];
        res.data.weeklyTimeLogs.forEach(timelog => {
          tempTasksArray.push(timelog.task);
        });
        setWeeklyTimeLogs(res.data.weeklyTimeLogs)

        let uniqueTasksObject = [];
        let tempArray = [];
        tempTasksArray.forEach(element => {
          const val = element.id;
          if (!tempArray.includes(val)) {
            tempArray.push(val);
            uniqueTasksObject.push(element);
          }
        });

        setUniqueTasks(uniqueTasksObject);
        setWeekStartDate(newDate);
        setShowWeek(`From ${newDate} To ${weeklyDates(newDate,6)}`);
        if (currentWeekStartDate === newDate) {
          setButtonDisable(true)
        }
      }
    });
  }

  let uniqueTaskData = [];

  if (timeLogColumns.length > 0) {
    uniqueTaskData = uniqueTasks.map(item => {
        console.log(item);
        return ({
            task_name :   <ShowMoreText
                            lines={1}
                            more="Show more"
                            less="Show less"
                            className="content-css"
                            anchorClass="show-more-less-clickable"
                            expanded={false}
                            width={200}
                            truncatedEndingComponent={"... "}
                        >{item.task}
                        </ShowMoreText>,
            project : item && item.project.name,
            monday :  item.id && countTaskTime(weekStartDate,item.id),
            tuesday :  item.id && countTaskTime(weeklyDates(weekStartDate,1),item.id),
            wednesday :  item.id && countTaskTime(weeklyDates(weekStartDate,2),item.id),
            thursday : item.id && countTaskTime(weeklyDates(weekStartDate,3),item.id),
            friday : item.id && countTaskTime(weeklyDates(weekStartDate,4),item.id),
            saturday : item.id && countTaskTime(weeklyDates(weekStartDate,5),item.id),
            sunday : item.id && countTaskTime(weeklyDates(weekStartDate,6),item.id),
            total : item.id && countSingleTaskWeeklyTotalTime(item.id),
        });
    });
  }

  const tempTotalTimeObjectPush = {
    task_name :  "Total",
    project : "",
    monday :  countDailyTaskTime(weekStartDate),
    tuesday :  countDailyTaskTime(weeklyDates(weekStartDate,1)),
    wednesday :  countDailyTaskTime(weeklyDates(weekStartDate,2)),
    thursday : countDailyTaskTime(weeklyDates(weekStartDate,3)),
    friday : countDailyTaskTime(weeklyDates(weekStartDate,4)),
    saturday : countDailyTaskTime(weeklyDates(weekStartDate,5)),
    sunday : countDailyTaskTime(weeklyDates(weekStartDate,6)),
    total : countWeeklyTotalTime(),
  }

  uniqueTaskData.push(tempTotalTimeObjectPush)

  return (
    <div className="weekly-tasks-wrp">
      <div className='px-3'>
        <h3 className='py-3'>Weekly Tasks</h3>
        <div className='card-header'>
          <div className="controls-wrp pb-5">
            <form encType='multipart/form-data' onSubmit={generateReport} id="REPORT_FORM" className="row gx-3 gy-2 align-items-center">
              <div className='row pt-3'>
                <div className="col-12 col-md-6 col-lg-3">
                  <label htmlFor="project_id" className="visually-hidden">Select Project</label>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={projectInput.project_id}
                    isSearchable
                    isClearable
                    onChange={(options) =>
                      !options ? handleProject("all") : handleProject(options.value)
                    }
                    name="project_id"
                    id="project_id"
                    placeholder="All Projects"
                    options={
                      projectList.map(item => {
                        return ({
                          value: item.id,
                          label: item.name
                        })
                      })
                    }
                  />
                  {/* <small className='text-danger'>{errorList.project_id}</small> */}
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <label htmlFor="user_id" className="visually-hidden">Select User</label>
                  <Select
                      className="basic-single"
                      classNamePrefix="select"
                      required
                      defaultValue={projectInput.user_id}
                      isSearchable
                      isClearable
                      onChange={(options) =>
                        !options ? handleUser("all") : handleUser(options.value)
                      }
                      name="user_id"
                      id="user_id"
                      placeholder="Select User..."
                      options={
                          userList.map(item => {
                              return (
                                  {
                                      value: item.id,
                                      label: item.name
                                  }
                              )
                          })
                      }
                  />
                  {/* <small className='text-danger'>{errorList.user_id}</small> */}
                </div>

                <div className="col-2">
                  <button type='submit' className='btn btn-dark'>
                    Generate Report
                  </button>
                </div>
              </div>
            </form>

          </div>
          <div className="heading-wrp">
            <h4>{showWeek}</h4>
            <div className="button-wrp">
              <button disabled={previousButtonDisable} className="btn btn-primary btn-sm button-1 change-week-button" onClick={previousWeek}>
                Previous Week
              </button>
              <button disabled={buttonDisable} className="btn btn-primary btn-sm button-2 change-week-button" onClick={currentWeek}>
                Current Week
              </button>
              <button disabled={buttonDisable} className="btn btn-primary btn-sm button-3 change-week-button" onClick={nextWeek}>
                Next Week
              </button>
            </div>
          </div>
        </div>
        <div className='weekly-tasks-table'>
            <DataTable
                columns={timeLogColumns}
                data={uniqueTaskData}
            />
        </div>
    </div>
    </div>
  );
}

export default WeeklyTimeLogReports;