import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import DataTable from 'react-data-table-component';
import ShowMoreText from "react-show-more-text";
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { modules } from '../../../utils/EditorToolbar';
import { TaskStatuses } from '../../../utils/TaskStatuses';
import { TaskPriority } from '../../../utils/TaskPriority';
import { Currencies } from '../../../utils/Currencies';
import { QuotationStatuses } from '../../../utils/QuotationStatuses';
import { InvoiceStatuses } from '../../../utils/InvoiceStatuses';
import MinutesToTime from '../../../utils/MinutesToTime';

const taskColumns = [
    {
        name: 'Task',
        selector: row => row.task,
        sortable: true,
    },
    {
        name: 'Assignee',
        selector: row => row.assignee,
        sortable: true,
    },
    {
        name: 'Assigned By',
        selector: row => row.assigned_by,
        sortable: true,
    },
    {
        name: 'Due Date',
        selector: row => row.due_date,
        sortable: true,
    },
    {
        name: 'Estimate',
        selector: row => row.estimate,
        sortable: true,
    },
    {
        name: 'Completed Date',
        selector: row => row.completed_date,
        sortable: true,
    },
    {
        name: 'Total Spent',
        selector: row => row.total_spent_time,
        sortable: true,
    },
    {
        name: 'Task Status',
        selector: row => row.task_status,
        sortable: true,
    },
    {
        name: 'Task Priority',
        selector: row => row.task_priority,
        sortable: true,
    },
    {
        name: 'Actions',
        selector: row => row.actions,
    }
];

let taskData = [];

const EditClientTask = (props) => {
    const clientTaskId = props.match.params.id;
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [clientUserList, setClientUserList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [allCheckbox, setCheckboxes] = useState([]);
    const [taskFile, setTaskFile] = useState([]);
    const [errorList, setError] = useState([]);
    const [pending, setPending] = useState(false);
    const [taskList, setTaskList] = useState([]);
    const [spent, setSpent] = useState({
        spent_hours: 0,
        spent_minutes: 0,
    });
    const [clientTaskInput, setClientTask] = useState({
        project_id: '',
        assigned_by: '',
        task: '',
        description: '',
        comments: '',
        ideal_date: '',
        completed_date: '',
        time_spent: '',
        task_status: '',
        task_priority: '',
        quotation_amount: '',
        quotation_currency: '',
        quotation_status: '',
        invoice_status: ''
    });

    const setFormData = useCallback(async () => {
        taskData = [];
        axios.get(`/api/edit-client-task/${clientTaskId}`).then(res => {
            if (res.data.status === 200) {
                setClientTask(res.data.clientTask);
                setTaskFile({ file: res.data.clientTask.file });
                setCheckboxes(res.data.clientTask);
                setTaskList(res.data.tasks);
                let spentTime = res.data.clientTask.time_spent;
                let spentObject = {
                    spent_hours : Math.floor(spentTime/60),
                    spent_minutes : spentTime - (Math.floor(spentTime/60))*60
                }
                setSpent(spentObject);
            } else if (res.data.status === 404) {
                swal("Error", res.data.message, "error");
                history.push('/admin/view-tasks');
            }
            setLoading(false);
        });
    }, [history, clientTaskId])

    useEffect(() => {
        axios.get(`/api/all-projects`).then(res => {
            if (res.data.status === 200) {
                setProjectList(res.data.projects);
            }
        });
        axios.get(`/api/all-client-users`).then(res => {
            if (res.data.status === 200) {
                setClientUserList(res.data.users);
            }
        });
        setFormData();
    }, [setFormData]);

    const deleteTask = (e, id) => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Record",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(`/api/delete-task/${id}`).then(res => {
                    if (res.data.status === 200) {
                        swal(res.data.message, {
                            icon: "success",
                        });
                        setFormData();
                    } else if (res.data.status === 404) {
                        swal("Error", res.data.message, "error");
                    } else if (res.data.status === 405) {
                        swal("Error", res.data.message, "error");
                    }
                });
            }
        });
    }

    const handleInput = (e) => {
        e.persist();
        setClientTask({ ...clientTaskInput, [e.target.name]: e.target.value });
    }

    const handleSpent = (e) => {
        e.persist();
        setSpent({ ...spent, [e.target.name]: e.target.value });
    }

    const handleDescription = (value) => {
        setClientTask({ ...clientTaskInput, description: value });
    }

    const handleComments = (value) => {
        setClientTask({ ...clientTaskInput, comments: value });
    }

    const handleTaskFile = (e) => {
        e.persist();
        setTaskFile({ file: e.target.files[0] });
    }

    const handleCheckbox = (e) => {
        e.persist();
        setCheckboxes({ ...allCheckbox, [e.target.name]: e.target.checked });
    }

    const handleProject = (project) => {
        setClientTask({ ...clientTaskInput, project_id: project.value });
    }

    const handleClientUser = (clientUser) => {
        setClientTask({ ...clientTaskInput, assigned_by: clientUser.value });
    }

    const updateTask = (e) => {
        e.preventDefault();
        setPending(true);

        axios.get('/sanctum/csrf-cookie').then(response => {
            let hours = spent.spent_hours ? parseInt(spent.spent_hours) : 0;
            let minutes = spent.spent_minutes ? parseInt(spent.spent_minutes) : 0;
            let spentTime = hours*60 + minutes;
            const formData = new FormData();
            formData.append('project_id', clientTaskInput.project_id);
            formData.append('assigned_by', clientTaskInput.assigned_by);
            formData.append('task', clientTaskInput.task);
            formData.append('description', clientTaskInput.description);
            formData.append('comments', clientTaskInput.comments);
            formData.append('ideal_date', clientTaskInput.ideal_date);
            formData.append('completed_date', clientTaskInput.completed_date);
            formData.append('time_spent', spentTime);
            formData.append('file', taskFile.file);
            formData.append('task_status', clientTaskInput.task_status);
            formData.append('task_priority', clientTaskInput.task_priority);
            formData.append('status', allCheckbox.status ? '1' : '0');
            formData.append('quotation_amount', clientTaskInput.quotation_amount);
            formData.append('quotation_currency', clientTaskInput.quotation_currency);
            formData.append('quotation_status', clientTaskInput.quotation_status);
            formData.append('invoice_status', clientTaskInput.invoice_status);
            formData.append('_method', 'PUT');

            axios.post(`/api/update-client-task/${clientTaskId}`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
                if (res.data.status === 200) {
                    swal("Success", res.data.message, "success");
                    setError([]);
                    setFormData();
                    setPending(false);
                } else if (res.data.status === 422) {
                    swal("All fields are mandatory", "", "error");
                    setError(res.data.errors);
                    setPending(false);
                } else if (res.data.status === 404) {
                    swal("Error", res.data.message, "error");
                    history.push('/admin/view-client-tasks');
                    setPending(false);
                }
            });
        });
    }

    const projectOptions = projectList.map(item => {
        return ({
            value: item.id,
            label: item.name+' ('+item.client.name+')'
        });
    });

    const clientUserOptions = clientUserList.map(item => {
        return ({
            value: item.id,
            label: item.name+' ('+item.client.name+')'
        });
    });

    if (loading) {
        return <h4>Loading Edit Client Task...</h4>
    } else {
        if (taskList.length > 0) {
            taskData = taskList.map(item => {
                return ({
                    task :  <ShowMoreText
                                lines={1}
                                more="Show more"
                                less="Show less"
                                className="content-css"
                                anchorClass="show-more-less-clickable"
                                expanded={false}
                                width={200}
                                truncatedEndingComponent={"... "}
                            >{item.task}
                            </ShowMoreText>,
                    assignee :   item.assignee && item.assignee.name,
                    assigned_by :   item.assigned_by ? item.assigned_by.name : 'Self Assigned',
                    due_date :  item.due_date,
                    estimate :  MinutesToTime(item.estimate_time),
                    completed_date  :   item.completed_date,
                    total_spent_time    :   MinutesToTime(item.total_spent_time),
                    task_status :    TaskStatuses.map((taskStatus, idx) => item.task_status === taskStatus.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskStatus.value}`}>{taskStatus.label}</span> : ''),
                    task_priority :    TaskPriority.map((taskPriority, idx) => item.task_priority === taskPriority.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskPriority.value}`}>{taskPriority.label}</span> : ''),
                    actions :   <div className="d-flex">
                        <Link to={`/admin/view-task/${item.id}`} className="btn btn-outline-secondary btn-sm"><i className="fa fa-eye"></i></Link>
                        <button type='button' onClick={(e) => deleteTask(e, item.id)} className='btn btn-outline-danger btn-sm ms-1'><i className="fa fa-trash"></i></button>
                    </div>,
                });
            });
        }
    }

    return (
        <div className='px-4'>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h4>Edit Client Task
                        <Link to="/admin/view-client-tasks" className='btn btn-primary btn-sm float-end'>BACK</Link>
                    </h4>
                </div>
                <div className='card-body'>
                    <form encType='multipart/form-data' onSubmit={updateTask} id="CLIENT_TASK_FORM" className="row g-3">
                        <div className="col-md-3">
                            <label htmlFor="project_id" className="form-label">Select Project</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                value={projectOptions.filter(function(option) {
                                    return option.value === clientTaskInput.project_id;
                                })}
                                isSearchable
                                onChange={handleProject}
                                name="project_id"
                                options={projectOptions}
                            />
                            <small className='text-danger'>{errorList.project_id}</small>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="assigned_by" className="form-label">Select Client User</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                value={clientUserOptions.filter(function(option) {
                                    return option.value === clientTaskInput.assigned_by;
                                })}
                                isSearchable
                                onChange={handleClientUser}
                                name="assigned_by"
                                options={clientUserOptions}
                            />
                            <small className='text-danger'>{errorList.assigned_by}</small>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="task" className="form-label">Task</label>
                            <input type="text" name="task" onChange={handleInput} value={clientTaskInput.task} className='form-control' id='task' />
                            <small className='text-danger'>{errorList.task}</small>
                        </div>
                        <div className="col-12">
                            <label htmlFor="description" className="form-label">Description</label>
                            <ReactQuill
                                theme="snow"
                                value={clientTaskInput.description}
                                onChange={handleDescription}
                                modules={modules}
                            />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="ideal_date" className="form-label">Ideal Date</label>
                            <input type="date" name="ideal_date" onChange={handleInput} value={clientTaskInput.ideal_date} className='form-control' id='ideal_date' />
                            <small className='text-danger'>{errorList.ideal_date}</small>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="task_status" className="form-label">Time Spent (HH:MM)</label>
                            <div className="input-group">
                                <input type="number" name="spent_hours" onChange={handleSpent} value={spent.spent_hours} min="0" max="999" step="1" id='spent_time_hours' className="form-control text-end" placeholder="Hours" aria-label=""/>
                                <span className="input-group-text">:</span>
                                <input type="number" name="spent_minutes" onChange={handleSpent} value={spent.spent_minutes} min="0" max="59" step="1" id='spent_time_minutes' className="form-control" placeholder="Minutes" aria-label=""/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="completed_date" className="form-label">Completed Date</label>
                            <input type="date" name="completed_date" onChange={handleInput} value={clientTaskInput.completed_date} className='form-control' id='completed_date' />
                            <small className='text-danger'>{errorList.completed_date}</small>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="projectask_statust_id" className="form-label">Task Status</label>
                            <select name='task_status' onChange={handleInput} value={clientTaskInput.task_status} className='form-select' id='task_status'>
                                <option value="">Select Task Status</option>
                                {TaskStatuses.map((taskStatus, idx) => <option key={idx} value={taskStatus.value}>{taskStatus.label}</option>)}
                            </select>
                            <small className='text-danger'>{errorList.task_status}</small>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="task_priority" className="form-label">Task Priority</label>
                            <select name='task_priority' onChange={handleInput} value={clientTaskInput.task_priority} className='form-select' id='task_priority'>
                                <option value="">Select Task Priority</option>
                                {TaskPriority.map((taskPriority, idx) => <option key={idx} value={taskPriority.value}>{taskPriority.label}</option>)}
                            </select>
                            <small className='text-danger'>{errorList.task_priority}</small>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="file" className="form-label">Upload File</label>
                            <input type='file' name='file' onChange={handleTaskFile} className='form-control' id='file' />
                            <small className='text-danger'>{errorList.file}</small>
                            {clientTaskInput.file && <div><a href={`${process.env.REACT_APP_BACKEND_URL}/${clientTaskInput.file}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-dark mt-2">View File <i className="fa fa-external-link" aria-hidden="true"></i></a></div>}
                        </div>
                        <div className="col-12">
                            <div className="form-check">
                                <input type="checkbox" name='status' onChange={handleCheckbox} defaultChecked={allCheckbox.status === 1 ? true : false} className="form-check-input" id="status" />
                                <label htmlFor="status" className="form-check-label">Status (checked=Hidden)</label>
                            </div>
                        </div>
                        <div className="col-12">
                            <label htmlFor="comments" className="form-label">Comments</label>
                            <ReactQuill
                                theme="snow"
                                value={clientTaskInput.comments}
                                onChange={handleComments}
                                modules={modules}
                            />
                        </div>
                        <h4 className="card-title mt-5">Quotation</h4>
                        <div className="col-md-3">
                            <label htmlFor="quotation_amount" className="form-label">Quotation Amount</label>
                            <input type="number" name="quotation_amount" onChange={handleInput} value={clientTaskInput.quotation_amount} className='form-control' id='quotation_amount' step="0.1" />
                            <small className='text-danger'>{errorList.quotation_amount}</small>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="quotation_currency" className="form-label">Quotation Currency</label>
                            <select name='quotation_currency' onChange={handleInput} value={clientTaskInput.quotation_currency} className='form-select' id='quotation_currency'>
                                <option value="">Select Currency</option>
                                {Currencies.map((currency, idx) => <option key={idx} value={currency.value}>{currency.label}</option>)}
                            </select>
                            <small className='text-danger'>{errorList.quotation_currency}</small>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="quotation_status" className="form-label">Quotation Status</label>
                            <select name='quotation_status' onChange={handleInput} value={clientTaskInput.quotation_status} className='form-select' id='quotation_status'>
                                <option value="">Select Status</option>
                                {QuotationStatuses.map((quotationStatus, idx) => <option key={idx} value={quotationStatus.value}>{quotationStatus.label}</option>)}
                            </select>
                            <small className='text-danger'>{errorList.quotation_status}</small>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="invoice_status" className="form-label">Invoice Status</label>
                            <select name='invoice_status' onChange={handleInput} value={clientTaskInput.invoice_status} className='form-select' id='invoice_status'>
                                <option value="">Select Status</option>
                                {InvoiceStatuses.map((invoiceStatus, idx) => <option key={idx} value={invoiceStatus.value}>{invoiceStatus.label}</option>)}
                            </select>
                            <small className='text-danger'>{errorList.invoice_status}</small>
                        </div>
                        <div className="col-12">
                            <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-primary px-4 float-end'>
                                <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h5>Developer Task List
                        <Link to={`/admin/add-task-by-client-task/${clientTaskId}`} className="btn btn-primary btn-sm float-end">Add Task</Link>
                    </h5>
                </div>
                <div className='card-body'>
                    <DataTable
                        columns={taskColumns}
                        data={taskData}
                        pagination
                    />
                </div>
            </div>
        </div>
    );
}

export default EditClientTask;