import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Avatar from 'react-avatar';

const Dashboard = () => {
    const [loading, setLoading] = useState(true);
    const [mondayLeaves, setMondayLeaves] = useState([]);
    const [tuesdayLeaves, setTuesdayLeaves] = useState([]);
    const [wednesdayLeaves, setWdnesdayLeaves] = useState([]);
    const [thursdayLeaves, setThursdayLeaves] = useState([]);
    const [fridayLeaves, setFridayLeaves] = useState([]);

    useEffect(() => {
        let isMounted = true;
        axios.get(`/api/get-weekly-leaves`).then(res => {
            if (isMounted) {
                if (res.data.status === 200) {
                    setMondayLeaves(res.data.monday);
                    setTuesdayLeaves(res.data.tuesday);
                    setWdnesdayLeaves(res.data.wednesday);
                    setThursdayLeaves(res.data.thursday);
                    setFridayLeaves(res.data.friday);
                    setLoading(false);
                }
            }
        });
        return () => {
            isMounted = false
        }
    }, []);

    if (loading) {
        return <h4>Loading Dashboard...</h4>
    }

    return (
        <>
            <header className="d-none d-lg-block pt-4">
                <div className="container px-lg-5">
                    <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
                        <div className="m-4 m-lg-5">
                            <h2 className="display-5 fw-bold">Welcome to Bees Admin Panel</h2>
                            <p className="fs-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent justo mauris, fringilla sed nunc eget, efficitur porta nibh. Maecenas massa sem, ullamcorper egestas purus eget, congue fermentum nisl.</p>
                        </div>
                    </div>
                </div>
            </header>
            <section className="pt-4">
                <div className="container px-lg-5">
                    <div className="row d-flex justify-content-center pb-5">
                        <div className="col-12 mb-5 text-center">
                            <h2>Current Week Leaves</h2>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-5">
                            <div className="card">
                                <div className="card-header">
                                    Monday
                                </div>
                                <ul className="list-group list-group-flush">
                                    {
                                        (mondayLeaves.length > 0) ?
                                            mondayLeaves.map(leave => (
                                                <li className="list-group-item text-capitalize">
                                                    <Avatar name={leave.user.name} src={leave.user.profile_picture !== '' ? `${process.env.REACT_APP_BACKEND_URL}/${leave.user.profile_picture}` : ''} size="60" round={true} /> {leave.type}
                                                </li>
                                            ))
                                        :
                                        <li className="list-group-item text-capitalize">No Leaves</li>
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-5">
                            <div className="card">
                                <div className="card-header">
                                    Tuesday
                                </div>
                                <ul className="list-group list-group-flush">
                                    {
                                        (tuesdayLeaves.length > 0) ?
                                            tuesdayLeaves.map(leave => (
                                                <li className="list-group-item text-capitalize">
                                                    <Avatar name={leave.user.name} src={leave.user.profile_picture !== '' ? `${process.env.REACT_APP_BACKEND_URL}/${leave.user.profile_picture}` : ''} size="60" round={true} /> {leave.type}
                                                </li>
                                            ))
                                        :
                                        <li className="list-group-item text-capitalize">No Leaves</li>
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-5">
                            <div className="card">
                                <div className="card-header">
                                    Wednesday
                                </div>
                                <ul className="list-group list-group-flush">
                                    {
                                        (wednesdayLeaves.length > 0) ?
                                            wednesdayLeaves.map(leave => (
                                                <li className="list-group-item text-capitalize">
                                                    <Avatar name={leave.user.name} src={leave.user.profile_picture !== '' ? `${process.env.REACT_APP_BACKEND_URL}/${leave.user.profile_picture}` : ''} size="60" round={true} /> {leave.type}
                                                </li>
                                            ))
                                        :
                                        <li className="list-group-item text-capitalize">No Leaves</li>
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-5">
                            <div className="card">
                                <div className="card-header">
                                    Thursday
                                </div>
                                <ul className="list-group list-group-flush">
                                    {
                                        (thursdayLeaves.length > 0) ?
                                            thursdayLeaves.map(leave => (
                                                <li className="list-group-item text-capitalize">
                                                    <Avatar name={leave.user.name} src={leave.user.profile_picture !== '' ? `${process.env.REACT_APP_BACKEND_URL}/${leave.user.profile_picture}` : ''} size="60" round={true} /> {leave.type}
                                                </li>
                                            ))
                                        :
                                        <li className="list-group-item text-capitalize">No Leaves</li>
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-5">
                            <div className="card">
                                <div className="card-header">
                                    Friday
                                </div>
                                <ul className="list-group list-group-flush">
                                    {
                                        (fridayLeaves.length > 0) ?
                                            fridayLeaves.map(leave => (
                                                <li className="list-group-item text-capitalize">
                                                    <Avatar name={leave.user.name} src={leave.user.profile_picture !== '' ? `${process.env.REACT_APP_BACKEND_URL}/${leave.user.profile_picture}` : ''} size="60" round={true} /> {leave.type}
                                                </li>
                                            ))
                                        :
                                        <li className="list-group-item text-capitalize">No Leaves</li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row gx-lg-5">
                        <div className="col-lg-6 col-xxl-4 mb-5">
                            <div className="card bg-light border-0 h-100">
                                <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                    <Link className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4" to="/admin/view-users"><i className="bi bi-card-checklist"></i></Link>
                                    <h2 className="fs-4 fw-bold">Users</h2>
                                    <p className="mb-0">Click here to view users.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xxl-4 mb-5">
                            <div className="card bg-light border-0 h-100">
                                <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                    <Link className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4" to="/admin/view-clients"><i className="bi bi-card-checklist"></i></Link>
                                    <h2 className="fs-4 fw-bold">Clients</h2>
                                    <p className="mb-0">Click here to view clients.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xxl-4 mb-5">
                            <div className="card bg-light border-0 h-100">
                                <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                    <Link className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4" to="/admin/view-projects"><i className="bi bi-card-checklist"></i></Link>
                                    <h2 className="fs-4 fw-bold">Projects</h2>
                                    <p className="mb-0">Click here to view projects.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xxl-4 mb-5">
                            <div className="card bg-light border-0 h-100">
                                <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                    <Link className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4" to="/admin/view-tasks"><i className="bi bi-card-checklist"></i></Link>
                                    <h2 className="fs-4 fw-bold">Tasks</h2>
                                    <p className="mb-0">Click here to view tasks.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xxl-4 mb-5">
                            <div className="card bg-light border-0 h-100">
                                <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                    <Link className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4" to="/admin/view-client-tasks"><i className="bi bi-card-checklist"></i></Link>
                                    <h2 className="fs-4 fw-bold">Client Tasks</h2>
                                    <p className="mb-0">Click here to view client tasks.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xxl-4 mb-5">
                            <div className="card bg-light border-0 h-100">
                                <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                    <Link className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4" to="/admin/view-leaves"><i className="bi bi-card-checklist"></i></Link>
                                    <h2 className="fs-4 fw-bold">Leaves</h2>
                                    <p className="mb-0">Click here to view leaves.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xxl-4 mb-5">
                            <div className="card bg-light border-0 h-100">
                                <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                    <Link className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4" to="/admin/resource-allocation-report"><i className="bi bi-card-checklist"></i></Link>
                                    <h2 className="fs-4 fw-bold">Resource Allocation</h2>
                                    <p className="mb-0">Click here to view resource allocation reports.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xxl-4 mb-5">
                            <div className="card bg-light border-0 h-100">
                                <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                    <Link className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4" to="/admin/project-time-report"><i className="bi bi-card-checklist"></i></Link>
                                    <h2 className="fs-4 fw-bold">Project Time Report</h2>
                                    <p className="mb-0">Click here to view project time reports.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Dashboard;