import React, {useEffect, useState} from 'react';
import { startOfWeek, format } from 'date-fns'
import axios from 'axios';
import DataTable from 'react-data-table-component';
import MinutesToTime from '../../../utils/MinutesToTime';
import ShowMoreText from "react-show-more-text";

const timeLogColumns = [
  {
      name: 'Task Name',
      selector: row => row.task_name,
      width: "250px",
  },
  {
    name: 'Project',
    selector: row => row.project,
    width: "150px",
  },
  {
      name: 'Monday',
      selector: row => row.monday,
  },
  {
      name: 'Tuesday',
      selector: row => row.tuesday,
  },
  {
      name: 'Wednesday',
      selector: row => row.wednesday,
  },
  {
      name: 'Thursday',
      selector: row => row.thursday,
  },
  {
      name: 'Friday',
      selector: row => row.friday,
  },
  {
      name: 'Saturday',
      selector: row => row.saturday,
  },
  {
      name: 'Sunday',
      selector: row => row.sunday,
  },
  {
    name: 'Total',
    selector: row => row.total,
},
];

const ViewWeeklyTimeLogs = (props) => {
  var date = new Date();
  const startDate = format(startOfWeek(date, {weekStartsOn: 1}), 'yyyy-MM-dd' ) ;

  const [weekStartDate, setWeekStartDate] = useState(startDate);
  const [currentWeekStartDate, setCurrentWeekStartDate] = useState('');
  const [weeklyTimeLogs, setWeeklyTimeLogs] = useState([]);
  const [uniqueTasks, setUniqueTasks] = useState([]);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [showWeek, setShowWeek] = useState('');
  // const [loading, setLoading] = useState();

  useEffect(() => {
    let isMounted = true;
    setShowWeek('Loading...');
    // setLoading(true);
    axios.get(`/api/get-weekly-time-logs/${weekStartDate}`).then(res => {
      if (isMounted) {
        if (res.data.status === 200) {
          let tempTasksArray = [];
          res.data.weeklyTimeLogs.forEach(timelog => {
            tempTasksArray.push(timelog.task);
          });
          setWeeklyTimeLogs(res.data.weeklyTimeLogs)

          let uniqueTasksObject = [];
          let tempArray = [];
          tempTasksArray.forEach(element => {
            const val = element.id;
            if (!tempArray.includes(val)) {
              tempArray.push(val);
              uniqueTasksObject.push(element);
            }
          });
          setUniqueTasks(uniqueTasksObject);
          setCurrentWeekStartDate(weekStartDate);
          setShowWeek(`( From ${weekStartDate} To ${weeklyDates(weekStartDate,6)} )`);
          // setLoading(false);
        }
      }
    });
    return () => {
      isMounted = false
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const countTaskTime = (date, taskId) => {
    let count = 0;
    let workTime;
    weeklyTimeLogs.forEach(element => {
      if (element.date === date && element.task_id === taskId) {
        count += parseInt(element.spent_time);
      }
    });

    if (count === 0) {
      workTime = "-";
    } else {
      workTime = MinutesToTime(count);
    }

    return workTime;
  }

  const countDailyTaskTime = (date) => {
    let count = 0;
    let workTime;
    weeklyTimeLogs.forEach(element => {
      if (element.date === date) {
        count += parseInt(element.spent_time);
      }
    });

    if (count === 0) {
      workTime = "-";
    } else {
      workTime = MinutesToTime(count);
    }

    return workTime;
  }

  const countSingleTaskWeeklyTotalTime = (taskId) => {
    let count = 0;
    let workTime;
    weeklyTimeLogs.forEach(element => {
      if (element.task_id === taskId) {
        count += parseInt(element.spent_time);
      }
    });

    if (count === 0) {
      workTime = "-";
    } else {
      workTime = MinutesToTime(count);
    }

    return workTime;
  }

  const countWeeklyTotalTime = () => {
    let count = 0;
    let workTime;
    weeklyTimeLogs.forEach(element => {
      count += parseInt(element.spent_time);
    });

    if (count === 0) {
      workTime = "-";
    } else {
      workTime = MinutesToTime(count);
    }

    return workTime;
  }

  const weeklyDates = (startDate, increment) => {
    let tempDate = new Date(startDate);
    const day = 60 * 60 * 24 * 1000;
    const endDate = new Date(tempDate.getTime() + day*increment);
    return format(endDate, 'yyyy-MM-dd' )
  }

  const previousWeek = () => {
    // setLoading(true);
    setShowWeek('Loading...');
    const newDate = weeklyDates(weekStartDate, -7);
    axios.get(`/api/get-weekly-time-logs/${newDate}`).then(res => {
      if (res.data.status === 200) {
        let tempTasksArray = [];
        res.data.weeklyTimeLogs.forEach(timelog => {
          tempTasksArray.push(timelog.task);
        });
        setWeeklyTimeLogs(res.data.weeklyTimeLogs);

        let uniqueTasksObject = [];
        let tempArray = [];
        tempTasksArray.forEach(element => {
          const val = element.id;
          if (!tempArray.includes(val)) {
            tempArray.push(val);
            uniqueTasksObject.push(element);
          }
        });

        setUniqueTasks(uniqueTasksObject);
        setWeekStartDate(newDate);
        setButtonDisable(false);
        setShowWeek(`( From ${newDate} To ${weeklyDates(newDate,6)} )`);
      }
    });
    // setLoading(false);
  }

  const currentWeek = () => {
    // setLoading(true);
    setShowWeek('Loading...');
    const newDate = currentWeekStartDate;
    axios.get(`/api/get-weekly-time-logs/${newDate}`).then(res => {
      if (res.data.status === 200) {
        let tempTasksArray = [];
        res.data.weeklyTimeLogs.forEach(timelog => {
          tempTasksArray.push(timelog.task);
        });
        setWeeklyTimeLogs(res.data.weeklyTimeLogs)

        let uniqueTasksObject = [];
        let tempArray = [];
        tempTasksArray.forEach(element => {
          const val = element.id;
          if (!tempArray.includes(val)) {
            tempArray.push(val);
            uniqueTasksObject.push(element);
          }
        });

        setUniqueTasks(uniqueTasksObject);
        setWeekStartDate(newDate);
        setButtonDisable(true)
        setShowWeek(`( From ${newDate} To ${weeklyDates(newDate,6)} )`);
      }
    });
    // setLoading(false);
  }

  const nextWeek = () => {
    // setLoading(true);
    setShowWeek('Loading...');
    const newDate = weeklyDates(weekStartDate, 7);
    axios.get(`/api/get-weekly-time-logs/${newDate}`).then(res => {
      if (res.data.status === 200) {
        let tempTasksArray = [];
        res.data.weeklyTimeLogs.forEach(timelog => {
          tempTasksArray.push(timelog.task);
        });
        setWeeklyTimeLogs(res.data.weeklyTimeLogs)

        let uniqueTasksObject = [];
        let tempArray = [];
        tempTasksArray.forEach(element => {
          const val = element.id;
          if (!tempArray.includes(val)) {
            tempArray.push(val);
            uniqueTasksObject.push(element);
          }
        });

        setUniqueTasks(uniqueTasksObject);
        setWeekStartDate(newDate);
        setShowWeek(`( From ${newDate} To ${weeklyDates(newDate,6)} )`);
        if (currentWeekStartDate === newDate) {
          setButtonDisable(true)
        }
      }
    });
    // setLoading(false);
  }

  let uniqueTaskData = [];

  if (timeLogColumns.length > 0) {
    uniqueTaskData = uniqueTasks.map(item => {
        console.log(item);
        return ({
            task_name :   <ShowMoreText
                            lines={1}
                            more="Show more"
                            less="Show less"
                            className="content-css"
                            anchorClass="show-more-less-clickable"
                            expanded={false}
                            width={200}
                            truncatedEndingComponent={"... "}
                        >{item.task}
                        </ShowMoreText>,
            project : item && item.project.name,
            monday :  item.id && countTaskTime(weekStartDate,item.id),
            tuesday :  item.id && countTaskTime(weeklyDates(weekStartDate,1),item.id),
            wednesday :  item.id && countTaskTime(weeklyDates(weekStartDate,2),item.id),
            thursday : item.id && countTaskTime(weeklyDates(weekStartDate,3),item.id),
            friday : item.id && countTaskTime(weeklyDates(weekStartDate,4),item.id),
            saturday : item.id && countTaskTime(weeklyDates(weekStartDate,5),item.id),
            sunday : item.id && countTaskTime(weeklyDates(weekStartDate,6),item.id),
            total : item.id && countSingleTaskWeeklyTotalTime(item.id),
        });
    });
  }

  const tempTotalTimeObjectPush = {
    task_name :  "Total",
    project : "",
    monday :  countDailyTaskTime(weekStartDate),
    tuesday :  countDailyTaskTime(weeklyDates(weekStartDate,1)),
    wednesday :  countDailyTaskTime(weeklyDates(weekStartDate,2)),
    thursday : countDailyTaskTime(weeklyDates(weekStartDate,3)),
    friday : countDailyTaskTime(weeklyDates(weekStartDate,4)),
    saturday : countDailyTaskTime(weeklyDates(weekStartDate,5)),
    sunday : countDailyTaskTime(weeklyDates(weekStartDate,6)),
    total : countWeeklyTotalTime(),
  }

  uniqueTaskData.push(tempTotalTimeObjectPush)

  return (
    <div className="weekly-tasks-wrp">
      <div className='py-5'>
        {/* <h3 className='py-3'>Weekly Tasks</h3> */}
        <div className='card-header'>
          <div className="heading-wrp">
            <h4>{`Weekly Tasks - ${showWeek}`}</h4>
            <div className="button-wrp">
              <button className="btn btn-primary btn-sm button-1 change-week-button" onClick={previousWeek}>
                Previous Week
              </button>
              <button disabled={buttonDisable} className="btn btn-primary btn-sm button-2 change-week-button" onClick={currentWeek}>
                Current Week
              </button>
              <button disabled={buttonDisable} className="btn btn-primary btn-sm button-3 change-week-button" onClick={nextWeek}>
                Next Week
              </button>
            </div>
          </div>
        </div>

        <div className='weekly-tasks-table'>
            <DataTable
                columns={timeLogColumns}
                data={uniqueTaskData}
            />
        </div>
      </div>
    </div>
  );
}

export default ViewWeeklyTimeLogs;