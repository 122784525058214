import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import ShowMoreText from "react-show-more-text";
import DataTable from 'react-data-table-component';
import Select from 'react-select';

import { TaskStatuses } from '../../../utils/TaskStatuses';
import { TaskPriority } from '../../../utils/TaskPriority';
import { TaskTypes } from '../../../utils/TaskTypes';
import MinutesToTime from '../../../utils/MinutesToTime';

const tasksColumns = [
    {
        name: 'Project',
        selector: row => row.project,
        sortable: true,
    },
    {
        name: 'Task',
        selector: row => row.task,
        sortable: true,
    },
    {
        name: 'Assigned By',
        selector: row => row.assigned_by,
        sortable: true,
    },
    {
        name: 'Due Date',
        selector: row => row.due_date,
        sortable: true,
    },
    {
        name: 'Estimate',
        selector: row => row.estimate,
        sortable: true,
    },
    {
        name: 'Completed Date',
        selector: row => row.completed_date,
        sortable: true,
    },
    {
        name: 'Total Spent',
        selector: row => row.total_spent_time,
        sortable: true,
    },
    {
        name: 'Total Billable',
        selector: row => row.total_billable_time,
        sortable: true,
    },
    {
        name: 'Type',
        selector: row => row.task_type,
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.task_status,
        sortable: true,
    },
    {
        name: 'Priority',
        selector: row => row.task_priority,
        sortable: true,
    },
    {
        name: 'Actions',
        selector: row => row.actions,
    },
];

let tasksData = [];

const timeLogColumns = [
    {
        name: 'Project',
        selector: row => row.project,
        sortable: true,
    },
    {
        name: 'Task',
        selector: row => row.task,
        sortable: true,
    },
    {
        name: 'Note',
        selector: row => row.note,
    },
    {
        name: 'Date',
        selector: row => row.date,
        sortable: true,
    },
    {
        name: 'Spent',
        selector: row => row.spent,
        sortable: true,
    },
    {
        name: 'Billable',
        selector: row => row.billable,
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
    }
];

let timeLogData = [];

const ResourceAllocation = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [taskList, setTaskList] = useState([]);
    const [timeLogList, setTimeLogList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [totalDueTasks, setTotalDueTasks] = useState(0);
    const [weekList, setWeekList] = useState([]);
    const [errorList, setError] = useState([]);
    const [pending, setPending] = useState(false);
    const [reportInput, setReportInput] = useState({
        user_id: '',
        week_start_date: '',
    });
    let totalEstimateTime = 0;
    let totalSpentTime = 0;
    let totalNonBillableTime = 0;
    let totalCompletedTasks = 0;

    useEffect(() => {
        axios.get(`/api/resource-allocation-report`).then(res => {
            if (res.data.status === 200) {
                setTaskList([]);
                tasksData = [];
                setTimeLogList([]);
                timeLogData = [];
                setTotalDueTasks(0);
                setError([]);
                setUserList(res.data.users);
                setWeekList(res.data.weeks);
            }
            setLoading(false);
        });
    }, []);

    const handleUser = (e) => {
        setReportInput({ ...reportInput, user_id: e.value });
    }

    const handleWeek = (e) => {
        setReportInput({ ...reportInput, week_start_date: e.value });
    }

    const generateReport = (e) => {
        e.preventDefault();
        setPending(true);
        setTaskList([]);
        tasksData = [];
        setTimeLogList([]);
        timeLogData = [];
        setTotalDueTasks(0);
        setError([]);
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.get(`/api/generate-resource-allocation-report/${reportInput.user_id}/${reportInput.week_start_date}`).then(res => {
                if (res.data.status === 200) {
                    setTaskList(res.data.tasks);
                    setTimeLogList(res.data.timeLogs);
                    setTotalDueTasks(res.data.totalDueTasks);
                    setPending(false);
                } else if (res.data.status === 404) {
                    swal("Error", res.data.message, "error");
                    history.push('/admin/resource-allocation-report');
                    setPending(false);
                }
            });
        });
    }

    if (loading) {
        return <h4>Loading Resource Allocation Report...</h4>
    } else {
        if (taskList.length > 0) {
            tasksData = taskList.map(item => {
                if (item.estimate_time === null) {
                    item.estimate_time = 0;
                }
                totalEstimateTime += parseInt(item.estimate_time);
                if(item.task_status === 'completed') {
                    totalCompletedTasks++;
                }
                return ({
                    project :   item.project && item.project.name,
                    task    :   <ShowMoreText
                                    lines={1}
                                    more="Show more"
                                    less="Show less"
                                    className="content-css"
                                    anchorClass="show-more-less-clickable"
                                    expanded={false}
                                    width={200}
                                    truncatedEndingComponent={"... "}
                                >{item.task}
                                </ShowMoreText>,
                    assigned_by :   item.assigned_by ? item.assigned_by.name : 'Self Assigned',
                    due_date :  item.due_date,
                    estimate :  MinutesToTime(item.estimate_time),
                    completed_date  :   item.completed_date,
                    total_spent_time    :   MinutesToTime(item.total_spent_time),
                    total_billable_time :   MinutesToTime(item.total_spent_time - item.total_non_billable_time),
                    task_type   :   TaskTypes.map((taskType, idx) => item.task_type === taskType.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskType.value}`}>{taskType.label}</span> : ''),
                    task_status :   TaskStatuses.map((taskStatus, idx) => item.task_status === taskStatus.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskStatus.value}`}>{taskStatus.label}</span> : ''),
                    task_priority   :   TaskPriority.map((taskPriority, idx) => item.task_priority === taskPriority.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskPriority.value}`}>{taskPriority.label}</span> : ''),
                    actions :   <div className="d-flex">
                                    <Link to={`/admin/view-task/${item.id}`} className="btn btn-outline-secondary btn-sm"><i className="fa fa-eye"></i></Link>
                                </div>,
                });
            });
        }

        if (timeLogList.length > 0) {
            timeLogData = timeLogList.map(item => {
                if (item.spent_time === null) {
                    item.spent_time = 0;
                }
                totalSpentTime += (item.spent_time ? parseInt(item.spent_time) : 0);

                if (item.non_billable_time === null) {
                    item.non_billable_time = 0;
                }
                totalNonBillableTime += (item.non_billable_time ? parseInt(item.non_billable_time) : 0);
                return ({
                    project :   item.task && item.task.project.name,
                    task :  item.task &&
                            <ShowMoreText
                                lines={1}
                                more="Show more"
                                less="Show less"
                                className="content-css"
                                anchorClass="show-more-less-clickable"
                                expanded={false}
                                width={200}
                                truncatedEndingComponent={"... "}
                            >{item.task.task}
                            </ShowMoreText>,
                    note :  <ShowMoreText
                                lines={1}
                                more="Show more"
                                less="Show less"
                                className="content-css"
                                anchorClass="show-more-less-clickable"
                                expanded={false}
                                width={200}
                                truncatedEndingComponent={"... "}
                            >{item.note}
                            </ShowMoreText>,
                    date :  item.date,
                    spent : MinutesToTime(item.spent_time),
                    billable : MinutesToTime(item.spent_time - item.non_billable_time),
                    status :    TaskStatuses.map((taskStatus, idx) => item.task && (item.task.task_status === taskStatus.value) ? <span key={idx} className={`badge rounded-pill bg-pill-${taskStatus.value}`}>{taskStatus.label}</span> : ''),
                });
            });
        }
    }

    return (
        <div className='px-4'>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h4>Resource Allocation Report</h4>
                </div>
                <div className='card-body'>
                    <form encType='multipart/form-data' onSubmit={generateReport} id="REPORT_FORM" className="row gx-3 gy-2 align-items-center">
                        <div className="col-md-3">
                            <label htmlFor="user_id" className="visually-hidden">Select User</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                defaultValue={reportInput.user_id}
                                isSearchable
                                onChange={handleUser}
                                name="user_id"
                                id="user_id"
                                placeholder="Select User..."
                                required
                                options={
                                    userList.map(item => {
                                        return (
                                            {
                                                value: item.id,
                                                label: item.name
                                            }
                                        )
                                    })
                                }
                            />
                            <small className='text-danger'>{errorList.user_id}</small>
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="week_start_date" className="visually-hidden">Select Week</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                defaultValue={reportInput.week_start_date}
                                isSearchable
                                onChange={handleWeek}
                                name="week_start_date"
                                id="week_start_date"
                                placeholder="Select Week..."
                                required
                                options={
                                    weekList.map(item => {
                                        return (
                                            {
                                                value: item.id,
                                                label: item.label
                                            }
                                        )
                                    })
                                }
                            />
                            <small className='text-danger'>{errorList.week_start_date}</small>
                        </div>
                        <div className="col-md-2 d-grid">
                            <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-dark'>
                                <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                                Generate Report
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="row pt-5">
                <div className="col-md-3">
                    <div className="card text-bg-dark mb-3">
                        <div className="card-header"><h5>Total Estimated (HH:MM)</h5></div>
                        <div className="card-body">
                            <h5 className="card-title">{MinutesToTime(totalEstimateTime)}</h5>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card text-bg-dark mb-3">
                        <div className="card-header"><h5>Total Spent (HH:MM)</h5></div>
                        <div className="card-body">
                            <h5 className="card-title">{MinutesToTime(totalSpentTime)}</h5>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card text-bg-dark mb-3">
                        <div className="card-header"><h5>Total Billable (HH:MM)</h5></div>
                        <div className="card-body">
                            <h5 className="card-title">{MinutesToTime(totalSpentTime - totalNonBillableTime)}</h5>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card text-bg-dark mb-3">
                        <div className="card-header"><h5>Total Completed Tasks</h5></div>
                        <div className="card-body">
                            <h5 className="card-title">{totalCompletedTasks}</h5>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card text-bg-dark mb-3">
                        <div className="card-header"><h5>Total Due Tasks</h5></div>
                        <div className="card-body">
                            <h5 className="card-title">{totalDueTasks}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h5>Task Data</h5>
                </div>
                <div className='card-body'>
                    <DataTable
                        columns={tasksColumns}
                        data={tasksData}
                        pagination
                    />
                </div>
            </div>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h5>Time Log Data</h5>
                </div>
                <div className='card-body'>
                    <DataTable
                        columns={timeLogColumns}
                        data={timeLogData}
                        pagination
                    />
                </div>
            </div>
        </div>
    );
}

export default ResourceAllocation;