import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import PublicRouteList from '../../routes/PublicRouteList';

const FrontendLayout = () => {
    return (
        <Switch>
            {PublicRouteList.map((routeData, idx) => {
                return (
                    routeData.component && (
                        (routeData.path === "/login" || routeData.path === "/register" || routeData.path === "/forgot-password") && localStorage.getItem('auth_token') ? 
                        <Redirect key={idx} to='/' /> :
                        <Route
                            key={idx}
                            path={routeData.path}
                            exact={routeData.exact}
                            name={routeData.name}
                            render={(props) => (
                                <routeData.component {...props} allowedRoles={routeData.allowedRoles} />
                            )}
                        />
                    )
                )
            })}
            <Redirect to="/" />
        </Switch>
    );
}

export default FrontendLayout;