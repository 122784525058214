import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import ShowMoreText from "react-show-more-text";
import DataTable from 'react-data-table-component';
import parse from 'html-react-parser';

import { TaskStatuses } from '../../../utils/TaskStatuses';
import { TaskPriority } from '../../../utils/TaskPriority';
import MinutesToTime from '../../../utils/MinutesToTime';

const taskColumns = [
    {
        name: 'Task',
        selector: row => row.task,
        sortable: true,
    },
    {
        name: 'Assignee',
        selector: row => row.assignee,
        sortable: true,
    },
    {
        name: 'Assigned By',
        selector: row => row.assigned_by,
        sortable: true,
    },
    {
        name: 'Due Date',
        selector: row => row.due_date,
        sortable: true,
    },
    {
        name: 'Completed Date',
        selector: row => row.completed_date,
        sortable: true,
    },
    {
        name: 'Task Status',
        selector: row => row.task_status,
        sortable: true,
    },
    {
        name: 'Task Priority',
        selector: row => row.task_priority,
        sortable: true,
    }
];

let taskData = [];

const ViewProjectTask = (props) => {
    const clientTaskId = props.match.params.id;
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [projectList, setProjectList] = useState([]);
    const [taskList, setTaskList] = useState([]);
    const [clientTaskInput, setClientTaskInput] = useState({
        project_id: '',
        task: '',
        description: '',
        comments: '',
        ideal_date: '',
        task_status: '',
        task_priority: '',
        file: '',
        time_spent: '',
        completed_date: '',
    });

    if (!localStorage.getItem('auth_token')) {
        history.push('/login');
        swal('Unauthorized', 'Authorization Required', 'error');
    } else if (!props.allowedRoles.includes(localStorage.getItem('auth_role'))) {
        history.push('/403');
    }

    const setFormData = useCallback(async () => {
        taskData = [];
        axios.get(`/api/get-client-task-data/${clientTaskId}`).then(res => {
            if (res.data.status === 200) {
                setClientTaskInput(res.data.clientTask);
                setTaskList(res.data.tasks);
            } else if (res.data.status === 404) {
                swal("Error", res.data.message, "error");
                history.push('/tasks');
            }
            setLoading(false);
        });
    }, [history, clientTaskId]);

    useEffect(() => {
        axios.get(`/api/get-projects`).then(res => {
            if (res.data.status === 200) {
                setProjectList(res.data.projects);
            }
        });
        setFormData();
    }, [setFormData]);

    if (loading) {
        return <h4>Loading View Task...</h4>
    } else {
        if (taskList.length > 0) {
            taskData = taskList.map(item => {
                return ({
                    task :  <ShowMoreText
                                lines={1}
                                more="Show more"
                                less="Show less"
                                className="content-css"
                                anchorClass="show-more-less-clickable"
                                expanded={false}
                                width={200}
                                truncatedEndingComponent={"... "}
                            >{item.task}
                            </ShowMoreText>,
                    assignee :   item.assignee && item.assignee.name,
                    assigned_by :   item.assigned_by ? item.assigned_by.name : 'Self Assigned',
                    due_date :  item.due_date,
                    completed_date  :   item.completed_date,
                    task_status :    TaskStatuses.map((taskStatus, idx) => item.task_status === taskStatus.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskStatus.value}`}>{taskStatus.label}</span> : ''),
                    task_priority :    TaskPriority.map((taskPriority, idx) => item.task_priority === taskPriority.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskPriority.value}`}>{taskPriority.label}</span> : ''),
                });
            });
        }
    }

    return (
        <div className='container py-5'>
            <div className='card'>
                <div className='card-header'>
                    <h4>View Task
                        <Link to={`/my-tasks/${clientTaskInput.project.slug}`} className='btn btn-primary btn-sm float-end'>BACK</Link>
                    </h4>
                </div>
                <div className='card-body'>
                    <form encType='multipart/form-data' id="TASK_FORM" className="row g-3">
                        <div className="col-md-4">
                            <label htmlFor="project_id" className="form-label">Project</label>
                            <select name='project_id' value={clientTaskInput.project_id} className='form-select' id="project_id" disabled>
                                <option value="">Select Project</option>
                                {
                                    projectList.map(item => {
                                        return (
                                            <option value={item.id} key={item.id}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-md-8">
                            <label htmlFor="task" className="form-label">Task</label>
                            <input type="text" name="task" value={clientTaskInput.task} className='form-control' id="task" disabled />
                        </div>
                        <div className="col-12">
                            <label htmlFor="description" className="form-label">Description</label>
                            <div className='form-control html-desc'>{clientTaskInput.description && parse(clientTaskInput.description)}</div>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="ideal_date" className="form-label">Ideal Date</label>
                            <input type="date" name="ideal_date" value={clientTaskInput.ideal_date} className='form-control' id='ideal_date' disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="completed_date" className="form-label">Completed Date</label>
                            <input type="date" name="completed_date" value={clientTaskInput.completed_date} className='form-control' id='completed_date' disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="spent_time" className="form-label">Total Spent Time (HH:MM)</label>
                            <input type="text" name="spent_time" value={MinutesToTime(clientTaskInput.time_spent)} className='form-control' min="0" step="1" id='spent_time' disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="task_status" className="form-label">Task Status</label>
                            <select name='task_status' value={clientTaskInput.task_status} className='form-select' id='task_status' disabled>
                                <option value="">Select Task Status</option>
                                {TaskStatuses.map((taskStatus, idx) => <option key={idx} value={taskStatus.value}>{taskStatus.label}</option>)}
                            </select>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="task_priority" className="form-label">Task Priority</label>
                            <select name='task_priority' value={clientTaskInput.task_priority} className='form-select' id='task_priority' disabled>
                                <option value="">Select Task Priority</option>
                                {TaskPriority.map((taskPriority, idx) => <option key={idx} value={taskPriority.value}>{taskPriority.label}</option>)}
                            </select>
                        </div>
                        {clientTaskInput.file &&
                            <div className="col-md-4">
                                <label htmlFor="file" className="form-label">File</label>
                                <div><a href={`${process.env.REACT_APP_BACKEND_URL}/${clientTaskInput.file}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-dark mt-2">View File <i className="fa fa-external-link" aria-hidden="true"></i></a></div>
                            </div>
                        }
                        <div className="col-12">
                            <label htmlFor="comments" className="form-label">Comments</label>
                            <div className='form-control html-desc'>{clientTaskInput.comments && parse(clientTaskInput.comments)}</div>
                        </div>
                    </form>
                </div>
            </div>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h5>Developer Tasks</h5>
                </div>
                <div className='card-body'>
                    <DataTable
                        columns={taskColumns}
                        data={taskData}
                        pagination
                    />
                </div>
            </div>
        </div>
    );
}

export default ViewProjectTask;