export const TaskTypes = [
    { value: 'creative', label: 'Creative' },
    { value: 'development', label: 'Development' },
    { value: 'qa', label: 'QA' },
    { value: 'devops', label: 'DevOps' },
    { value: 'support', label: 'Support' },
    { value: 'communication', label: 'Communication' },
    { value: 'content', label: 'Content' },
    { value: 'documentation', label: 'Documentation' },
    { value: 'other', label: 'Other' }
];