import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { modules } from '../../../utils/EditorToolbar';
import { TaskStatuses } from '../../../utils/TaskStatuses';
import { TaskPriority } from '../../../utils/TaskPriority';
import { TaskTypes } from '../../../utils/TaskTypes';

const EditTask = (props) => {
    const taskId = props.match.params.id;
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [projectList, setProjectList] = useState([]);
    const [assigneeList, setAssigneeList] = useState([]);
    const [qaAssigneeList, setQaAssigneeList] = useState([]);
    const [allCheckbox, setCheckboxes] = useState([]);
    const [picture, setPicture] = useState([]);
    const [errorList, setError] = useState([]);
    const [pending, setPending] = useState(false);
    const [estimate, setEstimate] = useState({
        estimate_hours: 0,
        estimate_minutes: 0,
    });
    const [taskInput, setTask] = useState({
        project_id: '',
        assignee_id: '',
        task: '',
        description: '',
        due_date: '',
        task_status: '',
        task_priority: '',
        task_type: '',
        qa_assignee_id: '',
        qa_comments: '',
    });

    const setFormData = useCallback(async () => {
        axios.get(`/api/edit-task/${taskId}`).then(res => {
            if (res.data.status === 200) {
                setTask(res.data.task);
                setPicture({ image: res.data.task.image });
                setCheckboxes(res.data.task);
                let estimateTime = res.data.task.estimate_time;
                let estimateObject = {
                    estimate_hours : Math.floor(estimateTime/60),
                    estimate_minutes : estimateTime - (Math.floor(estimateTime/60))*60
                }
                setEstimate(estimateObject);
            } else if (res.data.status === 404) {
                swal("Error", res.data.message, "error");
                history.push('/admin/view-tasks');
            }
            setLoading(false);
        });
    }, [history, taskId])

    useEffect(() => {
        axios.get(`/api/all-projects`).then(res => {
            if (res.data.status === 200) {
                setProjectList(res.data.projects);
            }
        });
        axios.get(`/api/all-employees`).then(res => {
            if (res.data.status === 200) {
                setAssigneeList(res.data.users);
            }
        });
        axios.get(`/api/get-qa-assignees`).then(res => {
            if (res.data.status === 200) {
                setQaAssigneeList(res.data.users);
            }
        });
        setFormData();
    }, [setFormData]);

    const handleInput = (e) => {
        e.persist();
        setTask({ ...taskInput, [e.target.name]: e.target.value });
    }

    const handleEstimate = (e) => {
        e.persist();
        setEstimate({ ...estimate, [e.target.name]: e.target.value });
    }

    const handleDescription = (value) => {
        setTask({ ...taskInput, description: value });
    }

    const handleQaComments = (value) => {
        setTask({ ...taskInput, qa_comments: value });
    }

    const handleImage = (e) => {
        e.persist();
        setPicture({ image: e.target.files[0] });
    }

    const handleCheckbox = (e) => {
        e.persist();
        setCheckboxes({ ...allCheckbox, [e.target.name]: e.target.checked });
    }

    const handleProject = (project) => {
        setTask({ ...taskInput, project_id: project.value });
    }

    const handleAssignee = (assignee) => {
        setTask({ ...taskInput, assignee_id: assignee.value });
    }

    const handleQaAssignee = (assignee) => {
        setTask({ ...taskInput, qa_assignee_id: assignee.value });
    }

    const updateTask = (e) => {
        e.preventDefault();
        setPending(true);

        axios.get('/sanctum/csrf-cookie').then(response => {
            let hours = estimate.estimate_hours ? parseInt(estimate.estimate_hours) : 0;
            let minutes = estimate.estimate_minutes ? parseInt(estimate.estimate_minutes) : 0;
            let estimatedTime = hours*60 + minutes;

            const formData = new FormData();

            formData.append('project_id', taskInput.project_id);
            formData.append('assignee_id', taskInput.assignee_id);
            formData.append('task', taskInput.task);
            formData.append('description', taskInput.description);
            formData.append('due_date', taskInput.due_date);
            formData.append('estimate_time', estimatedTime);
            formData.append('image', picture.image);
            formData.append('task_status', taskInput.task_status);
            formData.append('task_priority', taskInput.task_priority);
            formData.append('task_type', taskInput.task_type);
            formData.append('qa_assignee_id', taskInput.qa_assignee_id);
            formData.append('qa_comments', taskInput.qa_comments);
            formData.append('status', allCheckbox.status ? '1' : '0');
            formData.append('_method', 'PUT');

            let isValid = true;

            if (taskInput.task_status === 'internal-qa') {
                if (taskInput.qa_assignee_id === '' || taskInput.qa_assignee_id === null) {
                    swal("Please Select QA Assignee", "", "error");
                    isValid = false;
                    setPending(false);
                }
            }

            if (isValid) {
                axios.post(`/api/update-task/${taskId}`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
                    if (res.data.status === 200) {
                        swal("Success", res.data.message, "success");
                        setError([]);
                        setFormData();
                        setPending(false);
                    } else if (res.data.status === 422) {
                        swal("All fields are mandatory", "", "error");
                        setError(res.data.errors);
                        setPending(false);
                    } else if (res.data.status === 404) {
                        swal("Error", res.data.message, "error");
                        history.push('/admin/view-tasks');
                        setPending(false);
                    }
                });
            }
        });
    }

    const projectOptions = projectList.map(item => {
        return ({
            value: item.id,
            label: item.name+' ('+item.client.name+')'
        });
    });

    const assigneeOptions = assigneeList.map(item => {
        return ({
            value: item.id,
            label: item.name
        });
    });

    const qaAssigneeOptions = qaAssigneeList.map(item => {
        return ({
            value: item.id,
            label: item.name
        });
    });

    if (loading) {
        return <h4>Loading Edit Task...</h4>
    }

    return (
        <div className='px-4'>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h4>Edit Task
                        <Link to="/admin/view-tasks" className='btn btn-primary btn-sm float-end'>BACK</Link>
                    </h4>
                </div>
                <div className='card-body'>
                    <form encType='multipart/form-data' onSubmit={updateTask} id="TASK_FORM" className="row g-3">
                        <div className="col-md-4">
                            <label htmlFor="project_id" className="form-label">Select Project</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                value={projectOptions.filter(function(option) {
                                    return option.value === taskInput.project_id;
                                })}
                                isSearchable
                                onChange={handleProject}
                                name="project_id"
                                options={projectOptions}
                            />
                            <small className='text-danger'>{errorList.project_id}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="assignee_id" className="form-label">Select Assignee</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                value={assigneeOptions.filter(function(option) {
                                    return option.value === taskInput.assignee_id;
                                })}
                                isSearchable
                                onChange={handleAssignee}
                                name="assignee_id"
                                options={assigneeOptions}
                            />
                            <small className='text-danger'>{errorList.assignee_id}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="assigned_by" className="form-label">Assigned By</label>
                            <input type="text" name="assigned_by" value={taskInput.assigned_by ? taskInput.assigned_by.name : 'Self Assigned'} className='form-control' id="task" disabled />
                        </div>
                        <div className="col-12">
                            <label htmlFor="task" className="form-label">Task</label>
                            <input type="text" name="task" onChange={handleInput} value={taskInput.task} className='form-control' id='task' />
                            <small className='text-danger'>{errorList.task}</small>
                        </div>
                        <div className="col-12">
                            <label htmlFor="description" className="form-label">Description</label>
                            <ReactQuill
                                theme="snow"
                                value={taskInput.description}
                                onChange={handleDescription}
                                modules={modules}
                            />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="task_type" className="form-label">Task Type</label>
                            <select name='task_type' onChange={handleInput} value={taskInput.task_type} className='form-select' id='task_type'>
                                <option value="">Select Task Type</option>
                                {TaskTypes.map((taskType, idx) => <option key={idx} value={taskType.value}>{taskType.label}</option>)}
                            </select>
                            <small className='text-danger'>{errorList.task_type}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="task_status" className="form-label">Task Status</label>
                            <select name='task_status' onChange={handleInput} value={taskInput.task_status} className='form-select' id='task_status'>
                                <option value="">Select Task Status</option>
                                {TaskStatuses.map((taskStatus, idx) => <option key={idx} value={taskStatus.value}>{taskStatus.label}</option>)}
                            </select>
                            <small className='text-danger'>{errorList.task_status}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="task_priority" className="form-label">Task Priority</label>
                            <select name='task_priority' onChange={handleInput} value={taskInput.task_priority} className='form-select' id='task_priority'>
                                <option value="">Select Task Priority</option>
                                {TaskPriority.map((taskPriority, idx) => <option key={idx} value={taskPriority.value}>{taskPriority.label}</option>)}
                            </select>
                            <small className='text-danger'>{errorList.task_priority}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="due_date" className="form-label">Due Date</label>
                            <input type="date" name="due_date" onChange={handleInput} value={taskInput.due_date} className='form-control' id='due_date' />
                            <small className='text-danger'>{errorList.due_date}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="estimate_time" className="form-label">Estimate Time (HH:MM)</label>
                            <div className="input-group">
                                <input type="number" name="estimate_hours" onChange={handleEstimate} value={estimate.estimate_hours} min="0" max="999" step="1" id='estimate_hours' className="form-control text-end" placeholder="Hours" aria-label=""/>
                                <span className="input-group-text">:</span>
                                <input type="number" name="estimate_minutes" onChange={handleEstimate} value={estimate.estimate_minutes} min="0" max="59" step="1" id='estimate_minutes' className="form-control" placeholder="Minutes" aria-label=""/>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="image" className="form-label">Upload File</label>
                            <input type='file' name='image' onChange={handleImage} className='form-control' id='image' />
                            <small className='text-danger'>{errorList.image}</small>
                            {taskInput.image && <div><a href={`${process.env.REACT_APP_BACKEND_URL}/${taskInput.image}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-dark mt-2">View File <i className="fa fa-external-link" aria-hidden="true"></i></a></div>}
                        </div>
                        <div className="col-12">
                            <div className="form-check">
                                <input type="checkbox" name='status' onChange={handleCheckbox} defaultChecked={allCheckbox.status === 1 ? true : false} className="form-check-input" id="status" />
                                <label htmlFor="status" className="form-check-label">Status (checked=Hidden)</label>
                            </div>
                        </div>
                        <hr />
                        <div className="col-md-4">
                            <label htmlFor="qa_assignee_id" className="form-label">QA Assignee</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                value={qaAssigneeOptions.filter(function(option) {
                                    return option.value === taskInput.qa_assignee_id;
                                })}
                                isSearchable
                                onChange={handleQaAssignee}
                                name="qa_assignee_id"
                                options={qaAssigneeOptions}
                            />
                            <small className='text-danger'>{errorList.qa_assignee_id}</small>
                        </div>
                        <div className="col-12">
                            <label htmlFor="qa_comments" className="form-label">Task Comments</label>
                            <ReactQuill
                                theme="snow"
                                value={taskInput.qa_comments}
                                onChange={handleQaComments}
                                modules={modules}
                            />
                        </div>
                        <div className="col-12">
                            <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-primary px-4 float-end'>
                                <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditTask;