import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import ShowMoreText from "react-show-more-text";
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import Modal from 'react-modal';

import { TaskStatuses } from '../../../utils/TaskStatuses';
import { TaskPriority } from '../../../utils/TaskPriority';
import { TaskTypes } from '../../../utils/TaskTypes';

const tasksColumns = [
    {
        name: 'Project',
        selector: row => row.project,
        sortable: true,
    },
    {
        name: 'Task',
        selector: row => row.task,
        sortable: true,
    },
    {
        name: 'Assigned By',
        selector: row => row.assigned_by,
        sortable: true,
    },
    {
        name: 'Due Date',
        selector: row => row.due_date,
        sortable: true,
    },
    {
        name: 'Type',
        selector: row => row.type,
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
    },
    {
        name: 'Priority',
        selector: row => row.priority,
        sortable: true,
    },
    {
        name: 'Actions',
        selector: row => row.actions,
    },
];

let tasksData = [];
let completedTasksData = [];
let myQaTasksData = [];
let qaTasksData = [];

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(30, 30, 30, 0.75)'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        margin: '0',
        transform: 'translate(-50%, -50%)',
        width: '350px',
        overflow: 'visible'
    },
};

const ViewTasks = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [taskList, setTaskList] = useState([]);
    const [qaTaskList, setQaTaskList] = useState([]);
    const [weekList, setWeekList] = useState([]);
    const [weekStartDate, setWeekStartDate] = useState('all');
    const [modalIsOpen, setIsOpen] = useState(false);
    const [errorList, setError] = useState([]);
    const [pending, setPending] = useState(false);
    const [qaAssigneeList, setQaAssigneeList] = useState([]);
    const [taskInput, setTask] = useState({
        id: '',
        qa_assignee_id: '',
    });

    if (!localStorage.getItem('auth_token')) {
        history.push('/login');
        swal('Unauthorized', 'Authorization Required', 'error');
    } else if (!props.allowedRoles.includes(localStorage.getItem('auth_role'))) {
        history.push('/403');
    }

    useEffect(() => {
        let isMounted = true;
        axios.get(`/api/get-weekly-tasks/${weekStartDate}`).then(res => {
            if (isMounted) {
                if (res.data.status === 200) {
                    setTaskList(res.data.tasks);
                    setQaTaskList(res.data.qaTasks);
                    setWeekList(res.data.weeks);
                    setWeekStartDate(res.data.currentWeek);
                    setLoading(false);
                }
            }
        });
        axios.get(`/api/get-qa-assignees`).then(res => {
            if (res.data.status === 200) {
                setQaAssigneeList(res.data.users);
                setLoading(false);
            }
        });
        return () => {
            isMounted = false
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setTableData = useCallback(async (weekStartDate) => {
        setTaskList([]);
        setQaTaskList([]);
        tasksData = [];
        completedTasksData = [];
        myQaTasksData = [];
        qaTasksData = [];
        axios.get(`/api/get-weekly-tasks/${weekStartDate}`).then(res => {
            if (res.data.status === 200) {
                setTaskList(res.data.tasks);
                setQaTaskList(res.data.qaTasks);
                setWeekList(res.data.weeks);
                setWeekStartDate(weekStartDate);
                setLoading(false);
            }
        });
    }, []);

    const generateReport = (e) => {
        e.preventDefault();
        setTaskList([]);
        setQaTaskList([]);
        tasksData = [];
        completedTasksData = [];
        myQaTasksData = [];
        qaTasksData = [];
        axios.get(`/api/get-weekly-tasks/${weekStartDate}`).then(res => {
            if (res.data.status === 200) {
                setTaskList(res.data.tasks);
                setQaTaskList(res.data.qaTasks);
                setWeekList(res.data.weeks);
                setWeekStartDate(weekStartDate);
                setLoading(false);
            }
        });
    }

    const deleteTask = (e, id) => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Record",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(`/api/delete-task-data/${id}`).then(res => {
                    if (res.data.status === 200) {
                        swal(res.data.message, {
                            icon: "success",
                        });
                        setTableData(weekStartDate);
                    } else if (res.data.status === 404) {
                        swal("Error", res.data.message, "error");
                    } else if (res.data.status === 405) {
                        swal("Error", res.data.message, "error");
                    }
                });
            }
        });
    }

    const setFormData = useCallback(async (taskId) => {
        setTask([]);
        axios.get(`/api/get-task-qa-data/${taskId}`).then(res => {
            if (res.data.status === 200) {
                setTask({ ...res.data.task });
            } else if (res.data.status === 404) {
                swal("Error", res.data.message, "error");
                history.push('/tasks');
            }
            setLoading(false);
        });
    }, [history]);

    function openModal(e, taskId) {
        setFormData(taskId);
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const handleQaAssignee = (assignee) => {
        setTask({ ...taskInput, qa_assignee_id: assignee.value });
    }

    const handleWeek = (e) => {
        setWeekStartDate(e.value);
    }

    const resetForm = (e) => {
        e.preventDefault();
        setTableData('all');
    }

    const getDataRow = (item) => {
        return ({
            project :   item.project && item.project.name,
            task    :   <ShowMoreText
                        lines={1}
                        more="Show more"
                        less="Show less"
                        className="content-css"
                        anchorClass="show-more-less-clickable"
                        expanded={false}
                        width={200}
                        truncatedEndingComponent={"... "}
                    >{item.task}
                    </ShowMoreText>,
            assigned_by :   item.assigned_by ? item.assigned_by.name : 'Self Assigned',
            due_date    :   item.due_date,
            type    :   TaskTypes.map((taskType, idx) => item.task_type === taskType.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskType.value}`}>{taskType.label}</span> : ''),
            status  :     TaskStatuses.map((taskStatus, idx) => item.task_status === taskStatus.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskStatus.value}`}>{taskStatus.label}</span> : ''),
            priority    :   TaskPriority.map((taskPriority, idx) => item.task_priority === taskPriority.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskPriority.value}`}>{taskPriority.label}</span> : ''),
            actions :   <div className="d-flex">
                            <Link to={`/view-task/${item.id}`} className="btn btn-outline-secondary btn-sm"><i className="fa fa-eye"></i></Link>
                            { 
                                !item.assigned_by && item.task_status !== 'internal-qa' &&
                                <Link to={`/edit-task/${item.id}`} className="btn btn-outline-success btn-sm ms-1"><i className="fa fa-pencil"></i></Link>
                            }
                            { 
                                (item.task_status !== 'completed' && item.task_status !== 'internal-qa' && item.locked !== 1) && 
                                <Link to={`/add-time-log/${item.id}`} className="btn btn-outline-primary btn-sm ms-1"><i className="fa fa-clock"></i></Link> 
                            }
                            { 
                                item.task_status === 'completed' &&
                                <button className="btn btn-outline-warning btn-sm ms-1" onClick={(e) => openModal(e, item.id)}><i className="fa fa-q"></i></button>
                            }
                            { 
                                (!item.assigned_by && item.task_status === 'not-started') &&
                                <button type='button' onClick={(e) => deleteTask(e, item.id)} className='btn btn-outline-danger btn-sm ms-1'><i className="fa fa-trash"></i></button> 
                            }
                        </div>,
        });
    }

    const weekOptions = weekList.map(item => {
        return ({
            value: item.id, 
            label: item.label
        })
    });

    weekOptions.unshift({
        value: 'all', 
        label: 'All'
    });

    const updateTask = (e) => {
        e.preventDefault();
        setPending(true);

        axios.get('/sanctum/csrf-cookie').then(response => {
            const formData = new FormData();

            formData.append('qa_assignee_id', taskInput.qa_assignee_id);
            formData.append('_method', 'PUT');

            axios.post(`/api/assign-task-qa/${taskInput.id}`, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
                if (res.data.status === 200) {
                    setTableData(weekStartDate);
                    swal("Success", res.data.message, "success");
                    setTask([]);
                    setError([]);
                    closeModal();
                    setPending(false);
                } else if (res.data.status === 422) {
                    swal("All fields are mandatory", "", "error");
                    setError(res.data.errors);
                    setPending(false);
                } else if (res.data.status === 404) {
                    swal("Error", res.data.message, "error");
                    history.push('/tasks');
                    setPending(false);
                }
            });
        });
    }

    const qaAssigneeOptions = qaAssigneeList.map(item => {
        return ({
            value: item.id,
            label: item.name
        });
    });

    if (loading) {
        return <h4>Loading Tasks...</h4>
    } else {
        if (taskList.length > 0) {
            tasksData = taskList.filter(item => item.task_status !== 'completed').filter(item => item.task_status !== 'internal-qa').map(item => getDataRow(item));
            completedTasksData = taskList.filter(item => item.task_status === 'completed').map(item => getDataRow(item));
            myQaTasksData = taskList.filter(item => item.task_status === 'internal-qa').map(item => getDataRow(item));
        }

        if (qaTaskList.length > 0) {
            qaTasksData = qaTaskList.filter(item => item.task_status === 'internal-qa').filter(item => parseInt(item.qa_assignee_id) === parseInt(localStorage.getItem('auth_id'))).map((item, idx) => {
                return ({
                    project :   item.project && item.project.name,
                    task    :  <ShowMoreText
                                lines={1}
                                more="Show more"
                                less="Show less"
                                className="content-css"
                                anchorClass="show-more-less-clickable"
                                expanded={false}
                                width={200}
                                truncatedEndingComponent={"... "}
                            >{item.task}
                            </ShowMoreText>,
                    assigned_by :   item.assigned_by ? item.assigned_by.name : 'Self Assigned',
                    due_date    :   item.due_date,
                    type    :   TaskTypes.map((taskType, idx) => item.task_type === taskType.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskType.value}`}>{taskType.label}</span> : ''),
                    status  :   TaskStatuses.map((taskStatus, idx) => item.task_status === taskStatus.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskStatus.value}`}>{taskStatus.label}</span> : ''),
                    priority    :   TaskPriority.map((taskPriority, idx) => item.task_priority === taskPriority.value ? <span key={idx} className={`badge rounded-pill bg-pill-${taskPriority.value}`}>{taskPriority.label}</span> : ''),
                    actions :   <div className="d-flex">
                                    <Link to={`/view-task/${item.id}`} className="btn btn-outline-secondary btn-sm"><i className="fa fa-eye"></i></Link>
                                    <Link to={`/edit-task/${item.id}`} className="btn btn-outline-success btn-sm ms-1"><i className="fa fa-pencil"></i></Link>
                                    { 
                                        (!item.assigned_by && item.task_status === 'not-started') &&
                                        <button type='button' onClick={(e) => deleteTask(e, item.id)} className='btn btn-outline-danger btn-sm ms-1'><i className="fa fa-trash"></i></button> 
                                    }
                                </div>,
                });
            });
        }
    }

    return (
        <div className='container py-5'>
            <div className='card'>
                <div className='card-header'>
                    <h4>My Tasks</h4>
                </div>
                <div className='card-body'>
                    <form encType='multipart/form-data' onSubmit={generateReport} id="REPORT_FORM" className="row gx-3 gy-2 align-items-center">
                        <div className="col-md-4">
                            <label htmlFor="week_start_date" className="visually-hidden">Select Week</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                value={weekOptions.filter(function(option) {
                                    return option.value === weekStartDate;
                                })}
                                isSearchable
                                onChange={handleWeek}
                                name="week_start_date"
                                id="week_start_date"
                                placeholder="Select Week..."
                                required
                                options={weekOptions}
                            />
                        </div>
                        <div className="col-md-2 d-grid">
                            <button type='submit' className='btn btn-dark'>Search</button>
                        </div>
                        <div className="col-md-2 d-grid">
                            <button type='button' onClick={(e) => resetForm(e)} className='btn btn-secondary'>Reset</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h4>Task List
                        <Link to="/add-task" className='btn btn-primary btn-sm float-end'>Add Task</Link>
                    </h4>
                </div>
                <div className='card-body'>
                    <DataTable
                        columns={tasksColumns}
                        data={tasksData}
                        pagination
                    />
                </div>
            </div>
            <div className='card mt-4'>
                <div className='card-header'>
                    <h4>Completed Task List</h4>
                </div>
                <div className='card-body'>
                    <DataTable
                        columns={tasksColumns}
                        data={completedTasksData}
                        pagination
                    />
                </div>
            </div>
            {
                localStorage.getItem('auth_designation') === 'qa-engineer' || localStorage.getItem('auth_designation') === 'qa-intern' ?
                <div className='card mt-4'>
                    <div className='card-header'>
                        <h4>QA Task List</h4>
                    </div>
                    <div className='card-body'>
                        <DataTable
                            columns={tasksColumns}
                            data={qaTasksData}
                            pagination
                        />
                    </div>
                </div> :
                <div className='card mt-4'>
                    <div className='card-header'>
                        <h4>QA Assigned Task List</h4>
                    </div>
                    <div className='card-body'>
                        <DataTable
                            columns={tasksColumns}
                            data={myQaTasksData}
                            pagination
                        />
                    </div>
                </div>
            }
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} ariaHideApp={false}>
                <div>
                    <div className="modal-header">
                        <h5 className="modal-title">Assign Task To QA</h5>
                        <button type="button" className="btn-close" onClick={closeModal}></button>
                    </div>
                    <div className="modal-body">
                        <form encType='multipart/form-data' onSubmit={updateTask} id="TASK_FORM" className="row g-3">
                            <input type='hidden' name='id' value={taskInput.id} />
                            <div className="col-12">
                                <label htmlFor="qa_assignee_id" className="form-label">QA Assignee</label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    value={qaAssigneeOptions.filter(function(option) {
                                        return option.value === taskInput.qa_assignee_id;
                                    })}
                                    isSearchable
                                    onChange={handleQaAssignee}
                                    name="qa_assignee_id"
                                    options={qaAssigneeOptions}
                                />
                                <small className='text-danger'>{errorList.qa_assignee_id}</small>
                            </div>
                            <div className="col-12">
                                <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-primary px-4 float-end'>
                                    <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                                    Assign
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default ViewTasks;