import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import swal from 'sweetalert';
import parse from 'html-react-parser';
import ReactQuill from 'react-quill';

import { modules } from '../../../utils/EditorToolbar';
import { TaskStatuses } from '../../../utils/TaskStatuses';
import { TaskPriority } from '../../../utils/TaskPriority';
import TimerCount from '../../../utils/TimerCount';
import MinutesToTime from '../../../utils/MinutesToTime';

const AddTimeLog = (props) => {
    let taskId = '';
    if (localStorage.getItem('current_task_id')) {
        taskId = localStorage.getItem('current_task_id');
    } else {
        taskId = props.match.params.taskId;
    }
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [taskList, setTaskList] = useState([]);
    const [qaAssigneeList, setQaAssigneeList] = useState([]);
    const [errorList, setError] = useState([]);
    const [pending, setPending] = useState(false);
    const [timeLogInput, setTimeLog] = useState({
        task_id: '',
        description: '',
        note: '',
        date: new Date().toISOString().slice(0,10),
        due_date: '',
        estimate_time: '',
        spent_time: '',
        spent_time_hours: 0,
        spent_time_minutes: 0,
        non_billable_time: '',
        non_billable_time_hours: 0,
        non_billable_time_minutes: 0,
        task_status: '',
        task_priority: '',
        qa_assignee_id: '',
        qa_comments: '',
    });

    if (!localStorage.getItem('auth_token')) {
        history.push('/login');
        swal('Unauthorized', 'Authorization Required', 'error');
    } else if (!props.allowedRoles.includes(localStorage.getItem('auth_role'))) {
        history.push('/403');
    }

    const setTaskData = useCallback(async (taskId) => {
        if (taskId) {
            axios.get(`/api/edit-task-data/${taskId}`).then(res => {
                if (res.data.status === 200) {
                    let task = res.data.task;
                    setTimeLog(
                        { ...timeLogInput,
                            task_id: task.id,
                            description: task.description ? task.description : '',
                            task_status: task.task_status ? task.task_status : '',
                            due_date: task.due_date ? task.due_date : '',
                            estimate_time: task.estimate_time ? task.estimate_time : '',
                            task_priority: task.task_priority ? task.task_priority : '',
                            image: task.image ? task.image : '',
                            qa_assignee_id: task.qa_assignee_id ? task.qa_assignee_id : '',
                            qa_comments: task.qa_comments ? task.qa_comments : '',
                        }
                    );
                } else if (res.data.status === 404) {
                    swal("Error", res.data.message, "error");
                    history.push('/tasks');
                }
                setLoading(false);
            });
            if (localStorage.getItem('start_time')) {
                localStorage.setItem('is_resume', true);
            }
        }
        setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);

    useEffect(() => {
        axios.get(`/api/get-tasks`).then(res => {
            if (res.data.status === 200) {
                setTaskList(res.data.tasks);
            }
        });
        axios.get(`/api/get-qa-assignees`).then(res => {
            if (res.data.status === 200) {
                setQaAssigneeList(res.data.users);
            }
        });
        setTaskData(taskId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetForm = () => {
        setTimeLog({
            task_id: '',
            description: '',
            note: '',
            date: '',
            due_date: '',
            estimate_time: '',
            spent_time: '',
            spent_time_hours: 0,
            spent_time_minutes: 0,
            task_status: '',
            task_priority: '',
            image: '',
            qa_assignee_id: '',
            qa_comments: '',
        });
    }

    const handleInput = (e) => {
        e.persist();
        setTimeLog({ ...timeLogInput, [e.target.name]: e.target.value });
    }

    const handleQaComments = (value) => {
        setTimeLog({ ...timeLogInput, qa_comments: value });
    }

    const handleQaAssignee = (assignee) => {
        setTimeLog({ ...timeLogInput, qa_assignee_id: assignee.value });
    }

    const handleTask = (task) => {
        let selectedTask = taskList.find(item => item.id === task.value);
        if (selectedTask) {
            setTimeLog(
                { ...timeLogInput,
                    task_id: selectedTask.id,
                    description: selectedTask.description ? selectedTask.description : '',
                    task_status: selectedTask.task_status ? selectedTask.task_status : '',
                    due_date: selectedTask.due_date ? selectedTask.due_date : '',
                    estimate_time: selectedTask.estimate_time ? selectedTask.estimate_time : '',
                    task_priority: selectedTask.task_priority ? selectedTask.task_priority : '',
                    qa_assignee_id: selectedTask.qa_assignee_id ? selectedTask.qa_assignee_id : '',
                    qa_comments: selectedTask.qa_comments ? selectedTask.qa_comments : '',
                    image: selectedTask.image ? selectedTask.image : '',
                }
            )
        }
    }

    const timerData = (data) => {
        let time = data;
        let hoursTime;
        let minutesTime;

        if (time === 'reset') {
            minutesTime = 0;
            hoursTime = 0;
        } else {
            hoursTime = Math.floor(time/60);
            minutesTime = (time % 60);
            if (minutesTime.length === 1) {
            minutesTime = '0' + minutesTime;
            }
        }
        setTimeLog({ ...timeLogInput, spent_time_hours: hoursTime,spent_time_minutes: minutesTime });
    }

    const submitTimeLog = (e) => {
        e.preventDefault();
        setPending(true);
        let hours = timeLogInput.spent_time_hours ? parseInt(timeLogInput.spent_time_hours) : 0;
        let minutes = timeLogInput.spent_time_minutes ? parseInt(timeLogInput.spent_time_minutes) : 0;
        let spentTime = hours*60 + minutes;
        let nonBillableHours = timeLogInput.non_billable_time_hours ? parseInt(timeLogInput.non_billable_time_hours) : 0;
        let nonBillableMinutes = timeLogInput.non_billable_time_minutes ? parseInt(timeLogInput.non_billable_time_minutes) : 0;
        let nonBillableTime = nonBillableHours*60 + nonBillableMinutes;
        const formData = new FormData();
        formData.append('task_id', timeLogInput.task_id);
        formData.append('note', timeLogInput.note);
        formData.append('date', timeLogInput.date);
        formData.append('spent_time', spentTime);
        formData.append('non_billable_time', nonBillableTime);
        formData.append('task_status', timeLogInput.task_status);
        formData.append('task_priority', timeLogInput.task_priority);
        formData.append('qa_assignee_id', timeLogInput.qa_assignee_id);
        formData.append('qa_comments', timeLogInput.qa_comments);

        let isValid = true;

        if (timeLogInput.task_status === 'internal-qa') {
            if (timeLogInput.qa_assignee_id === '') {
                swal("Please Select QA Assignee", "", "error");
                isValid = false;
                setPending(false);
            }
        }
        
        if (isValid) {
            axios({
                method: "post",
                url: `/api/add-time-log-data`,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then(res => {
                if (res.data.status === 200) {
                    swal("Success", res.data.message, "success");
                    resetForm();
                    setError([]);
                    setTaskData(taskId);
    
                    // remove variables from local storage when submit is successfull
                    localStorage.removeItem('start_time');
                    localStorage.removeItem('pause_time');
                    localStorage.removeItem('current_task_id');
                    localStorage.removeItem('total_paused_time');
                    setPending(false);
                } else if (res.data.status === 422) {
                    swal("All Fields are mandatory", "", "error");
                    setError(res.data.errors);
                    setPending(false);
                }
            });
        }
    }

    const taskPriorityFunction = (inputValue) => {
        try {
            let tempValue = TaskPriority[TaskPriority.map(e => e.value).indexOf(inputValue)].label;
            return tempValue;
        } catch (error) {
            return "";
        }
    }

    const taskOptions = taskList.filter(item => item.task_status !== 'completed').filter(item => item.locked !== 1).map(item => {
        return ({
            value: item.id,
            label: item.project.name+' - '+item.task+' ( Due on '+item.due_date+')'
        })
    });

    const qaAssigneeOptions = qaAssigneeList.map(item => {
        return ({
            value: item.id,
            label: item.name
        });
    });

    if (loading) {
        return <h4>Loading...</h4>
    }

    return (
        <div className='container py-5'>
            <div className='card'>
                <div className='card-header'>
                    <h4>Add Time Log
                        <Link to="/time-logs" className='btn btn-primary btn-sm float-end'>View My Time Logs</Link>
                    </h4>
                </div>
                <div className='card-body'>
                    <form encType='multipart/form-data' onSubmit={submitTimeLog} id="TASK_FORM" className="row g-3">
                        <div className="col-12">
                            <label htmlFor="task_id" className="form-label">Select Task</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                value={taskOptions.filter(function(option) {
                                    return option.value === timeLogInput.task_id;
                                })}
                                isSearchable
                                onChange={handleTask}
                                name="task_id"
                                options={taskOptions}
                            />
                            <small className='text-danger'>{errorList.task_id}</small>
                        </div>
                        <div className="col-12">
                            <label htmlFor="description" className="form-label">Description</label>
                            <div className='form-control html-desc'>{timeLogInput.description && parse(timeLogInput.description)}</div>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="due_date" className="form-label">Due Date</label>
                            <input type="date" name="due_date" onChange={handleInput} value={timeLogInput.due_date} className='form-control' id='due_date' disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="estimate_time" className="form-label">Estimate Time (HH:MM)</label>
                            <input type="text" name="estimate_time" onChange={handleInput} value={MinutesToTime(timeLogInput.estimate_time)} className='form-control' min="0" step="1" id='estimate_time' disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="task_priority" className="form-label">Task Priority</label>
                            <input type="text" name="task_priority" onChange={handleInput} value={taskPriorityFunction(timeLogInput.task_priority)} className='form-control' id='task_priority' disabled />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="date" className="form-label">Date</label>
                            <input type="date" name="date" onChange={handleInput} value={timeLogInput.date} className='form-control' id='date' />
                            <small className='text-danger'>{errorList.date}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="spent_time_hours" className="form-label">Spent Time (HH:MM)</label>
                            <div className="input-group">
                                <input type="number" name="spent_time_hours" onChange={handleInput} value={timeLogInput.spent_time_hours} min="0" max="999" step="1" id='spent_time_hours' className="form-control text-end" placeholder="Hours" />
                                <span className="input-group-text">:</span>
                                <input type="number" name="spent_time_minutes" onChange={handleInput} value={timeLogInput.spent_time_minutes} min="0" max="59" step="1" id='spent_time_minutes' className="form-control" placeholder="Minutes" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="non_billable_time_hours" className="form-label">Non Billable Time (HH:MM)</label>
                            <div className="input-group">
                                <input type="number" name="non_billable_time_hours" onChange={handleInput} value={timeLogInput.non_billable_time_hours} min="0" max="999" step="1" id='non_billable_time_hours' className="form-control text-end" placeholder="Hours" />
                                <span className="input-group-text">:</span>
                                <input type="number" name="non_billable_time_minutes" onChange={handleInput} value={timeLogInput.non_billable_time_minutes} min="0" max="59" step="1" id='non_billable_time_minutes' className="form-control" placeholder="Minutes" />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="task_status" className="form-label">Task Status</label>
                            <select name='task_status' onChange={handleInput} value={timeLogInput.task_status} className='form-select' id='task_status'>
                                <option value="">Select Task Status</option>
                                {TaskStatuses.map((taskStatus, idx) => <option key={idx} value={taskStatus.value}>{taskStatus.label}</option>)}
                            </select>
                            <small className='text-danger'>{errorList.task_status}</small>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="qa_assignee_id" className="form-label">QA Assignee</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                value={qaAssigneeOptions.filter(function(option) {
                                    return option.value === timeLogInput.qa_assignee_id;
                                })}
                                isSearchable
                                onChange={handleQaAssignee}
                                name="qa_assignee_id"
                                options={qaAssigneeOptions}
                            />
                            <small className='text-danger'>{errorList.qa_assignee_id}</small>
                        </div>
                        
                        <div className="col-md-4">
                            {
                                timeLogInput.image &&
                                <>
                                    <label htmlFor="image" className="form-label">File</label>
                                    <div><a href={`${process.env.REACT_APP_BACKEND_URL}/${timeLogInput.image}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-outline-dark mt-2">View File <i className="fa fa-external-link" aria-hidden="true"></i></a></div>
                                </>
                            }
                        </div>
                        <div className="col-12">
                            <label htmlFor="qa_comments" className="form-label">Task Comments</label>
                            <ReactQuill
                                theme="snow"
                                value={timeLogInput.qa_comments}
                                onChange={handleQaComments}
                                modules={modules}
                            />
                            {/* <textarea name="qa_comments" onChange={handleInput} value={timeLogInput.qa_comments} className='form-control' id="qa_comments"></textarea> */}
                            {/* <div className='form-control html-desc'>{timeLogInput.qa_comments && parse(timeLogInput.qa_comments)}</div> */}
                        </div>
                        <div className="col-12">
                            <label htmlFor="note" className="form-label">Time Log Note</label>
                            <textarea name="note" onChange={handleInput} value={timeLogInput.note} className='form-control' id="note"></textarea>
                        </div>
                        <div className="col-12">
                            <button disabled = {pending ? 'disabled' : ''} type='submit' className='btn btn-primary px-4 mt-2 float-end'>
                                <span className={`spinner-border spinner-border-sm me-3 ${!pending ? 'd-none' : ''}`} role="status" aria-hidden="true"></span>
                                Submit
                            </button>
                        </div>
                    </form>
                    <div>
                        <TimerCount timerData={timerData} taskId={timeLogInput.task_id} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddTimeLog;