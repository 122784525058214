import React, { useEffect, useState, useCallback } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import axios from 'axios';
import Avatar from 'react-avatar';
import swal from 'sweetalert';

const Home = (props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [mondayLeaves, setMondayLeaves] = useState([]);
    const [tuesdayLeaves, setTuesdayLeaves] = useState([]);
    const [wednesdayLeaves, setWdnesdayLeaves] = useState([]);
    const [thursdayLeaves, setThursdayLeaves] = useState([]);
    const [fridayLeaves, setFridayLeaves] = useState([]);

    if (!localStorage.getItem('auth_token')) {
        history.push('/login');
        swal('Unauthorized', 'Authorization Required', 'error');
    } else if (!props.allowedRoles.includes(localStorage.getItem('auth_role'))) {
        history.push('/403');
    }

    const setDashboardData = useCallback(async () => {
        axios.get(`/api/get-weekly-leaves`).then(res => {
            if (res.data.status === 200) {
                setMondayLeaves(res.data.monday);
                setTuesdayLeaves(res.data.tuesday);
                setWdnesdayLeaves(res.data.wednesday);
                setThursdayLeaves(res.data.thursday);
                setFridayLeaves(res.data.friday);
                setLoading(false);
            } else if (res.data.status === 404) {
                swal("Error", res.data.message, "error");
                history.push('/');
            }
            setLoading(false);
        });
    }, [history])

    useEffect(() => {
        setDashboardData();
    }, [setDashboardData]);

    useEffect(() => {
        let isMounted = true;
        axios.get(`/api/get-weekly-leaves`).then(res => {
            if (isMounted) {
                if (res.data.status === 200) {
                    setMondayLeaves(res.data.monday);
                    setTuesdayLeaves(res.data.tuesday);
                    setWdnesdayLeaves(res.data.wednesday);
                    setThursdayLeaves(res.data.thursday);
                    setFridayLeaves(res.data.friday);
                    setLoading(false);
                }
            }
        });
        return () => {
            isMounted = false
        }
    }, []);

    if (loading) {
        return <h4>Loading Dashboard...</h4>
    }

    return (
        localStorage.getItem('auth_token') ?
        localStorage.getItem('auth_role') === 'client' ?
        <>
            <header className="d-none d-lg-block py-5">
                <div className="container px-lg-5">
                    <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
                        <div className="m-4 m-lg-5">
                            <h1 className="display-5 fw-bold">Welcome to Bees</h1>
                            <p className="fs-4">Developed by Smashy Design</p>
                        </div>
                    </div>
                </div>
            </header>
        </> :
        <>
            <header className="d-none d-lg-block pt-4">
                <div className="container px-lg-5">
                    <div className="p-4 p-lg-5 bg-light rounded-3 text-center">
                        <div className="m-4 m-lg-5">
                            <h1 className="display-5 fw-bold">Welcome to Bees</h1>
                            <p className="fs-4">Smashy Design In house Application.</p>
                        </div>
                    </div>
                </div>
            </header>
            <section className="pt-4">
                <div className="container px-lg-5">
                    <div className="row d-flex justify-content-center pb-5">
                        <div className="col-12 mb-5 text-center">
                            <h2>Current Week Leaves</h2>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-5">
                            <div className="card">
                                <div className="card-header">
                                    Monday
                                </div>
                                <ul className="list-group list-group-flush">
                                    {
                                        (mondayLeaves.length > 0) ?
                                            mondayLeaves.map(leave => (
                                                <li className="list-group-item text-capitalize">
                                                    <Avatar name={leave.user.name} src={leave.user.profile_picture !== '' ? `${process.env.REACT_APP_BACKEND_URL}/${leave.user.profile_picture}` : ''} size="60" round={true} /> {leave.type}
                                                </li>
                                            ))
                                        :
                                        <li className="list-group-item text-capitalize">No Leaves</li>
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-5">
                            <div className="card">
                                <div className="card-header">
                                    Tuesday
                                </div>
                                <ul className="list-group list-group-flush">
                                    {
                                        (tuesdayLeaves.length > 0) ?
                                            tuesdayLeaves.map(leave => (
                                                <li className="list-group-item text-capitalize">
                                                    <Avatar name={leave.user.name} src={leave.user.profile_picture !== '' ? `${process.env.REACT_APP_BACKEND_URL}/${leave.user.profile_picture}` : ''} size="60" round={true} /> {leave.type}
                                                </li>
                                            ))
                                        :
                                        <li className="list-group-item text-capitalize">No Leaves</li>
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-5">
                            <div className="card">
                                <div className="card-header">
                                    Wednesday
                                </div>
                                <ul className="list-group list-group-flush">
                                    {
                                        (wednesdayLeaves.length > 0) ?
                                            wednesdayLeaves.map(leave => (
                                                <li className="list-group-item text-capitalize">
                                                    <Avatar name={leave.user.name} src={leave.user.profile_picture !== '' ? `${process.env.REACT_APP_BACKEND_URL}/${leave.user.profile_picture}` : ''} size="60" round={true} /> {leave.type}
                                                </li>
                                            ))
                                        :
                                        <li className="list-group-item text-capitalize">No Leaves</li>
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-5">
                            <div className="card">
                                <div className="card-header">
                                    Thursday
                                </div>
                                <ul className="list-group list-group-flush">
                                    {
                                        (thursdayLeaves.length > 0) ?
                                            thursdayLeaves.map(leave => (
                                                <li className="list-group-item text-capitalize">
                                                    <Avatar name={leave.user.name} src={leave.user.profile_picture !== '' ? `${process.env.REACT_APP_BACKEND_URL}/${leave.user.profile_picture}` : ''} size="60" round={true} /> {leave.type}
                                                </li>
                                            ))
                                        :
                                        <li className="list-group-item text-capitalize">No Leaves</li>
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-5">
                            <div className="card">
                                <div className="card-header">
                                    Friday
                                </div>
                                <ul className="list-group list-group-flush">
                                    {
                                        (fridayLeaves.length > 0) ?
                                            fridayLeaves.map(leave => (
                                                <li className="list-group-item text-capitalize">
                                                    <Avatar name={leave.user.name} src={leave.user.profile_picture !== '' ? `${process.env.REACT_APP_BACKEND_URL}/${leave.user.profile_picture}` : ''} size="60" round={true} /> {leave.type}
                                                </li>
                                            ))
                                        :
                                        <li className="list-group-item text-capitalize">No Leaves</li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row gx-lg-5">
                        <div className="col-lg-4 mb-5">
                            <div className="card bg-light border-0 h-100">
                                <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                    <Link className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4" to="/tasks"><i className="bi bi-card-checklist"></i></Link>
                                    <h2 className="fs-4 fw-bold">My Tasks</h2>
                                    <p className="mb-0">Click here to view your tasks.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-5">
                            <div className="card bg-light border-0 h-100">
                                <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                    <Link className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4" to="/time-logs"><i className="bi bi-card-checklist"></i></Link>
                                    <h2 className="fs-4 fw-bold">My Time Logs</h2>
                                    <p className="mb-0">Click here to view your time logs.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-5">
                            <div className="card bg-light border-0 h-100">
                                <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                    <Link className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4" to="/leaves"><i className="bi bi-card-checklist"></i></Link>
                                    <h2 className="fs-4 fw-bold">My Leaves</h2>
                                    <p className="mb-0">Click here to view your leaves.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-5">
                            <div className="card bg-light border-0 h-100">
                                <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                    <Link className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4" to="/add-task"><i className="bi bi-plus-square"></i></Link>
                                    <h2 className="fs-4 fw-bold">Add Task</h2>
                                    <p className="mb-0">Click here to add a new task.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-5">
                            <div className="card bg-light border-0 h-100">
                                <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                    <Link className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4" to="/add-time-log"><i className="bi bi-plus-square"></i></Link>
                                    <h2 className="fs-4 fw-bold">Add Time Log</h2>
                                    <p className="mb-0">Click here to add a new time log.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-5">
                            <div className="card bg-light border-0 h-100">
                                <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                    <Link className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4" to="/add-leave"><i className="bi bi-plus-square"></i></Link>
                                    <h2 className="fs-4 fw-bold">Request a Leave</h2>
                                    <p className="mb-0">Click here to Request a Leave.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-5">
                            <div className="card bg-light border-0 h-100">
                                <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                    <Link className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4" to="/projects"><i className="bi bi-server"></i></Link>
                                    <h2 className="fs-4 fw-bold">Our Projects</h2>
                                    <p className="mb-0">Click here to view Our Projects</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-5">
                            <div className="card bg-light border-0 h-100">
                                <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                    <Link className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4" to="/clients"><i className="bi bi-server"></i></Link>
                                    <h2 className="fs-4 fw-bold">Our Clients</h2>
                                    <p className="mb-0">Click here to view Our Clients</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-5">
                            <div className="card bg-light border-0 h-100">
                                <div className="card-body text-center p-4 p-lg-5 pt-0 pt-lg-0">
                                    <Link className="feature bg-primary bg-gradient text-white rounded-3 mb-4 mt-n4" to="/users"><i className="bi bi-server"></i></Link>
                                    <h2 className="fs-4 fw-bold">Our Employees</h2>
                                    <p className="mb-0">Click here to view Our Employees</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
        :
        <Redirect to='/login' />
    );
}

export default Home;