import React from 'react';
import { useStopwatch } from 'react-timer-hook';
import { useState } from 'react';
import swal from 'sweetalert';

const TimerCount = ({timerData,taskId}) => {
  const [startVisible, setStartVisible] = useState(true);
  const [stopVisible, setStopVisible] = useState(true);
  let counter = 0;

  const {
    seconds,
    minutes,
    hours,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: false });

  // Checking for local variables and resume/paused
  if (localStorage.getItem('is_resume')) {
    var timePassed;
    localStorage.removeItem('is_resume', false);
    const stopwatchOffset = new Date();
    const nowTime = stopwatchOffset.getTime();
    const pauseTime = parseInt(localStorage.getItem('pause_time'));
    const thenTime = parseInt(localStorage.getItem('start_time'));
    var timeToRemove;
    // Check if there it was paused during the timer
    if (localStorage.getItem('total_paused_time')) {
      timeToRemove = parseInt(localStorage.getItem('total_paused_time'));
    } else {
      timeToRemove = 0;
    }
    // Check if timer is paused when reloading
    if (localStorage.getItem('pause_time')) {
      timePassed = (((nowTime-thenTime)-(nowTime - pauseTime))/1000)-timeToRemove;
      stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + Math.round(timePassed));
      reset(stopwatchOffset,false);
      setStartVisible((current) => !current);
      setStopVisible((current) => !current);
    // Run this if timer not paused
    } else {
      timePassed = (nowTime-thenTime)/1000-timeToRemove;
      stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + Math.round(timePassed));
      reset(stopwatchOffset,true);
      setStartVisible((current) => !current);
    }
  }

  // Function to change "Start" button text
  const startText = () => {
    if (localStorage.getItem('start_time') || localStorage.getItem('pause_time')) {
      return 'Stop';
    }else {
      if (startVisible) {
        return 'Start';
      } else {
        return 'Stop';
      }
    }
  }

  // Function to change "Pause" button text
  const stopText = () => {
    if (localStorage.getItem('pause_time')) {
      return 'Resume';
    } else {
      if (stopVisible) {
        return 'Pause';
      } else {
        return 'Resume';
      }
    }
  }

  // Function to "Start/Stop" the Timer
  const startTimer = () => {
    if (taskId) {
      localStorage.setItem('current_task_id', taskId);
      setStartVisible((current) => !current);
      counter++
      if (counter===1) {
        const startTime = new Date();
        localStorage.setItem('start_time', startTime.getTime());
      }
      if (startVisible) {
        start();
      } else {
        reset(0,false);
        timerData(Math.ceil(seconds/60)+minutes+hours*60);
        localStorage.removeItem('start_time');
        localStorage.removeItem('pause_time');
        localStorage.removeItem('current_task_id');
        localStorage.removeItem('total_paused_time');
      }
    } else {
      swal("Error", 'Please Select a Task', "error");
    }
  }

  // Function to "Pause/Resume" the timer
  const stopTimer = () => {
    setStopVisible((current) => !current);
    if (stopVisible) {
      pause();
      const pauseTime = new Date();
      localStorage.setItem('pause_time', pauseTime.getTime() );
    } else {
      start();
      const resume = new Date();
      const resumeTime = resume.getTime();
      const pausedTotalTimeTemp = (resumeTime - parseInt(localStorage.getItem('pause_time')))/1000;
      let pausedTotalTime;
      if (localStorage.getItem('total_paused_time')) {
        pausedTotalTime = pausedTotalTimeTemp + parseInt(localStorage.getItem('total_paused_time'))
      } else {
        pausedTotalTime = pausedTotalTimeTemp;
      }
      localStorage.setItem('total_paused_time', pausedTotalTime);
      localStorage.removeItem('pause_time');
    }
  }

  // Function to "Reset" the timer
  const resetTimer = () => {
    if (!startVisible) {
      startTimer();
    }
    if (!stopVisible) {
      stopTimer();
    }
    reset(0,false);
    timerData('reset');
    localStorage.removeItem('start_time');
    localStorage.removeItem('pause_time');
    localStorage.removeItem('current_task_id');
    localStorage.removeItem('total_paused_time');
  }

  // Function to add 0 to seconds where less than 10
  const secondsFunction = (seconds) => {
    if (seconds<10) {
      let newseconds = '0'+ seconds;
      return newseconds;
    }
    return seconds;
  }

  // Function to add 0 to minutes where less than 10
  const minutesFunction = (minutes) => {
    if (minutes<10) {
      let newminutes = '0'+ minutes;
      return newminutes;
    }
    return minutes;
  }

  return (
    <div style={{textAlign: 'start'}}>
      <div>
        <h4>Time</h4>
        {}
        <h5>{`${hours} : ${minutesFunction(minutes)} : ${secondsFunction(seconds)}`}</h5>
      </div>
      <button className='btn btn-primary px-4 me-2 float-start' onClick={() => startTimer()}>{startText()}</button>
      <button className='btn btn-primary px-4 me-2 float-start' onClick={() => stopTimer()}>{stopText()}</button>
      <button className='btn btn-primary px-4 me-2 float-start' onClick={() => resetTimer()}>Reset</button>
      {/* <div>
        <p>{localStorage.getItem('start_time')}</p>
      </div> */}
    </div>
  );
}

export default TimerCount;