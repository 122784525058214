import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import DataTable from 'react-data-table-component';
import Avatar from 'react-avatar';

import { Designations } from '../../../utils/Designations';
import { Roles } from '../../../utils/Roles';
import { Statuses } from '../../../utils/Statuses';
import FilterComponent from '../../FilterComponent';

const usersColumns = [
    {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
    },
    {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
    },
    {
        name: 'Designation',
        selector: row => row.designation,
        sortable: true,
    },
    {
        name: 'Profile Picture',
        selector: row => row.profile_picture,
    },
    {
        name: 'Role',
        selector: row => row.role,
        sortable: true,
    },
    {
        name: 'Client',
        selector: row => row.client,
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
    },
    {
        name: 'Actions',
        selector: row => row.actions,
    },
];

let usersData = [];
let clientAccountsData = [];

const ViewUsers = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterText2, setFilterText2] = useState('');
	const [resetPaginationToggle2, setResetPaginationToggle2] = useState(false);

    useEffect(() => {
        let isMounted = true;
        if (localStorage.getItem('auth_designation') !== 'qa-intern') {
          axios.get(`/api/view-users`).then(res => {
            if (isMounted) {
                if (res.data.status === 200) {
                    setUserList(res.data.users);
                    setLoading(false);
                }
            }
          });
          return () => {
              isMounted = false
          }
        } else {
          history.push('/admin/dashboard');
        }
// eslint-disable-next-line
    }, []);

    const setTableData = useCallback(async () => {
        usersData = [];
        clientAccountsData = [];
        axios.get(`/api/view-users`).then(res => {
            if (res.data.status === 200) {
                setUserList(res.data.users);
                setLoading(false);
            }
        });
    }, []);

    const deleteUser = (e, id) => {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Record",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(`/api/delete-user/${id}`).then(res => {
                    if (res.data.status === 200) {
                        swal(res.data.message, {
                            icon: "success",
                        });
                        setTableData();
                    } else if (res.data.status === 404) {
                        swal("Error", res.data.message, "error");
                    } else if (res.data.status === 405) {
                        swal("Error", res.data.message, "error");
                    }
                });
            }
        });
    }

    const restPasswordUser = (e, id) => {
        e.preventDefault();
        swal({
            title: "Reset Password",
            text: "You are going to reset the password to a defalt of this user",
            icon: "info",
            buttons: true,
        }).then((willReset) => {
            if (willReset) {
                axios.put(`/api/reset-password-user/${id}`).then(res => {
                    if (res.data.status === 200) {
                        swal(res.data.message, {
                            icon: "success",
                        });
                    } else if (res.data.status === 404) {
                        swal("Error", res.data.message, "error");
                    }
                });
            }
        });
    }

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} placeholderText="Filter By Name / Email" />
        );
    }, [filterText, resetPaginationToggle]);

    const subHeaderComponentMemo2 = useMemo(() => {
        const handleClear = () => {
            if (filterText2) {
                setResetPaginationToggle2(!resetPaginationToggle2);
                setFilterText2('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText2(e.target.value)} onClear={handleClear} filterText={filterText2} placeholderText="Filter By Name / Email / Client" />
        );
    }, [filterText2, resetPaginationToggle2]);

    if (loading) {
        return <h4>Loading Users...</h4>
    } else {
        if (userList.length > 0) {
            usersData = userList.filter(val => 
            (val.name && val.name.toLowerCase().includes(filterText.toLowerCase())) || 
            (val.email && val.email.toLowerCase().includes(filterText.toLowerCase())) || 
            (val.client && val.client.name.toLowerCase().includes(filterText.toLowerCase()))
            ).filter(res => parseInt(res.role_as) !== 2).map(item => {
                return ({
                    name :  item.name,
                    email :  item.email,
                    designation :   Designations.map((designation, idx) => item.designation === designation.value ? <span key={idx} className={`badge rounded-pill bg-pill-${idx}`}>{designation.label}</span> : ''),
                    profile_picture :   <Avatar name={item.name} src={item.profile_picture !== '' ? `${process.env.REACT_APP_BACKEND_URL}/${item.profile_picture}` : ''} size="40" round={true} />,
                    role :  Roles.map((role, idx) => parseInt(item.role_as) === parseInt(role.value) ? <span key={idx} className={`badge rounded-pill bg-pill-${idx}`}>{role.label}</span> : ''),
                    client :    item.client && item.client.name,
                    status :    Statuses.map((role, idx) => parseInt(item.status) === parseInt(role.value) ? <span key={idx} className={`badge rounded-pill bg-pill-${parseInt(role.value) ? 'inactive' : 'active'}`}>{role.label}</span> : ''),
                    actions :   <div className="d-flex">
                                    <Link to={`/admin/edit-user/${item.id}`} className="btn btn-outline-success btn-sm"><i className="fa fa-pencil"></i></Link>
                                    { item.role_as !== 1 && <button type='button' onClick={(e) => deleteUser(e, item.id)} className='btn btn-outline-danger btn-sm ms-1'><i className="fa fa-trash"></i></button>}
                                    {<button type='button' onClick={(e) => restPasswordUser(e, item.id)} className='btn btn-outline-danger btn-sm ms-1'><i className="bi bi-unlock"></i></button>}
                                </div>,
                });
            });

            clientAccountsData = userList.filter(val => 
            (val.name && val.name.toLowerCase().includes(filterText2.toLowerCase())) || 
            (val.email && val.email.toLowerCase().includes(filterText2.toLowerCase())) || 
            (val.client && val.client.name.toLowerCase().includes(filterText2.toLowerCase()))
            ).filter(res => parseInt(res.role_as) === 2).map(item => {
                return ({
                    name :  item.name,
                    email :  item.email,
                    designation :   Designations.map((designation, idx) => item.designation === designation.value ? <span key={idx} className={`badge rounded-pill bg-pill-${idx}`}>{designation.label}</span> : ''),
                    profile_picture :   <Avatar name={item.name} src={item.profile_picture !== '' ? `${process.env.REACT_APP_BACKEND_URL}/${item.profile_picture}` : ''} size="40" round={true} />,
                    role :  Roles.map((role, idx) => parseInt(item.role_as) === parseInt(role.value) ? <span key={idx} className={`badge rounded-pill bg-pill-${idx}`}>{role.label}</span> : ''),
                    client :    item.client && item.client.name,
                    status :    Statuses.map((role, idx) => parseInt(item.status) === parseInt(role.value) ? <span key={idx} className={`badge rounded-pill bg-pill-${parseInt(role.value) ? 'inactive' : 'active'}`}>{role.label}</span> : ''),
                    actions :   <div className="d-flex">
                                    <Link to={`/admin/edit-user/${item.id}`} className="btn btn-outline-success btn-sm"><i className="fa fa-pencil"></i></Link>
                                    { item.role_as !== 1 && <button type='button' onClick={(e) => deleteUser(e, item.id)} className='btn btn-outline-danger btn-sm ms-1'><i className="fa fa-trash"></i></button>}
                                    {<button type='button' onClick={(e) => restPasswordUser(e, item.id)} className='btn btn-outline-danger btn-sm ms-1'><i className="bi bi-unlock"></i></button>}
                                </div>,
                });
            });
        }
    }

    return (
        <>
            <div className='px-4'>
                <div className='card mt-4'>
                    <div className='card-header'>
                        <h4>User List</h4>
                    </div>
                    <div className='card-body'>
                        <DataTable
                            columns={usersColumns}
                            data={usersData}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle}
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                        />
                    </div>
                </div>
            </div>
            <div className='px-4'>
                <div className='card mt-4'>
                    <div className='card-header'>
                        <h4>Client Account List</h4>
                    </div>
                    <div className='card-body'>
                        <DataTable
                            columns={usersColumns}
                            data={clientAccountsData}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle2}
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo2}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ViewUsers;